
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue'
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APICustomer, APIInvoice, APIPaymentMethod } from '@/typesAPI';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue'
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import Divider from '@/components/UIElements/Divider.vue'
import Switch from '@/components/UIElements/Switch.vue'

class Props {
  visible: boolean = prop({
    required: true,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomNumberInput,
    CustomTextInput,
    Icon,
    PaymentMethodsDisplay,
    StripeWidgetPaymentMethods,
    WidgetBillingDetails,
    FransysSignature,
    Spinner,
    Divider,
    Switch
  },
})
export default class ModalPurchaseCredit extends Vue.with(Props) {
    creditAmountToAdd = 100
    loadingModalCTA = false
    loadingDraft = false
    stripePublicKey = ""
    promotionCode = ""
    paymentMethodSelected:APIPaymentMethod | null = null
    invoice:APIInvoice | null = null
    editing = false
    waitingToEdit = false
    showAddOtherPaymentMethod = false
    showEditBillingDetails = false
    showSuccess = false
    switchPromoValue = false
    errs:any = null

    get customer():APICustomer {
      return this.$store.getters['user/getCustomer']
    }

    get paymentMethods():APIPaymentMethod[] {
      return this.$store.getters['billing/getPaymentMethods']
    }

    get draftInvoiceCredit():APIInvoice | null {
      return this.$store.getters['billing/getDraftInvoiceCredit']
    }

    get disabledCTA(): boolean {
      return this.errs || !this.paymentMethodSelected
    }

    onAddPaymentMethod() {
      this.showAddOtherPaymentMethod = false
      this.editDraft()
    }

    onEditBillingDetails() {
      this.showEditBillingDetails = false
    }

    @Watch('visible', {immediate: true})
    onVisibleChange() {
      if(this.visible) {
        if(!this.customer?.attributes?.country) {
          this.showEditBillingDetails = true
        }
        this.createDraft() 
      }
    }

    @Watch('paymentMethodSelected')
    onPaymentMethodSelectedChange() {
      this.editDraft()
    }

    @Watch('editing')
    onEditingChange() {
      if(this.waitingToEdit) {
        this.editDraft()
      }
    }
    

    createDraft() {
      if(!this.draftInvoiceCredit && this.visible) {
        this.loadingDraft = true
        API.billing.createDraftInvoiceCredits(this.customer.id, this.creditAmountToAdd)
        .then((res:APIInvoice) => {
          if(res.attributes.amount_subtotal === this.creditAmountToAdd * 100) {
            this.$store.dispatch('billing/setDraftInvoiceCredit', res)
            this.loadingDraft = false
          }
        })
      }
    }

    editDraft() {
      if(this.editing) {
        this.waitingToEdit = true
      } else if(this.draftInvoiceCredit && this.visible) {
        this.loadingModalCTA = true
        this.editing = true
        this.waitingToEdit = false
        API.billing.editDraftInvoiceCredits(this.draftInvoiceCredit?.id, this.creditAmountToAdd, this.paymentMethodSelected?.id, this.promotionCode && this.switchPromoValue ? [this.promotionCode] : undefined)
        .then((res:APIInvoice) => {
          this.$store.dispatch('billing/setDraftInvoiceCredit', res)
          this.errs = null
        })
        .catch((err:any) => {
          this.errs = err.response?.data?.meta
        })
        .finally(() => {
          this.loadingModalCTA = false
          this.editing = false
        })
      }
    }

    onPurchaseCredit() {
      this.loadingModalCTA = true
      if(this.draftInvoiceCredit && this.paymentMethodSelected) {
        API.billing.finaliseDraftInvoiceCredits(this.draftInvoiceCredit?.id, this.creditAmountToAdd, this.paymentMethodSelected?.id, this.promotionCode && this.switchPromoValue ? [this.promotionCode] : undefined)
        .then((res:APIInvoice) => {
          this.$store.dispatch('billing/invalidateInvoices')
          this.$store.dispatch('billing/setDraftInvoiceCredit', null)
          this.onSuccessfullPurchaseCredit()
          this.errs = null
        })
        .catch((err:any) => {
          this.errs = err.response.data.meta
        })
      }
    }

    onSuccessfullPurchaseCredit() {
      this.showSuccess = true
      setTimeout(this.closeModal, 3000)
    }

    onClickAddOtherPaymentMethod() {
      this.showAddOtherPaymentMethod = true
    }

    closeModal() {
      this.$emit('update:visible',false)
      this.loadingModalCTA = false
      this.editing = false
      this.waitingToEdit = false
      this.loadingDraft = false
      this.showAddOtherPaymentMethod = false
      this.showEditBillingDetails = false
      this.showSuccess = false
    }

}
