
import { Vue, Options, prop } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import API from '@/api/wrapper'
import { APIDeploymentVersion, APIDeploymentPlan, APIDeploymentSubject, APIDeploymentRun } from '@/typesAPI'


class Props {
  draftViewText: string | undefined = prop({
    required: false,
  });
  forceDisable: boolean | undefined = prop({
    required: false,
  });
  versionsToDeploy?:APIDeploymentVersion[] = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton
  },
})
export default class PublishButton extends Vue.with(Props) {
  tryToDeploy = false
  isStartTrial = false

  created() {
    this.$emitter.off('forceDeploy')
    this.$emitter.on('forceDeploy', () => {
      this.doDeploy()
    })

    this.$emitter.off('startTrial')
    this.$emitter.on('startTrial', () => {
      this.$store.dispatch('user/startTrial')
      this.doDeploy()
    })
    
  }

  tryDeploy () {
    this.$store.dispatch('deployment/setModalIsDraftView', false)
    if (this.hasErrors || this.hasWarnings) {
      this.tryToDeploy = true
      this.$store.dispatch('errorsValidation/openModal')
      if(!this.hasErrors && this.hasWarnings) {
        this.$store.dispatch('deployment/setModalIsReduced', true)
        this.$store.dispatch('errorsValidation/openWarningPopup')
      }
      setTimeout(() => {
        this.tryToDeploy = false
      },2000)
    } else {
      this.doDeploy()
    }
  }

  doDeploy () {
    this.tryToDeploy = false
    if(this.needToShowSubscriptionModal) {
      this.$store.dispatch('deployment/setModalIsOpen', true)
    } else {
      if(this.versionsToDeploy) {
        this.versionsToDeploy.forEach((version) => {
          API.deployment.editDeploymentVersion(version.id, { status: 'running' })
          .then(() => {
            this.$store.dispatch('deployment/setModalIsOpen', true)
          })
        })
      }
    }
  }

  get needToShowSubscriptionModal () {
    return this.subscriptionState === this.$enums.SubscriptionState.FRESH || this.subscriptionState === this.$enums.SubscriptionState.TRIAL_EXPIRED || this.subscriptionState === this.$enums.SubscriptionState.EXPIRED
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get text (): string {

    if(!this.modalOpen) {
      return this.tryToDeploy || this.deploying || this.deployed ? '' : 'Deploy'
    }

    if(this.isDraftViewMode && this.draftViewText) {
      return this.draftViewText
    }

    if(this.needToShowSubscriptionModal) {
      return 'Deploy'
    }

    if(this.deploying) {
      if(this.isRollingBack) {
        return 'Canceling Deployment'
      }
      return 'Deployment in progress'
    }
    if(this.deployed) {
      if(this.isRollingBack) {
        return 'Deployment canceled'
      }
      return 'Deployment success'
    }

    return 'Deploy'
  }

  get plansRollback ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getDeploymentPlansRollback']
  }

  get isRollingBack ():boolean {
    return !!this.plansRollback.length
  }
  
  get status ():string {
    if (this.deployed) {
      if(this.isRollingBack) {
        return this.$enums.ButtonStates.Warning
      }
      return this.$enums.ButtonStates.Success
    } else if (this.tryToDeploy && this.hasErrors) {
      return this.$enums.ButtonStates.Danger
    } else if (this.tryToDeploy && this.hasWarnings) {
      return this.$enums.ButtonStates.Warning
    }
    return ''
  }

  get hasErrors ():boolean {
    return !!this.$store.getters['errorsValidation/getErrors'].length
  }

  get hasWarnings ():boolean {
    return !!this.$store.getters['errorsValidation/getWarnings'].length
  }

  get isDraftViewMode (): boolean {
    return this.$store.getters['deployment/getModalIsDraftView']
  }

  get hasDraft (): boolean {
    return !!this.$store.getters['deployment/getDraftVersions'].length
  }

  get deploying (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Running
  }

  get deployed (): boolean {
    return this.$store.getters['deployment/getDeployingState'] === this.$enums.DeploymentState.Completed
  }

  get modalOpen ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }
}
