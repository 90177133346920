import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["shell-detail-field", {disabled : _ctx.disabled}])
  }, [
    (_ctx.horizontal)
      ? (_openBlock(), _createBlock(_component_v_row, {
          key: 0,
          class: "ma-0 d-flex"
        }, {
          default: _withCtx(() => [
            (!_ctx.solo)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  class: "pa-0"
                }, {
                  default: _withCtx(() => [
                    (_ctx.title)
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createTextVNode(_toDisplayString(_ctx.title), 1)
                        ], 64))
                      : _renderSlot(_ctx.$slots, "title", { key: 1 }, undefined, true)
                  ]),
                  _: 3
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, {
              class: "pa-0",
              align: "right",
              style: {"max-width":"fit-content"}
            }, {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "content", {}, undefined, true)
              ]),
              _: 3
            })
          ]),
          _: 3
        }))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          (!_ctx.solo)
            ? (_openBlock(), _createBlock(_component_v_row, {
                key: 0,
                class: "ma-0 mb-2"
              }, {
                default: _withCtx(() => [
                  (_ctx.title)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(_ctx.title), 1)
                      ], 64))
                    : _renderSlot(_ctx.$slots, "title", { key: 1 }, undefined, true)
                ]),
                _: 3
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_row, { class: "ma-0" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "content", {}, undefined, true)
            ]),
            _: 3
          })
        ], 64))
  ], 2))
}