
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'

export class Props {
  logo?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  badgeLabel?: string = prop({
    required: false,
  });
  badgeState?: string = prop({
    required: false,
  });
  badgeIcon?: string = prop({
    required: false,
  });
  badgeOutlined?: boolean = prop({
    required: false,
  });
  text?: string = prop({
    required: false,
  });
  alignRight?: boolean = prop({
    required: false,
  });
  subtext?: string = prop({
    required: false,
  });
  selected?: boolean = prop({
    required: false,
  });
  iconRigth?: string = prop({
    required: false,
  });
  warningLevel?: string = prop({
    required: false,
  });
  noHover?: boolean = prop({
    required: false,
  });
  dense?: boolean = prop({
    required: false,
  });
  iconSubtext?: string = prop({
    required: false,
  });
  skeleton?: boolean = prop({
    required: false,
  });
  isURL?: boolean = prop({
    required: false,
  });
  separator?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  disabledButColored?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    Logo,
    Badge
  },
})
export default class ListElement extends Vue.with(Props) {
  onClick() {
    if(!this.separator && !this.disabled && !this.disabledButColored) {
      this.$emit('update:selected', true)
    }
  }

  onTextClick(event:any) {
    if(this.isURL) {
      event.stopPropagation()
      window.open('https://'+ this.text, "_blank");
    }
  }

  get withIconOrLogo () {
    return !!(this.logo || this.icon)
  }
}



