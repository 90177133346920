import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1097dd5f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popin-cover" }
const _hoisted_2 = { class: "popin bar" }
const _hoisted_3 = { class: "ma-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_v_col, { class: "pa-4" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_row, { class: "ma-0 mb-6" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "header", {}, undefined, true)
            ]),
            _: 3
          }),
          _createVNode(_component_v_row, { class: "ma-0 mb-4" }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _renderSlot(_ctx.$slots, "content", {}, undefined, true)
              ])
            ]),
            _: 3
          }),
          _createVNode(_component_v_row, { class: "ma-0" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ])
  ]))
}