
import LoginBar from '@/components/Actions/LoginBar.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    LoginBar,
    FransysSignature,
    BackgroundGradiant
  },
})
export default class AdminView extends Vue {

}
