
import { Environment } from "@/types"

type State = {
  list: Environment[]
}

const state: State = {
  list : [
    {
      id: '0',
      label: 'All environments',
    },
    {
      id: '1',
      label: 'Dev',
    },
    {
      id: '2',
      label: 'Preprod',
    },
    {
      id: '3',
      label: 'Prod',
    },
  ]
}

const getters = {
  getList: (state:State) => {
    return state.list
  },
}

const mutations = {
//   SET_USER_LANG: (state, payload) => {
//     state.app_lang = payload
//   },
}

const actions = {
//   setCountries: (context) => {
//     Services.app.getCountries().then(resp => {
//       context.commit('SET_COUNTRIES', resp)
//     })
//   },
}

export default {
  state,
  getters,
  mutations,
  actions
}