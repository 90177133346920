
import { Vue, prop } from 'vue-class-component';

class Props {
  pmlogo: string = prop({
    required: true,
  });
}

export default class PaymentMethodLogo extends Vue.with(Props) {
}
