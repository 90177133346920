
import API from '@/api/wrapper';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import SocketsManager from '@/components/Sockets/SocketsManager.vue';
import List, {ListItem} from '@/components/UIElements/List.vue'
import { APIUser, APISocialAccount, APICustomer } from '@/typesAPI';
import { Vue, Options } from 'vue-class-component';
import ModalCGU from '@/components/Modals/ModalCGU.vue';
import Spinner from '@/components/UIElements/Spinner.vue';

@Options({
  components: {
    FransysSignature,
    List,
    ModalCGU,
    SocketsManager,
    Spinner
  },
})
export default class HomeView extends Vue {

  list:ListItem[] = [
    {
      text: 'Projects',
      value: 'projects',
      icon:'projects'
    },
    {
      text: 'Clusters',
      value: 'clusters',
      icon:'cluster'
    },
    {
      separator: true,
    },
    {
      text: 'Account',
      value: 'account',
      icon:'user'
    },
    {
      text: 'Billing',
      value: 'billing',
      icon:'receipt'
    },
    {
      text: 'Usage',
      value: 'usage',
      icon:'chip'
    },
  ]

  get user() {
    return this.$store.getters['user/getUser']
  }

  get CGUAccepted(): boolean {
    return this.user ? this.user?.attributes?.terms_of_use_accepted_at !== null : false
  }

  mounted(): void {
    API.session.getCSRF().then(() => {
      this.getMe()
    })

    this.list.forEach((item:ListItem) => {
      item.selected = false
      if(item.value === this.$route.name) {
        item.selected = true
      }
    })
  }

  getMe() {
    if(!this.$store.getters['user/getUser']) {
      API.user.getMe()
      .then((res:{user:APIUser, socialAccounts:APISocialAccount[], customers:APICustomer[]}) => {
        this.$store.dispatch('user/setUser', res.user)
        this.$store.dispatch('user/setCustomer', res.customers[0])

        res.socialAccounts.forEach((socialAccount:APISocialAccount) => {
          this.$store.dispatch('user/setSocialAccount', {provider: socialAccount.attributes.provider, socialAccount: socialAccount})
        })
      })
    }
    
  }

  onSelect(item:ListItem) {
    this.$router.push({
      name: item.value
    })
  }
  

}
