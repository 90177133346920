
import API from '@/api/wrapper';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Logo from '@/components/UIElements/Logo.vue';
import { APIUrlRedirection } from '@/typesAPI';
import { Vue, Options, prop } from 'vue-class-component';


class Props {
  provider: string = prop({
    required: true,
  });
  providerName: string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    Logo,
  },
})
export default class LoginSocialButton extends Vue.with(Props) {
  onClick() {
    API.session.loginViaSocial(this.provider)
    .then((urlRedirection:APIUrlRedirection) => {
      this.$helpers.links.redirect(urlRedirection.attributes.url)
    })
  }
}
