
import { Vue, prop } from 'vue-class-component';

class Props {
  isHovered?: boolean = prop({
    required: false,
  });
}

export default class TrashBar extends Vue.with(Props) {
  get isDraggingNode () {
    return !!this.$store.getters['elements/getDraggingElement']
  }

  get trashOpen () :boolean {
    if ( this.isDraggingNode ) {
      if (this.isHovered) {
        return true
      } else {
        return false
      }
    } else {
      if (this.isHovered) {
        return false
      }
    }
    return true
  }
}
