
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Table from '@/components/UIElements/Table.vue';
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue';
import UsageAlert from '@/components/UIElements/UsageAlert.vue';
import FlatGauge from '@/components/UIElements/FlatGauge.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import PriceDisplay from '@/components/UIElements/PriceDisplay.vue';

import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import ModalEditLimits from '@/components/Modals/ModalEditLimits.vue';


import { Vue, Options } from 'vue-class-component';
import { APICustomer, APIMoney, APIPrice, APIUser } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Divider,
    Icon,
    Table,
    CustomNumberInput,
    UsageAlert,
    FlatGauge,
    Indicator,
    ModalPurchaseCredit,
    ModalEditLimits,
    PriceDisplay
  },
})
export default class RessourceUsageView extends Vue {

  sliderValues = [0, 0]
  showPurchaseCreditModal = false
  loadingLimitChange = false
  showEditLimits = false

  flatGaugeColors = ['rgba(50, 25, 178, 1)', 'rgba(157, 145, 218, 1)', 'rgba(206, 200, 237, 1)']

  usageAlerts = [
    {
      value: 80,
      limit: "hl",
      readonly: true 
    },
    {
      value: 50,
      limit: "hl",
      readonly: true 
    },
    {
      value: 80,
      limit: "brl",
      readonly: true 
    },
    {
      value: 50,
      limit: "brl",
      readonly: false 
    },
  ]

  detailHeaders = ['Resource name', 'Current usage price']

  get currentBillingPeriod(): {end:Date,start:Date} {
    return this.$store.getters['billing/getCurrentBillingPeriod']
  }

  get detailValues():string[][] {
    if(!this.currentBillingCycle) {
      return [[]]
    }
    return this.currentBillingCycle.map((price:APIPrice) => {
      return [
        price.attributes.label,
        this.$helpers.prices.format(parseInt(price.attributes.amount), this.currency),
      ]
    })
  }

  get projectedUsage():number {
    return this.$store.getters['billing/getProjectedUsage']
  }

  get isProjectedOverLimit() {
    return this.customer?.attributes.monthly_hard_limit ? this.projectedUsage > this.customer?.attributes.monthly_hard_limit : false
  }

  get balance() {
    return this.customer?.attributes?.balance ? parseInt(this.customer?.attributes?.balance) : 0
  }
  get currency() {
    return this.customer?.attributes?.currency
  }
  
  get burnRate():APIMoney | null {
    return this.$store.getters['billing/getBurnRate']
  }

  get currentBillingCycle():APIPrice[] {
    return this.$store.getters['billing/getCurrentBillingCycle']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get used():number {
    return this.$store.getters['billing/getRessourceUsed']
  }

  get user():APIUser {
    return this.$store.getters['user/getUser']
  }

  mounted(): void {
    this.sliderValues = [this.used, this.projectedUsage]
  }

}
