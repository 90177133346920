
import { Vue, prop } from 'vue-class-component';

class Props {
  color?: string  = prop({
    required: false,
  });
  state?: string  = prop({
    required: false,
  });
}
export default class Indicator extends Vue.with(Props) {
}
