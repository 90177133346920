import { APIErrorValidation } from "@/typesAPI"
import API from "@/api/wrapper"
import helpers from '@/utils/helpers'

type State = {
  list: APIErrorValidation[],
  modalIsOpen: boolean,
  warningPopupIsOpen: boolean,
  loading: boolean,
  modalTransparency: boolean,
  hoveredError: APIErrorValidation | null,
  selectedError: APIErrorValidation | null
}

const state: State = {
  list: [],
  loading: false,
  modalTransparency: false,
  modalIsOpen: false,
  warningPopupIsOpen: false,
  hoveredError: null,
  selectedError: null
}

function filterIsSystem(list:APIErrorValidation[]) {
  if(list.find((err:APIErrorValidation) => !err.attributes.isSystem)) {
    return list.filter((err:APIErrorValidation) => !err.attributes.isSystem)
  } 
  return list
}

const getters = {
  getList: (state:State) => {
    return filterIsSystem(state.list)
  },
  getErrors: (state:State) => {
    return filterIsSystem(state.list).filter((elem:APIErrorValidation) => {
      return elem?.meta?.severity === 'error'
    })
  },
  getWarnings: (state:State) => {
    return filterIsSystem(state.list).filter((elem:APIErrorValidation) => {
      return elem?.meta?.severity === 'warning'
    })  
  },
  getInfos: (state:State) => {
    return filterIsSystem(state.list).filter((elem:APIErrorValidation) => {
      return elem?.meta?.severity === 'info'
    })  
  },
  getBySourceID: (state:State) => {
    return (sourceID:string) => {
      return filterIsSystem(state.list).filter((elem:APIErrorValidation) => {
        return elem?.relationships.source?.data.id === sourceID
      })  
    }
  },
  isLoading: (state:State) => {
    return state.loading
  },
  getSelectedError: (state:State) => {
    return state.selectedError
  },
  getHoveredError: (state:State) => {
    return state.hoveredError
  },
  getModalIsOpen: (state:State) => {
    return state.modalIsOpen
  },
  getModalTransparency: (state:State) => {
    return state.modalTransparency
  },
  getWarningPopupIsOpen: (state:State) => {
    return state.warningPopupIsOpen
  },
}

const mutations = {
  SET_LIST: (state:State, list: APIErrorValidation[]) => {
    state.list = list
  },
  ADD_ERRORS: (state:State, list: APIErrorValidation[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.list, list)
  },
  DELETE_ERRORS: (state:State, list: APIErrorValidation[]) => {
    for(const err of list) {
      const index = state.list.findIndex((e:APIErrorValidation) => e.id === err.id)
      if(index != -1) {
        state.list.splice(index,1)
      }
    }  
  },
  SET_SELECTED_ERROR: (state:State, error: APIErrorValidation) => {
    state.selectedError = error
  },
  SET_HOVERED_ERROR: (state:State, error: APIErrorValidation) => {
    state.hoveredError = error
  },
  SET_MODAL_TRANSPARENCY: (state:State, val: boolean) => {
    state.modalTransparency = val
  },
  TOGGLE_MODAL: (state:State) => {
    state.modalIsOpen = !state.modalIsOpen
  },
  OPEN_MODAL: (state:State) => {
    state.modalIsOpen = true
  },
  CLOSE_MODAL: (state:State) => {
    state.modalIsOpen = false
  },
  SET_LOADING: (state:State, newLoading:boolean) => {
    state.loading = newLoading
  },
  TOGGLE_WARNING_POPUP: (state:State) => {
    state.warningPopupIsOpen = !state.warningPopupIsOpen
  },
  OPEN_WARNING_POPUP: (state:State) => {
    state.warningPopupIsOpen = true
  },
  CLOSE_WARNING_POPUP: (state:State) => {
    state.warningPopupIsOpen = false
  },
}

const actions = {
  addErrors: (context:any, list: APIErrorValidation[]) => {
    context.commit('ADD_ERRORS', list)
  },
  deleteErrors: (context:any, list: APIErrorValidation[]) => {
    context.commit('DELETE_ERRORS', list)
  },
  setList: (context:any, list: APIErrorValidation[]) => {
    context.commit('SET_LIST', list)
  },
  setSelectedError: (context:any, error: APIErrorValidation) => {
    context.commit('SET_SELECTED_ERROR', error)
  },
  setHoveredError: (context:any, error: APIErrorValidation) => {
    context.commit('SET_HOVERED_ERROR', error)
  },
  setModalTransparency: (context:any, val: boolean) => {
    context.commit('SET_MODAL_TRANSPARENCY', val)
  },
  toggleModal: (context:any) => {
    context.commit('TOGGLE_MODAL')
  },
  toggleWarningPopup: (context:any) => {
    context.commit('TOGGLE_WARNING_POPUP')
  },
  openModal: (context:any) => {
    context.commit('OPEN_MODAL')
  },
  openWarningPopup: (context:any) => {
    context.commit('OPEN_WARNING_POPUP')
  },
  closeModal: (context:any) => {
    context.commit('CLOSE_MODAL')
  },
  closeWarningPopup: (context:any) => {
    context.commit('CLOSE_WARNING_POPUP')
  },
  loadErrors: (context:any) => {
    context.commit('SET_LOADING', true)
    API.actions.validationErrors(context.rootGetters['projects/getCurrentProjectId'])
    .then((ret:APIErrorValidation[]) => {
      context.commit('SET_LIST', ret)
    })
    .finally(() => {
      context.commit('SET_LOADING', false)
    })
  },

  
}

export default {
  state,
  getters,
  mutations,
  actions
}