import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-24830874"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ma-auto token-text-regular" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_col, {
    class: _normalizeClass(["segmented-control-container", {disabled:_ctx.disabled}])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "ma-0 pa-0 control-container" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (text, key) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              value: key,
              key: 'tabs-' + key,
              class: _normalizeClass(["d-flex ma-0 pa-0 control", {'control-selected': _ctx.modelValue === key}]),
              onClick: ($event: any) => (_ctx.handleClick(key as string))
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  (!_ctx.$slots.content)
                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                        _createTextVNode(_toDisplayString(text), 1)
                      ], 64))
                    : _renderSlot(_ctx.$slots, "content", {
                        key: key,
                        value: text
                      }, undefined, true)
                ])
              ]),
              _: 2
            }, 1032, ["value", "class", "onClick"]))
          }), 128))
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["class"]))
}