import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalRenameRessource = _resolveComponent("ModalRenameRessource")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["project-bar bar py-2 px-4 d-flex", {collapsed:_ctx.collapsed}])
  }, [
    _createVNode(_component_ModalRenameRessource, {
      target: _ctx.currentProject,
      visible: _ctx.showModalRenameProject,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showModalRenameProject) = $event))
    }, null, 8, ["target", "visible"]),
    _createVNode(_component_v_col, { class: "ma-auto pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, {
              class: "my-auto",
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showModalRenameProject = true))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_v_row, { class: "title-container" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.currentProject?.attributes?.name), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 2))
}