import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockCategory = _resolveComponent("BlockCategory")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, {
    class: _normalizeClass(["block-bar bar pa-0", {hideLeft: _ctx.hideLeft}])
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "pa-0 ma-0 pt-2" }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listBlocks, (block) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "pb-2",
              key: 'block-'+block.id
            }, [
              _createVNode(_component_BlockCategory, {
                block: block,
                type: "block"
              }, null, 8, ["block"])
            ]))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["class"]))
}