
import { Options, Vue, prop } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Logo from '@/components/UIElements/Logo.vue';
import Badge from '@/components/UIElements/Badge.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import { APICluster, APIProject } from '@/typesAPI';

class Props {
  cluster:APICluster = prop({
    required: true,
  });
  projects:APIProject[] = prop({
    required: true,
  });
  clustersStatus:string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Icon,
    Logo,
    Indicator,
    Badge
  },
})
export default class ClusterDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'rename',
      text: 'Rename',
      icon: 'edit'
    },
    {
      id: 'edit',
      text: 'Edit config',
      icon: 'edit'
    },
    {
      id: 'offline',
      text: 'Put offline',
      icon: 'cloud-off'
    },
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'token-text-color-orange'
    },
  ]  

  get projectsStatus():{[key:string]:string} {
    return this.$store.getters['projects/getProjectsStatus']
  }

  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.$emit('clickMenu', item, cluster)
    this.burgerOpened = false
  }

  getProjectStateById(id:string) {
    return this.projectsStatus[id] ? this.$helpers.states.translateDeploymentStatusToState(this.projectsStatus[id]) : this.$enums.BadgeStates.Neutral
  }

}
