
import { Vue, prop, Options } from 'vue-class-component';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomCountrySelect from '@/components/UIElements/CustomCountrySelect.vue';
import { APICustomer } from '@/typesAPI';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';

class Props {
  customer:APICustomer = prop({
    required: true,
  });
}

@Options({
  components: {
    CustomTextInput,
    CustomButton,
    CustomCountrySelect
  },
})
export default class WidgetBillingDetails extends Vue.with(Props) {

  detailForm:Partial<APICustomer['attributes']> = {}
  loading = false
  
  get disabled():boolean {
    return !this.detailForm.address || !this.detailForm.name || !this.detailForm.country || !this.detailForm.city || !this.detailForm.postal_code
  }

  @Watch('customer', {immediate:true})
  onCustomerChange() {
    if(this.customer) {
      if(this.customer.attributes.name) this.detailForm.name = this.customer.attributes.name
      if(this.customer.attributes.country) this.detailForm.country = this.customer.attributes.country
      if(this.customer.attributes.address) this.detailForm.address = this.customer.attributes.address
      if(this.customer.attributes.city) this.detailForm.city = this.customer.attributes.city
      if(this.customer.attributes.postal_code) this.detailForm.postal_code = this.customer.attributes.postal_code
      if(this.customer.attributes.tax_id) this.detailForm.tax_id = this.customer.attributes.tax_id
    }
  }

  onSubmit() {
    this.loading = true
    API.billing.editCustomer(this.customer.id, this.detailForm)
    .then(() => {
      this.loading = false
      this.$emit('success')
    })
  }



}
