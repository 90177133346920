
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  dark?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
  },
})
export default class Spinner extends Vue.with(Props) {
}
