
import { Options, Vue, prop } from 'vue-class-component';
import BlockCategory from '@/components/Blocks/BlockCategory.vue'
import { Block, PositionXY } from '@/types'

class Props {
  positionXY?: PositionXY = prop({
    required: false,
  });
  linkSrc?: string = prop({
    required: false,
  });
}


@Options({
  components: {
    BlockCategory,
  },
})
export default class BlockBarContext extends Vue.with(Props) {
  get listBlocks () : Block[] {
    return this.$store.getters['blocks/getList']
  }
}
