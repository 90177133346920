import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import mitt from 'mitt'
import * as Sentry from "@sentry/vue";
import getEnv from '@/utils/env';

const emitter = mitt()

loadFonts()

const app = createApp(App)
app.config.globalProperties.$emitter = emitter

import enums from '@/enums'
app.config.globalProperties.$enums = enums

import helpers from '@/utils/helpers'
app.config.globalProperties.$helpers = helpers

import { createI18n } from 'vue-i18n'
const i18n = createI18n({
  // something vue-i18n options here ...
})

if(getEnv('SENTRY_KEY')) {
  Sentry.init({
    app,
    environment: getEnv('NODE_ENV'),
    dsn: getEnv('SENTRY_KEY'),
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  
    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["https://app.fransys.cloud"],
  
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}





app.use(router)
  .use(store)
  .use(vuetify)
  .use(i18n)
  .mount('#app')