
import { Vue, prop } from 'vue-class-component'
import { APIFieldDefinition } from '@/typesAPI'

class Props {
  definitionAttributes: APIFieldDefinition['attributes'] = prop({
    required: true,
  });
}

export default class ErrorLoad extends Vue.with(Props) {

}
