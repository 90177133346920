
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import getEnv from '@/utils/env';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CustomButton,
    Popin
  },
})
export default class SubscriptionTrialOverModal extends Vue {
  loadingCheckout = false
  
  mounted(): void {
    this.loadingCheckout = false
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
  }

  askForMore () {
    window.open('mailto:' + getEnv('VUE_APP_SUPPORT_EMAIL') + '?subject=Ask for more trial time', '_blank')
  }
}
