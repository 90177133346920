
import { nextTick, unref } from 'vue';
import { Options, Vue } from 'vue-class-component';
import { VueFlow, Connection, NodeDragEvent, useVueFlow, StartHandle, GraphNode } from '@vue-flow/core'
import { Background, BackgroundVariant, PanelPosition, Panel } from '@vue-flow/additional-components'
import BlockNode from '@/components/Nodes/BlockNode.vue'
import PlatformNode from '@/components/Nodes/PlatformNode.vue'
import InvisibleNode from '@/components/Nodes/InvisibleNode.vue'
import CustomEdge from '@/components/Edges/CustomEdge.vue'
import TrashBar from '@/components/Actions/TrashBar.vue'
import { Block, Platform, ElementNode, ElementLink, PositionXY } from '@/types';
import { Ref, Watch } from 'vue-property-decorator';
import API from '@/api/wrapper';

@Options({
  components: {
    VueFlow,
    Background,
    BlockNode,
    PlatformNode,
    InvisibleNode,
    CustomEdge,
    TrashBar,
    Panel
  },
})
export default class FlowChart extends Vue {
  @Ref('trashRef') readonly trashRef!:TrashBar
  @Ref('gridSnapHightlightRef') readonly gridSnapHightlightRef!:HTMLDivElement

  PanelPosition:typeof PanelPosition = PanelPosition
  BackgroundVariant:typeof BackgroundVariant = BackgroundVariant
  lastConnectionStartHandle: StartHandle | null = null
  isTrashHovered = false
  gridSpacing = 12
  gridSnapHeight = 80
  gridSnapWidth = 464
  vueFlowState = useVueFlow()

  created() {
    this.$emitter.on('deleteBlock', (node:GraphNode) => {
      this.deleteNode(node)
    })
  }

  mounted(): void {
    const nodes: GraphNode[] = this.$store.getters['elements/getNodes']
    nodes.forEach((node) => {
      this.snapToGrid(node, false)
    })
  }

  onPaneClick() {
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')
  }

  onDrop(event:DragEvent) {

    const draggingBlock:Block = this.$store.getters['blocks/getDraggingBlock']
    const draggingPlatform:Platform = this.$store.getters['platforms/getDraggingPlatform']
    const finalDragging: Block | Platform = draggingBlock ? draggingBlock : draggingPlatform;
    if(finalDragging) {
      const { left, top } = this.vueFlowState.vueFlowRef.value ? this.vueFlowState.vueFlowRef.value.getBoundingClientRect() : {left : 0, top : 0}
      const position = this.vueFlowState.project({
        x: event.clientX - left - 116,
        y: event.clientY - top - 62,
      })
      this.$store.dispatch('elements/addNode', {block:finalDragging, type: draggingBlock ? 'block' : 'platform', positionXY: position})
    }
  }

  onNodeCreation() {
    nextTick(() => {
      const lisStoredtNodes = this.$store.getters['elements/getNodes']
      const lastStoredNodeCreated = lisStoredtNodes[lisStoredtNodes.length - 1]
      if(lastStoredNodeCreated.type === 'block') {
        const lastFlowNodeCreated = unref(this.vueFlowState.getNodes).find((node) => node.id === lastStoredNodeCreated.id)
        if(lastFlowNodeCreated) {
          setTimeout(() => {
            const intersections = this.vueFlowState.getIntersectingNodes(lastFlowNodeCreated)
            this.handleMoveOnOverlap(lastFlowNodeCreated, intersections)
          })
        }
      }
    })
  }

  onConnectStart() {
    // this.$store.dispatch('elements/setSelectedLink')
    this.lastConnectionStartHandle = unref(this.vueFlowState.connectionStartHandle)
  }

  onConnectEnd() {
    if( this.connectionSuggested ) {
      this.$store.dispatch('elements/addLink', {
        source: this.lastConnectionStartHandle?.nodeId,
        target: this.connectionSuggested.id,
        sourceHandle: this.lastConnectionStartHandle?.handleId,
      })
      const nodes: GraphNode[] = this.$store.getters['elements/getNodes']
      const node = nodes.find((n) => {
        return n.id === this.lastConnectionStartHandle?.nodeId
      })
    }
  }

  onConnect(connection:Connection) {
    this.$store.dispatch('elements/addLink', connection)
  }

  onNodeDragStart(nodeDragEvent: NodeDragEvent) {
    console.log('onNodeDragStart')
  }

  onNodeDrag(nodeDragEvent: NodeDragEvent) {
    if( !this.draggingNode ) {
      this.$store.dispatch('elements/changeLinksType', 'default')
      this.$store.dispatch('elements/setDraggingElement', nodeDragEvent.node)
    }

    this.moveHightlightSnap(nodeDragEvent.node.position)
   
    this.isTrashHovered = this.isDraggingOverTrash(nodeDragEvent.node)
  }

  moveHightlightSnap(position: PositionXY) {
    const snapPosition = this.closestGridPositionAvailable(position)
    const transform = this.vueFlowState.getTransform()
    this.gridSnapHightlightRef.style.display = 'inherit'
    this.gridSnapHightlightRef.style.top = (transform.y + snapPosition.y)+ 'px'
    this.gridSnapHightlightRef.style.left = (transform.x + snapPosition.x)+ 'px'
  }

  isDraggingOverTrash(node: GraphNode) {
    const trashRect = this.trashRef.$el.getBoundingClientRect()
    // Take into account the invisible part of the node with (50 * this.vueFlowState.getTransform().zoom)
    const projectedXY = this.vueFlowState.project({x: trashRect.x + (node.type === 'block' ? 50 * this.vueFlowState.getTransform().zoom : 0), y: trashRect.y})
    trashRect.x = projectedXY.x 
    trashRect.y = projectedXY.y
    return this.vueFlowState.isNodeIntersecting(node, trashRect)
  }

  get gridSnapDimensions () {
    return this.$store.getters['elements/getGridSnapDimensions']
  }

  get draggingNode () {
    return this.$store.getters['elements/getDraggingElement']
  }

  get selectedLink (): ElementLink | undefined {
    return this.$store.getters['elements/getSelectedLink']
  }
  get draggedLink (): ElementLink | undefined {
    return this.$store.getters['elements/getDraggedLink']
  }

  @Watch('draggedLink')
  onDraggedLinkChange(newVal:ElementLink | undefined) {
    if(!newVal && this.connectionSuggested && this.selectedLink) {
      let newTargetID = this.selectedLink.target
      let newSourceID = this.selectedLink.source
      if(this.$store.getters['elements/getDraggedLinkSourceOrTarget'] === 'source') {
        newSourceID = this.connectionSuggested.id
      } else {
        newTargetID = this.connectionSuggested.id
      }
      this.$store.dispatch('elements/removeLink', this.selectedLink.id)
      this.$store.dispatch('elements/addLink', {
        source: newSourceID,
        target: newTargetID,
      })
      this.$store.dispatch('elements/setDraggedLink')
      this.$store.dispatch('elements/setSelectedLink')
      this.$store.dispatch('elements/setHoveredLink')
      this.$store.dispatch('elements/setIsLinkSuggested')
    }
  }

  snapToGrid(node: GraphNode, updateAPI?:boolean) {
    // Snap Node position to grid
    const newPos = this.closestGridPosition(node.position)
    this.vueFlowState.updateNodePositions([{  
        id: node.id,
        position: newPos,
        distance: {x: node.position.x - newPos.x, y: node.position.y - newPos.y},
        dimensions: node.dimensions,
        from: node.position
      }], true, false)
      if(updateAPI !== false) {
        this.handleUpdateAPINodePosition((node as GraphNode & ElementNode).extra.blockAPIID, newPos.y, newPos.x)
      }
      this.$store.dispatch('elements/updateElements', [node])

      this.$store.dispatch('elements/snapLinks', node.id)

      return newPos
  }

  closestGridPosition(position: PositionXY) {
    return { x : Math.round(position.x / this.gridSnapDimensions.width) * this.gridSnapDimensions.width, y: Math.round(position.y / this.gridSnapDimensions.height) * this.gridSnapDimensions.height }
  }

  closestGridPositionAvailable(position: PositionXY) {

    let positionTested = this.closestGridPosition(position)

    if(!this.isGridPositionAvailable(positionTested)) {
      if (position.y < positionTested.y) {
        positionTested.y -= this.gridSnapDimensions.height
        if (this.isGridPositionAvailable(positionTested)) {
          return positionTested
        } 
      }
      positionTested.y += this.gridSnapDimensions.height
      while (!this.isGridPositionAvailable(positionTested)) {
        positionTested.y += this.gridSnapDimensions.height
      }
    }

    return positionTested
  }

  isGridPositionAvailable (position: PositionXY):boolean {
    let ret = true
    this.listNodes.forEach((node:ElementNode) => {
      if (node.position.x === position.x && node.position.y === position.y) {
        ret = false
      }
    })
    return ret
  }

  onNodeDragStop(nodeDragEvent: NodeDragEvent) {
    this.$store.dispatch('elements/changeLinksType', 'link')

    if(nodeDragEvent.node.parentNode && nodeDragEvent.intersections?.length === 0) {
      this.$store.dispatch('elements/setNodeParent', {node: nodeDragEvent.node})
    } else {
      nodeDragEvent?.intersections?.forEach((intersection) => {
        if(intersection.type === 'platform') {
          this.$store.dispatch('elements/setNodeParent', {node: nodeDragEvent.node, parent: intersection})
        }
      })
    }
    this.$store.dispatch('elements/setDraggingElement')

    if(!this.handleDropOnTrash(nodeDragEvent.node)) {
      this.handleMoveOnOverlap(nodeDragEvent.node, nodeDragEvent.intersections)
    }
    setTimeout(() => {
      this.gridSnapHightlightRef.style.display = 'none'
    },200)
  }

  handleUpdateAPINodePosition(blockAPIID:string, lattitude:number, longitude:number) {
    this.$store.dispatch('elements/changeLinksType', 'default')
    setTimeout(() => {
      this.$store.dispatch('elements/changeLinksType', 'link')
    })
    API.blocks.editBlockPosition(blockAPIID, lattitude, longitude).then((data) => {
      this.$store.dispatch('blocksAPI/editBlock', data)
    })
  }

  handleMoveOnOverlap(node: GraphNode, intersections?: GraphNode[], forceDown?: boolean) {
    if(intersections && intersections.length && node.type === 'block' && intersections[0].type === 'block') {
      const colsestPosition = this.closestGridPosition(node.position)
      const newPos = { x : colsestPosition.x, y : intersections[0].position.y }
      if(forceDown) {
          newPos.y = newPos.y + this.gridSnapDimensions.height
      } else {
        if(newPos.y >= node.position.y) {
          newPos.y = newPos.y - this.gridSnapDimensions.height
        } else {
          newPos.y = newPos.y + this.gridSnapDimensions.height
        }
      }
      this.vueFlowState.updateNodePositions([{  
        id: node.id,
        position: newPos,
        distance: {x: 0, y: node.position.y - newPos.y},
        dimensions: node.dimensions,
        from: node.position
      }], true, false)

      nextTick(() => {
        const newIntersections = this.vueFlowState.getIntersectingNodes(node)
        if(newIntersections.length) {
          this.handleMoveOnOverlap(node, this.vueFlowState.getIntersectingNodes(node), true)
        } else {
          this.handleUpdateAPINodePosition((node as GraphNode & ElementNode).extra.blockAPIID, newPos.y, newPos.x)
          this.$store.dispatch('elements/updateElements', [node])
          this.$store.dispatch('elements/snapLinks', node.id)
        }
      })
    } else {
      this.$store.dispatch('elements/pushHistoric')
      this.snapToGrid(node)
    }
  }

  handleDropOnTrash(node: GraphNode) :boolean {
    let ret = false
    if(this.isTrashHovered) {
      this.deleteNode(node)
      ret = true
    }
    this.isTrashHovered = false
    return ret
  }

  deleteNode(node: GraphNode) {
    API.blocks.delete((node as GraphNode & ElementNode).extra.blockAPIID)
    .then(() => {
      this.$store.dispatch('elements/setDetailOpenElement')
    })
    this.$store.dispatch('elements/removeNode', {node: node, delayed: true})
  }

  get isABlockDisappearing(): boolean {
    return this.$store.getters['elements/getDisappearingElement']
  }

  get listNodes(): ElementNode[] {
    return this.$store.getters['elements/getNodes']
  }

  @Watch('listNodes')
  onListNodesChange(newList:ElementNode[], oldList:ElementNode[]) {
    if(oldList.length < newList.length) {
      this.onNodeCreation()
    }
  }


  get connectionSuggested (): Element | null {
    return this.$store.getters['elements/getLinkSuggested']
  }

}
