
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import { nextTick } from 'vue';

class Props {
  placeholder?: string = prop({
    required: false,
  });
  error?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  modelValue?: string = prop({
    required: false,
  });
  min?: number = prop({
    required: false,
  });
  max?: number = prop({
    required: false,
  });
  delay?: number = prop({
    required: false,
  });
}


@Options({
  components: {
    Icon,
  },
})
export default class CustomTextInput extends Vue.with(Props) {
  timeoutDelay = 1500
  timeout?:number

  mounted(): void {
    if(this.delay) {
      this.timeoutDelay = this.delay
    }
  }
      

  handleInput (event:Event) {
    const target = event.target as HTMLInputElement
    // this.$emit('update:modelValue', target?.value)

    let value = target?.value ? parseInt(target?.value) : 0
    if(this.min !== undefined) {
      if(value <= this.min) {
        value = this.min
      }
    }
    if(this.max !== undefined) {
      if(value >= this.max) {
        value = this.max
      }
    }
    nextTick(() => {
      this.$emit('update:modelValue', value)
      this.onValueChage()
    })
  }

  numberUp () {
    const newVal = this.modelValue ? parseInt(this.modelValue) + 1 : 1;
    this.$emit('update:modelValue', this.max ? (newVal <= this.max ? newVal : this.max) : newVal)
    this.onValueChage()
  }
  numberDown () {
    const newVal = this.modelValue ? parseInt(this.modelValue) - 1 : 0;
    this.$emit('update:modelValue', this.min ? (newVal >= this.min ? newVal : this.min) : newVal)
    this.onValueChage()
  }

  onValueChage() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.$emit('delayedUpdate', this.modelValue)
    }, this.timeoutDelay)
  }

}
