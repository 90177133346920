
import CustomButton from '@/components/UIElements/CustomButton.vue';
import ProjectsSneakPeek from '@/components/Projects/ProjectSneakPeek.vue';
import { Vue, Options } from 'vue-class-component';
import API from '@/api/wrapper';
import { Block } from '@/types';
import { APIBlockCategory, APIProject } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    ProjectsSneakPeek
  },
})
export default class ProjectsView extends Vue {

  get projectsList():APIProject[] {
    return this.$store.getters['projects/getProjectList']
  }

  mounted(): void {
    API.blocks.getCategories()
    .then(({blockCategories, definitions}) => {
      const blocks:Block[] = []
      blockCategories.forEach((blockCategory:APIBlockCategory) => {
        const block:Block = {
          ...blockCategory.attributes,
          id: blockCategory.id,
          preselectedFieldDefinitionId: blockCategory?.relationships.preselect_field_definition.data.id
        }
        blocks.push(block)
      })
      this.$store.dispatch('blocks/setBlockList', blocks)
      this.$store.dispatch('fields/addNodeFieldDefinitions', definitions)
    })
  }

  createProject() {
    API.projects.create()
    .then((newProject:APIProject) => {
      newProject.attributes.state = this.$enums.BadgeStates.Neutral
      this.projectsList.push(newProject)
    })
  }

}
