import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["fransys-signature", _ctx.vertical ? 'v-col pa-0' : 'v-row ma-0'])
  }, [
    (_ctx.vertical)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_Logo, {
            logo: "fransys",
            class: "mx-0 my-auto"
          }),
          _createVNode(_component_Logo, {
            logo: "fransys-txt",
            class: "mx-0 mt-3 my-auto"
          })
        ], 64))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createVNode(_component_Logo, {
            logo: "fransys",
            class: "mx-0 my-auto"
          }),
          _createVNode(_component_Logo, {
            logo: "fransys-txt",
            class: "mx-0 ml-3 my-auto"
          })
        ], 64))
  ], 2))
}