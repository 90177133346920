
import Switch from '@/components/UIElements/Switch.vue';
import Divider from '@/components/UIElements/Divider.vue';
import Table from '@/components/UIElements/Table.vue';
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Modal from '@/components/UIElements/Modal.vue';
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import { APICustomer, APIInvoice, APIPaymentMethod } from '@/typesAPI';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue';
import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue';
import Badge from '@/components/UIElements/Badge.vue';
import API from '@/api/wrapper';
import { Watch } from 'vue-property-decorator';

@Options({
  components: {
    Switch,
    Divider,
    CustomButton,
    Table,
    Modal,
    CustomNumberInput,
    Icon,
    CustomTextInput,
    WidgetBillingDetails,
    StripeWidgetPaymentMethods,
    ModalPurchaseCredit,
    PaymentMethodsDisplay,
    Badge
  },
})
export default class BillingView extends Vue {

  switchValue = false
  showPurchaseCreditModal = false
  showAddPaymentMethodModal = false
  showBillingDetailModal = false
  switchDisabled = false
  creditAmountToAdd = 100

  loadingModalCTA = false


  initialBillingDetailValues = {}

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get currency():string {
    return this.customer?.attributes?.currency
  }

  get consumed():number {
    return this.$store.getters['billing/getRessourceUsed']
  }

  get currentBillingPeriod(): {end:Date,start:Date} {
    return this.$store.getters['billing/getCurrentBillingPeriod']
  }

  get projectedUsage():number {
    return this.$store.getters['billing/getProjectedUsage']
  }

  get invoices():APIInvoice[] {
    let invoices = this.$store.getters['billing/getInvoices']
    if(invoices?.length) {
      invoices = invoices.filter((invoice:APIInvoice) => invoice.attributes.status !== 'draft')
    }
    return invoices
  }

  get paymentMethods():APIPaymentMethod[] {
    return this.$store.getters['billing/getPaymentMethods']
  }

  get invoiceValues():string[][] {
    return this.invoices?.map((invoice) => {
      let col1 = ""
      if(invoice.attributes.type === 'tally') {
        col1 = "Monthly usage : from " + new Date(invoice.attributes.start).toLocaleDateString() + ' to ' + new Date(invoice.attributes.end).toLocaleDateString()
      } else if(invoice.attributes.type === 'credits') {
        const key0 = Object.keys(invoice.attributes.lines)[0]
        col1 = 'Prepaid credits : ' + invoice.attributes.lines[key0].period_value + ' credits'
      } else {
        const key0 = Object.keys(invoice.attributes.lines)[0]
        col1 = invoice.attributes.lines[key0].label + ' : ' + invoice.attributes.lines[key0].period_value + ' ' + invoice.attributes.lines[key0].period_unit
      }
      return [
        col1,
        this.$helpers.prices.format(invoice.attributes.amount_total, this.currency),
        invoice.attributes.receipt_pdf ? invoice.attributes.receipt_pdf : '' 
      ]
    })
  }

  downloadInvoice(url:string) {
    // create element <a> for download PDF
      const link = document.createElement('a');
      link.href = url;
      // link.target = '_blank';
      link.download = url.substring(url.lastIndexOf('/') + 1);

      // Simulate a click on the element <a>
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  }

  onSwitchChangeValue() {
    this.switchDisabled = true
    API.billing.editCustomer(this.customer?.id, {has_auto_provisioning_enabled : this.switchValue})
    .finally(() => {
      this.switchDisabled = false
    })
  }

  onAddPaymentMethod() {
    this.showAddPaymentMethodModal = false
  }

  @Watch('customer', {immediate: true})
  onCustomerChange() {
    if(this.customer) {
      this.switchValue = this.customer.attributes.has_auto_provisioning_enabled
    }
  }
}
