import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["validation-errors-bar bar pa-2 d-flex", {hidden:_ctx.hidden}])
  }, [
    _createVNode(_component_CustomButton, {
      level: _ctx.$enums.ButtonLevels.Tertiary,
      class: "validation-error-bar-button",
      onClick: _ctx.openModal
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Icon, {
          icon: _ctx.icon,
          color: _ctx.iconColor,
          class: _normalizeClass(_ctx.list.length ? 'mr-4' : 'mx-1')
        }, null, 8, ["icon", "color", "class"]),
        (_ctx.errors.length)
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 0,
              state: "danger",
              label: _ctx.errors.length,
              class: _normalizeClass({'mr-2' : _ctx.warnings.length || _ctx.infos.length})
            }, null, 8, ["label", "class"]))
          : _createCommentVNode("", true),
        (_ctx.warnings.length)
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 1,
              state: "warning",
              label: _ctx.warnings.length,
              class: _normalizeClass({'mr-2' : _ctx.infos.length})
            }, null, 8, ["label", "class"]))
          : _createCommentVNode("", true),
        (_ctx.infos.length)
          ? (_openBlock(), _createBlock(_component_Badge, {
              key: 2,
              state: "info",
              label: _ctx.infos.length
            }, null, 8, ["label"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["level", "onClick"])
  ], 2))
}