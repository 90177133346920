
import { Options, Vue, prop } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import Icon from '@/components/UIElements/Icon.vue';

class Props {
  price:number = prop({
    required: true,
  });
  price2?:number = prop({
    required: false,
  });
  currency:string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Icon
  },
})
export default class PriceDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'h',
      text: 'hour',
    },
    {
      id: 'd',
      text: 'day',
    },
    {
      id: 'm',
      text: 'month',
    },
    {
      id: 'y',
      text: 'year',
    },
  ]  
  selectedItem:ListItem = {}

  mounted(): void {
    this.selectedItem = this.burgerItems.find((item) => item.id === 'm') as ListItem
    this.selectedItem.selected = true
  }

  onBurgerMenuItemClick(item:ListItem) {
    this.selectedItem = item
    this.burgerOpened = false
    this.burgerItems.forEach((i) => {
      i.selected = (this.selectedItem.id === i.id)
    })
  }

  get displayPrice():number {
    let ret = 0
    switch(this.selectedItem.id) {
      case 'h' :
        ret = this.price / 1440
        break;
      case 'd' :
        ret = this.price / 30
        break;
      case 'm' :
        ret = this.price
        break;
      case 'y' :
        ret = this.price * 12
        break;
    }

    return ret
  }

  get displayPrice2():number {
    let ret = 0
    if(this.price2) {
      switch(this.selectedItem.id) {
        case 'h' :
          ret = this.price2 / 1440
          break;
        case 'd' :
          ret = this.price2 / 30
          break;
        case 'm' :
          ret = this.price2
          break;
        case 'y' :
          ret = this.price2 * 12
          break;
      }
    }
    
    return ret
  }
}
