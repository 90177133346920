
import { Vue, Options } from 'vue-class-component';
import { Environment } from '@/types'
import { Ref } from 'vue-property-decorator';
import Icon from '@/components/UIElements/Icon.vue'
import List, { ListItem } from '@/components/UIElements/List.vue'

@Options({
  components: {
    Icon,
    List
  },
})
export default class EnvironmentsBar extends Vue {
  @Ref('envList') readonly envList!: HTMLElement

  deployed = false
  selectedEnv:Environment | null = null

  deploy() {
    if(this.deployed) {
      this.collapse()
    } else {
      this.expand()
    }
  }

  collapse() {
    let sectionHeight = this.envList.scrollHeight;
    let elementTransition = this.envList.style.transition;
    this.envList.style.transition = '';
    requestAnimationFrame(() => {
      this.envList.style.height = sectionHeight + 'px';
      this.envList.style.transition = elementTransition;
      requestAnimationFrame(() => {
        this.envList.style.height = 0 + 'px';
      });
    });
    this.deployed = false
  }

  expand() {
    let sectionHeight = this.envList.scrollHeight;
    this.envList.style.height = sectionHeight + 'px';
    const handleEventEnd = () => {
      this.envList.removeEventListener('transitionend', handleEventEnd);
      this.envList.style.height = 'auto';
    };
    this.envList.addEventListener('transitionend', handleEventEnd)
    
    this.deployed = true
  }

  selectEnv(env: Environment) {
    this.selectedEnv = env
  }

  get listEnvironments () : Environment[] {
    return this.$store.getters['environments/getList']
  }

  get listItems (): ListItem[] {
    return this.listEnvironments.map((env) => {
      return {
        text: env.label,
        selected: env.id === '0' ? true : false
      }
    })
  }

  mounted(): void {
    this.selectEnv(this.listEnvironments[0])
  }
}
