
import { Vue, prop, Options } from 'vue-class-component';
import { Block, Platform, PositionXY } from '@/types'
import BlockItemShadow from '@/components/Blocks/BlockItemShadow.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import { Ref } from 'vue-property-decorator';

class Props {
  block: Block | Platform = prop({
    required: true,
  });
  type: string = prop({
    required: true,
  });
  positionXY?: PositionXY = prop({
    required: false,
  });
  linkSrc?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    BlockItemShadow,
    Icon,
    Logo
  },
})
export default class BlockItem extends Vue.with(Props) {
  isDragging = false
  isClickDown = false
  shadowLeft = 0
  shadowTop = 0
  offset = {left: 0, top: 0}
  @Ref('htmlElement') readonly htmlElement!: HTMLElement

  get isAddLocked () {
    return this.$store.getters['elements/isLocked']
  }

  addElementNode () {
    if(!this.isAddLocked) {
      this.$store.dispatch('elements/addNode', {block:this.block, type:this.type, positionXY: this.positionXY, linkSrc: this.linkSrc})
      this.$emitter.emit('addNodeFromClick')
      this.$emit('addNode')
    }
  }

  onMouseDown() {
    this.isClickDown = true
  }
  onMouseUp() {
    this.isClickDown = false
  }

  onDragStart(event:DragEvent) {
    document.addEventListener('dragover', this.updateShadow)

    this.isDragging = true
    this.isClickDown = false
    if(this.type === 'block') {
      this.$store.dispatch('blocks/setDraggingBlock', this.block)
    } else {
      this.$store.dispatch('platforms/setDraggingPlatform', this.block)
    }
    const img = new Image();
    if(event.dataTransfer) {
      event.dataTransfer.setDragImage(img, 0, 0);
    }
    const rect = this.htmlElement.getBoundingClientRect();
    this.offset = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    };
  }
  onDragEnd() {
    document.removeEventListener('dragover', this.updateShadow)

    this.isDragging = false
    this.$store.dispatch('blocks/setDraggingBlock')
  }

  updateShadow(event:DragEvent) {
    const rect = this.htmlElement.getBoundingClientRect();

    const offset = {
      left: rect.left + window.scrollX,
      top: rect.top + window.scrollY
    }
    this.shadowLeft = event.x - this.offset.left - 68
    this.shadowTop = event.y - this.offset.top - 20
  }
    
}
