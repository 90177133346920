import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "block-item-icon" }
const _hoisted_2 = { class: "block-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_BlockItemShadow = _resolveComponent("BlockItemShadow")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["block-item", {'block-item-dragged' : _ctx.isDragging, 'block-item-click-down' : _ctx.isClickDown}]),
    ref: "htmlElement",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addElementNode && _ctx.addElementNode(...args))),
    draggable: "true",
    onDragstart: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onDragStart && _ctx.onDragStart(...args))),
    onDragend: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onDragEnd && _ctx.onDragEnd(...args))),
    onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
    onMouseup: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onMouseUp && _ctx.onMouseUp(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.block.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            icon: _ctx.block.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      (_ctx.block.logo)
        ? (_openBlock(), _createBlock(_component_Logo, {
            key: 1,
            logo: _ctx.block.logo
          }, null, 8, ["logo"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.block.name), 1),
    (_ctx.isDragging)
      ? (_openBlock(), _createBlock(_component_BlockItemShadow, {
          key: 0,
          block: _ctx.block,
          style: _normalizeStyle({left: _ctx.shadowLeft + 'px', top:_ctx.shadowTop + 'px'})
        }, null, 8, ["block", "style"]))
      : _createCommentVNode("", true)
  ], 34))
}