
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Icon from '@/components/UIElements/Icon.vue'

@Options({
  components: {
    Logo,
    Badge,
    CustomButton,
    Icon
  },
})
export default class LogoBar extends Vue {
  hover = false
  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING

  onClick() {
    this.$router.push({name:'home'})
  }
}
