
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomNumberInput from '@/components/UIElements/CustomNumberInput.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { Watch } from 'vue-property-decorator';
import { APICustomer } from '@/typesAPI';
import API from '@/api/wrapper'
import FransysSignature from '@/components/UIElements/FransysSignature.vue'

class Props {
  visible?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    CustomNumberInput,
    Icon,
    FransysSignature
  },
})
export default class ModalEditLimits extends Vue.with(Props) {
  loading = false
  burnRateLimit = 0
  hardLimit = 0
  buttonState = ""
  buttonText = "Save limits"

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get currency():string {
    return this.customer?.attributes?.currency
  }
  
  saveLimitsChange() {
    this.loading = true
    API.billing.editCustomer(this.customer.id,{monthly_burn_rate_limit: this.burnRateLimit * 100, monthly_hard_limit: this.hardLimit * 100})
    .then(() => {
      this.loading = false
      this.buttonState = this.$enums.ButtonStates.Success
      this.buttonText = "Success"
      setTimeout(() => {
        this.buttonState = ""
        this.buttonText = "Save limits"
        this.closeModal()
      },3000)
    })
  }

  closeModal() {
    this.$emit('update:visible',false)
  }

  @Watch('customer', {immediate: true})
  onCustomerChange() {
    if(this.customer) {
      this.burnRateLimit = this.customer?.attributes.monthly_burn_rate_limit / 100
      this.hardLimit = this.customer?.attributes.monthly_hard_limit / 100
    }
  }

}
