
import { Vue, prop, Options } from 'vue-class-component';
import { Block, PositionXY } from '@/types'
import BlockItem from '@/components/Blocks/BlockItem.vue'
import { APIFieldDefinition } from '@/typesAPI';

class Props {
  block: Block = prop({
    required: true,
  });
  type: string = prop({
    required: true,
  });
  positionXY?: PositionXY = prop({
    required: false,
  });
  linkSrc?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    BlockItem
  },
})
export default class BlockCategory extends Vue.with(Props) {
 
  get preselectFieldDefinition():APIFieldDefinition {
    return this.$store.getters['fields/getDefinitionsByID'](this.block.preselectedFieldDefinitionId)
  }


  get computedBlocks():Block[] {
    const ret:Block[] = []
  
    if(this.preselectFieldDefinition) {
      Object.entries(this.preselectFieldDefinition.attributes.possible_values).forEach(entry => {
        const [key, value] = entry;
        ret.push({
          name: value,
          key:key,
          logo:key,
          defaultValueSelected: key,
          id:this.block.id,
          preselectedFieldDefinitionId: this.block.preselectedFieldDefinitionId
        })
      });
    }
   

    return ret
  }

  get test() {
    return this.preselectFieldDefinition.attributes.possible_values
  }
    
}
