
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { ButtonLevels } from '@/enums'

class Props {
  level?: typeof ButtonLevels = prop({
    required: false,
  });
  text?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  status?: string = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    Spinner
  },
})
export default class CustomButton extends Vue.with(Props) {

  get computedIcon() {
    let ret = this.icon ? this.icon : ''
    switch(this.status) {
      case this.$enums.ButtonStates.Success:
        ret = 'check-circle'
        break;
      case this.$enums.ButtonStates.Danger:
        ret = 'cancel-circle'
        break;
      case this.$enums.ButtonStates.Warning:
        ret = 'warning'
        break;
      default:
        break;
    }
    if( this.loading ) {
      ret = 'spinner'
    }
    return ret
  }

  get hasText() {
    return this.text || this.$slots.default
  }
  get computedDisabled() {
    return this.disabled || this.loading || Object.values(this.$enums.ButtonStates).includes(this.status)
  }
}



