
import { Block } from "@/types"

type State = {
  list: Block[],
  draggingBlock?: Block
}

const state: State = {
  list : []
}

const getters = {
  getList: (state:State) => {
    return state.list
  },
  getDraggingBlock: (state:State) => {
    return state.draggingBlock
  },
  getByID: (state:State) => {
    return (id:string) => {
      return state.list.find(block => block.id === id)
    }
  },    
  getByKey: (state:State) => {
    return (key:string) => {
      return state.list.find(block => block.key === key)
    }
  },      
}

const mutations = {
  SET_BLOCK_LIST: (state:State, blockList:Block[]) => {
    state.list = blockList
  },
  SET_DRAGGING_BLOCK: (state:State, block:Block) => {
    state.draggingBlock = block
  },
}

const actions = {
  setBlockList: (context:any, blockList:Block[]) => {
    context.commit('SET_BLOCK_LIST', blockList)
  },
  setDraggingBlock: (context:any, block:Block) => {
    context.commit('SET_DRAGGING_BLOCK', block)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
}