import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6952bfea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "block-item block-item-shadow" }
const _hoisted_2 = { class: "block-item-icon" }
const _hoisted_3 = { class: "block-item-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_Logo = _resolveComponent("Logo")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.block.icon)
        ? (_openBlock(), _createBlock(_component_Icon, {
            key: 0,
            icon: _ctx.block.icon
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      (_ctx.block.logo)
        ? (_openBlock(), _createBlock(_component_Logo, {
            key: 1,
            logo: _ctx.block.logo
          }, null, 8, ["logo"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.block.name), 1)
  ]))
}