
import { Vue, Options } from 'vue-class-component'
import Logo from '@/components/UIElements/Logo.vue'
import Badge from '@/components/UIElements/Badge.vue'
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue'

@Options({
  components: {
    Logo,
    Badge,
    ModalRenameRessource
  },
})
export default class ProjectBar extends Vue {

  showModalRenameProject = false

  // FIRST UNBOARDING
  get collapsed () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING


  get currentProject() {
    return this.$store.getters['projects/getCurrentProject']
  }
}
