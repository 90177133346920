
import { Vue, Options, prop } from 'vue-class-component'
import { APIFieldDefinition, APIFieldValue, APIUrlRedirection } from '@/typesAPI'
import ShellDetailField from '@/components/Details/ShellDetailField.vue'
import {Props as ListElementProps} from '@/components/UIElements/ListElement.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Avatar from '@/components/UIElements/Avatar.vue';
import API from '@/api/wrapper';
import { APISocialAccount } from "@/typesAPI"
import { Watch } from 'vue-property-decorator';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  definitionAttributes?: APIFieldDefinition['attributes'] = prop({
    required: false,
  });
  fieldValue?: APIFieldValue = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  solo?: boolean = prop({
    required: false,
  });
  fieldSummary?: ListElementProps = prop({
    required: false,
  });
  forcedSocialAccount?: APISocialAccount = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton,
    ShellDetailField,
    Avatar,
    DropdownMenu
  },
})
export default class DetailFieldSocialAccount extends Vue.with(Props) {

  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'disconnect',
      text: 'Disconnect',
    },
  ]
  callbackCode = ''


  onBurgerMenuItemClick (newVal:ListItem) {
    newVal.selected = false
    this.burgerOpened = false

    switch(newVal.id) {
      case 'disconnect' : 
        this.disconnect()
      break;
      case 'permissions' : 
        this.connect()
      break;
      default:
      break;
    }
  }

  @Watch('socialAccountProvider', {immediate: true})
  onSocialAccountProviderChange() {
    if(this.socialAccountProvider === 'github') {
      this.burgerItems.push({
        id: 'permissions',
        text: 'Manage permissions',
      })
    }
  }

  get socialAccountProvider():string {
    if(this.forcedSocialAccount) {
      return this.forcedSocialAccount.attributes.provider
    }
    return this.definitionAttributes?.meta?.provider ? this.definitionAttributes.meta.provider : ""
  }

  get socialAccountProviderIntegrationImg():string | undefined {
    try {
      return require('@/assets/illustrations/' + this.socialAccountProvider +'_integration.svg') + ""
    } catch {
      return undefined
    }
  }

  get socialAccountProviderName():string {
    return this.definitionAttributes?.meta?.provider_name ? this.definitionAttributes.meta.provider_name : ""
  }

  mounted(): void {
    this.$emit('update:fieldSummary', {
      text: this.preSelected,
    })

    this.getSocialAccount()
  }

  get preSelected(): string {
    return this.socialAccount ? this.socialAccount?.attributes?.nickname : '-'
  }

  getSocialAccount() {
    if(this.fieldValue && this.fieldValue.attributes.value && !this.socialAccount) {
      API.social.get(this.fieldValue.attributes.value)
      .then((socialAccount:APISocialAccount) => {
        this.$store.dispatch('user/setSocialAccount', {provider: this.socialAccountProvider, socialAccount: socialAccount})
      })
    }
  }

  connect() {    
    const paramsProjectIdOrigin = this.$route.params.projectId ? `projectId=${this.$route.params.projectId}` : ''
    const paramsBlockIdOrigin = this.$route.params.blockId ? `&blockId=${this.$route.params.blockId}` : ''
    const paramsTabIdOrigin = this.$route.params.tabId ? `&tabId=${this.$route.params.tabId}` : ''
    const paramsBoxIdOrigin = this.$route.params.boxId ? `&boxId=${this.$route.params.boxId}` : ''
    const origin = window.location.origin
    const newUrl = `${origin}/socialAccounts/${this.socialAccountProvider}/callback?${paramsProjectIdOrigin}${paramsBlockIdOrigin}${paramsTabIdOrigin}${paramsBoxIdOrigin}`

    API.social.connect(this.$store.getters['user/getUser'].id, this.socialAccountProvider, newUrl)
    .then((urlRedirection:APIUrlRedirection) => {
      window.location.href = urlRedirection.attributes.url
    })
  }

  disconnect() {
    if(this.fieldValue) {
      API.social.delete(this.fieldValue.attributes.value)
      .then(() => {
        this.$store.dispatch('user/deleteSocialAccount', this.socialAccountProvider)
        this.$emit('changeValue',this.definitionAttributes?.key, null, this.fieldValue?.id)
      })
    }
  }

  @Watch('socialAccount', {immediate: true})
  onSocialAccountChange() {
    if(this.socialAccount) {
      this.$emit('update:fieldSummary', {
        text: this.socialAccount.attributes.nickname
      })
      if(this.socialAccount.id !==  this.fieldValue?.attributes?.value) {
        this.$emit('changeValue',this.definitionAttributes?.key, this.socialAccount.id, this.fieldValue?.id)
      }
    }
  }

  get socialAccount():APISocialAccount | undefined {
    return this.forcedSocialAccount ? this.forcedSocialAccount : this.$store.getters['user/getSocialAccountsByProvider'][this.socialAccountProvider]
  }

}
