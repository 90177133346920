
import { Options, Vue } from 'vue-class-component';
import BlockItem from '@/components/Blocks/BlockItem.vue'
import BlockCategory from '@/components/Blocks/BlockCategory.vue'
import { Block, Platform } from '@/types'

@Options({
  components: {
    BlockItem,
    BlockCategory
  },
})
export default class BlockBarMenu extends Vue {

  // FIRST UNBOARDING
  get hideLeft () {
    return !this.$store.getters['app/getFirstUnboardingDone']
  }
  // END FIRST UNBOARDING

  get listBlocks () : Block[] {
    return this.$store.getters['blocks/getList']
  }
  get listPlatforms () : Platform[] {
    return this.$store.getters['platforms/getList']
  }
}
