
import { Vue } from 'vue-class-component';
import API from '@/api/wrapper';
import { APIUser } from "@/typesAPI"

export default class LoginSocialCallbackView extends Vue {

  socialAccountProvider = ""

  mounted(): void {

    this.socialAccountProvider = this.$route.params.socialProvider.toString()

    API.session.loginViaSocialCallback(this.socialAccountProvider, this.$route.query.code as string)
    .then((meDatas:APIUser) => {
      this.$store.dispatch('user/setUser', meDatas)
      this.$router.replace({
        name: 'home'
      });
    })
    .catch(() => {
      this.$router.push({name: 'login'})
    })
  }
}
