import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["logo-bar bar d-flex", {collapsed:_ctx.collapsed, hover:_ctx.hover}]),
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
    onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
  }, [
    (_ctx.hover)
      ? (_openBlock(), _createBlock(_component_CustomButton, {
          key: 0,
          level: _ctx.$enums.ButtonLevels.Tertiary,
          class: "back-button my-auto",
          onClick: _ctx.onClick
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Icon, { icon: "caret-left" })
          ]),
          _: 1
        }, 8, ["level", "onClick"]))
      : (_openBlock(), _createBlock(_component_v_col, {
          key: 1,
          class: "ma-auto pa-0 py-2"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_v_row, { class: "pa-0 ma-0 d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_v_col, { class: "logo-container pa-0 ma-auto" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_Logo, { logo: "fransys" })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
  ], 34))
}