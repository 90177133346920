

import router from "@/router"
import { APIDeployment, APIProject } from "@/typesAPI"
import store from ".."
import API from "@/api/wrapper"
import enums from "@/enums"
import helpers from "@/utils/helpers"

type State = {
  currentProjectId: string,
  projectList:APIProject[] | null
  projectsStates:{[key:string]:string}
}

const state: State = {
  currentProjectId: "",
  projectList: null,
  projectsStates: {}
}

const getters = {
  getCurrentProjectId: (state:State) => {
    return router.currentRoute.value.params.projectId
  },
  getProjectList: (state:State) => {
    if(state.projectList === null) {
      API.projects.getList()
      .then((res:APIProject[]) => {
        state.projectList = res
      })
      return []
    }
    return state.projectList
  },
  getCurrentProject: (state:State) => {
    const projectList:APIProject[] = store.getters['projects/getProjectList']
    return projectList ? projectList.find((obj:APIProject) => obj.id === router.currentRoute.value.params.projectId) : null
  },
  getProjectsStatus: (state:State) => {
    const projectList = store.getters["projects/getProjectList"]
    if(projectList && Object.keys(state.projectsStates).length !== projectList.length) {
      projectList.forEach((project:APIProject) => {
        state.projectsStates[project.id] = enums.DeploymentStatus.OFFLINE
        API.blocks.getIndex(project.id, "deployments")
        .then(({deployments}) => {
          if(deployments.find((deployment) => deployment.attributes.status === enums.DeploymentStatus.CRITICAL)) {
            state.projectsStates[project.id] = enums.DeploymentStatus.CRITICAL
          } else if(deployments.find((deployment) => deployment.attributes.status === enums.DeploymentStatus.DEGRADED)) {
            state.projectsStates[project.id] = enums.DeploymentStatus.DEGRADED
          } else if(deployments.find((deployment) => deployment.attributes.status === enums.DeploymentStatus.UNKNOWN)){
            state.projectsStates[project.id] = enums.DeploymentStatus.UNKNOWN
          } else if(deployments.find((deployment) => deployment.attributes.status === enums.DeploymentStatus.HEALTHY)){
            state.projectsStates[project.id] = enums.DeploymentStatus.HEALTHY
          } else {
            state.projectsStates[project.id] = enums.DeploymentStatus.OFFLINE
          }
        })
      })
    }
    return state.projectsStates
  },
}

const mutations = {
  UPDATE_PROJECT: (state:State, project:APIProject) => {
    if(state.projectList) {
      helpers.ressources.addMultipleRessourceToArray(state.projectList, [project])
    } else {
      state.projectList = [project]
    }
  },
}

const actions = {
  clearStore: (context:any) => {
    context.dispatch('errorsValidation/setList', [], {root:true})
    context.dispatch('blocksAPI/setBlockList', [], {root:true})
    context.dispatch('elements/setNodesFromAPIBlocks',  [], {root:true})
    context.dispatch('elements/setLinksFromAPIConnections',  [], {root:true})
    context.dispatch('fields/setFieldsValue',  [], {root:true})
    context.dispatch('fields/setFieldTabs',  [], {root:true})
    context.dispatch('fields/setFieldBoxes',  [], {root:true})
    context.dispatch('fields/cleanTabLoaded',  [], {root:true})
    context.dispatch('errorsValidation/closeModal',  [], {root:true})
    context.dispatch('errorsValidation/closeWarningPopup',  [], {root:true})
    context.dispatch('errorsValidation/setList',  [], {root:true})
    context.dispatch('deployment/clear',  null, {root:true})
    // context.dispatch('modals/hideAll',  null, {root:true})
  },
  updateProject: (context:any, project:APIProject) => {
    context.commit('UPDATE_PROJECT', project)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}