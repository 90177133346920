
import { Vue, prop } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

class Props {
  modelValue: boolean = prop({
    required: true,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

export default class Checkbox extends Vue.with(Props) {
  innerValue = false

  mounted() {
    this.innerValue = this.modelValue
  }

  handleChange () {
    this.$emit('update:modelValue', !this.modelValue)
  }

  @Watch('modelValue')
  onModelValueChange(newVal:boolean, oldVal:boolean) {
    if(newVal !== this.innerValue) {
      this.innerValue = this.modelValue
    }
  }
}
