import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-70262b6b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ml-1 token-text-medium" }
const _hoisted_2 = { class: "ml-1 token-text-medium" }
const _hoisted_3 = { class: "ml-1 token-text-medium" }
const _hoisted_4 = { class: "ml-1 token-text-medium" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaymentMethodLogo = _resolveComponent("PaymentMethodLogo")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Badge = _resolveComponent("Badge")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "payment-method-display pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
        default: _withCtx(() => [
          (_ctx.paymentMethod.attributes.type === 'paypal')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_PaymentMethodLogo, { pmlogo: "paypal" })
                  ]),
                  _: 1
                }),
                _createVNode(_component_v_col, { class: "pa-0 ml-4" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Paypal ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.paymentMethod.attributes.object.payer_email), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ], 64))
            : (_ctx.paymentMethod.attributes.type === 'card')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_PaymentMethodLogo, {
                        pmlogo: _ctx.paymentMethod?.attributes?.object?.brand
                      }, null, 8, ["pmlogo"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "pa-0 ml-4 my-auto" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$enums.CardBrandsDisplay[_ctx.paymentMethod.attributes.object.display_brand]) + " ", 1),
                          _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.paymentMethod.attributes.object.last4), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_v_row, { class: "ma-0" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Expires "),
                          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.paymentMethod.attributes.object.exp_month) + "/" + _toDisplayString(_ctx.paymentMethod.attributes.object.exp_year - 2000), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ], 64))
              : (_ctx.paymentMethod.attributes.type === 'sepa_debit')
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaymentMethodLogo, { pmlogo: "sepa" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0 ml-4 my-auto" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "ma-0" }, {
                          default: _withCtx(() => [
                            _createTextVNode(" SEPA mandate "),
                            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.paymentMethod.attributes.object.fingerprint), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, { class: "ma-0" })
                      ]),
                      _: 1
                    })
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                    _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_PaymentMethodLogo, { pmlogo: "" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0 ml-4 my-auto" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "ma-0" }, {
                          default: _withCtx(() => [
                            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.paymentMethod.attributes.type), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_v_row, { class: "ma-0" })
                      ]),
                      _: 1
                    })
                  ], 64)),
          (_ctx.isDefault)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 4,
                class: "pa-0 ml-auto fit-content my-auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_Badge, {
                    label: "Default",
                    icon: "star",
                    state: _ctx.$enums.BadgeStates.Info,
                    class: _normalizeClass({'mr-2': _ctx.withBurger})
                  }, null, 8, ["state", "class"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.withBurger)
            ? (_openBlock(), _createBlock(_component_v_col, {
                key: 5,
                class: "pa-0 ml-auto fit-content my-auto"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CustomButton, {
                    ref: "menuButton",
                    class: "px-3",
                    icon: "burger",
                    level: _ctx.$enums.ButtonLevels.Tertiary,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.burgerOpened = !_ctx.burgerOpened))
                  }, null, 8, ["level"]),
                  _createVNode(_component_DropdownMenu, {
                    items: _ctx.burgerItems,
                    show: _ctx.burgerOpened,
                    "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.burgerOpened) = $event)),
                    maxContent: true,
                    "onUpdate:modelValue": _ctx.onBurgerMenuItemClick,
                    target: _ctx.$refs.menuButton,
                    classInternal: "mt-2"
                  }, null, 8, ["items", "show", "onUpdate:modelValue", "target"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}