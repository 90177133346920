import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2eee2c12"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-number-input-container" }
const _hoisted_2 = ["min", "max", "placeholder", "value"]
const _hoisted_3 = {
  key: 1,
  class: "custom-number-input-buttons"
}
const _hoisted_4 = ["disabled"]
const _hoisted_5 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.icon)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          class: "custom-number-input-icon",
          icon: _ctx.icon
        }, null, 8, ["icon"]))
      : _createCommentVNode("", true),
    _createElementVNode("input", {
      class: _normalizeClass(["custom-number-input", {'has-error': _ctx.error, 'has-error-fixed': false, 'has-icon': _ctx.icon, disabled: _ctx.disabled}]),
      type: "number",
      min: _ctx.min,
      max: _ctx.max,
      placeholder: _ctx.placeholder,
      value: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42, _hoisted_2),
    (!_ctx.disabled)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            class: "up",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.numberUp && _ctx.numberUp(...args))),
            disabled: _ctx.disabled ? true : (_ctx.modelValue && _ctx.max ? parseInt(_ctx.modelValue) >= _ctx.max : false)
          }, [
            _createVNode(_component_Icon, { icon: "caret-up-small" })
          ], 8, _hoisted_4),
          _createElementVNode("button", {
            class: "down",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.numberDown && _ctx.numberDown(...args))),
            disabled: _ctx.disabled ? true : (_ctx.modelValue && _ctx.min ? parseInt(_ctx.modelValue) <= _ctx.min : false)
          }, [
            _createVNode(_component_Icon, { icon: "caret-down-small" })
          ], 8, _hoisted_5)
        ]))
      : _createCommentVNode("", true)
  ]))
}