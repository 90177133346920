
import API from '@/api/wrapper';
import Badge from '@/components/UIElements/Badge.vue';
import Icon from '@/components/UIElements/Icon.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import Indicator from '@/components/UIElements/Indicator.vue';
import ClusterDisplay from '@/components/UIElements/ClusterDisplay.vue';
import ModalRenameRessource from '@/components/Modals/ModalRenameRessource.vue';
import ModalClusterConfig from '@/components/Modals/ModalClusterConfig.vue';
import ModalClusterPutOffline from '@/components/Modals/ModalClusterPutOffline.vue';

import { APICluster, APICustomer, APIDeployment, APIProject } from '@/typesAPI';
import { Vue, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import Logo from '@/components/UIElements/Logo.vue';
import { ListItem } from '@/components/UIElements/List.vue';

@Options({
  components: {
    CustomButton,
    DropdownMenu,
    Badge,
    Icon,
    Indicator,
    ModalRenameRessource,
    Logo,
    ModalClusterConfig,
    ModalClusterPutOffline,
    ClusterDisplay
  },
})
export default class ClustersView extends Vue {

  loadingProjects = false
  projects:{[key:string]:APIProject[]} = {}
  actionTarget:APICluster | null = null
  creating = false
  showModalRename = false
  showModalPutOffline = false 

  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }

  get projectsStatus():{[key:string]:string} {
    return this.$store.getters['projects/getProjectsStatus']
  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  @Watch('clusters', {immediate: true})
  onClustersChange() {
    if(this.clusters?.length && !this.projects?.length && !this.loadingProjects) {
      this.loadProjects()
    }
  }

  loadProjects() {
    this.loadingProjects = true
    this.clusters.forEach((cluster) => {
      API.clusters.getProjectsByCluster(cluster.id)
      .then((projects:APIProject[]) => {
        this.projects[cluster.id] = projects
      })
      .finally(() => {
        this.loadingProjects = false
      })
    })
   
  }

  get clusterDeployments():APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  // Key: clusterId
  // Value: status
  get clustersStatus():{[key:string]:string} {
    const ret:{[key:string]:string} = {}

    this.clusters.forEach((cluster) => {
      const myDeployment = this.clusterDeployments?.find((deployment) => {
        return deployment.id === cluster?.relationships?.deployment?.data?.id
      })
      ret[cluster.id] = myDeployment ? myDeployment.attributes.status : this.$enums.DeploymentStatus.UNKNOWN
    })

    return ret
  }

  createCluster() {
    this.actionTarget = null
    this.creating = true
    this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
  }

  getProjectStateById(id:string) {
    return this.projectsStatus[id] ? this.$helpers.states.translateDeploymentStatusToState(this.projectsStatus[id]) : this.$enums.BadgeStates.Neutral
  }
  
  onBurgerMenuItemClick(item:ListItem, cluster:APICluster) {
    this.actionTarget = cluster
    this.creating = false
    item.selected = false

    if(item.id === 'rename') {
      this.showModalRename = true
    } else if(item.id === 'edit') {
      this.$store.dispatch('modals/setVisibility', {modal:this.$enums.ModalsName.Cluster, newState: true})
    } else if(item.id === 'offline') {
      this.showModalPutOffline = true
    }
  }
}
