
import { Vue, prop, Options } from 'vue-class-component';
import ListElement from '@/components/UIElements/ListElement.vue'
import Icon from '@/components/UIElements/Icon.vue'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import { Watch } from 'vue-property-decorator';

export interface ListItem {
  text?:string;
  subtext?:string;
  label?:string;
  showArrow?:boolean;
  selected?:boolean;
  id?:string;
  icon?:string;
  logo?:string;
  value?:string;
  iconSubtext?:string;
  disabled?:boolean;
  separator?:boolean;
  class?:string;
  meta?:any;
}

class Props {
  items: ListItem[] = prop({
    required: true,
  });
  title?: string = prop({
    required: false,
  });
  deployable?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
}
@Options({
  components: {
    ListElement,
    Icon,
    HeightDeployer
  },
})
export default class List extends Vue.with(Props) {
  deployed = this.deployable ? false : true
  internalItems = JSON.parse(JSON.stringify(this.items))

  selectItem(item:ListItem) {
    if(!this.disabled && !item.disabled) {
      this.internalItems.forEach((i:ListItem) => i.selected = false)
      item.selected = true
      this.$emit('select', item)
    }
  }

  deploy() {
    if(this.deployable) {
      if(this.deployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.deployed = false
  }

  expand() {
    this.deployed = true
  }

  @Watch('items', {deep:true})
  onItemsChange () {
    this.internalItems = JSON.parse(JSON.stringify(this.items))
  }
}



