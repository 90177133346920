import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-80b60726"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panels-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PanelTop = _resolveComponent("PanelTop")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_PanelLeft = _resolveComponent("PanelLeft")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PanelRight = _resolveComponent("PanelRight")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0 pa-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_PanelTop)
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "panel-side pa-0 pl-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_PanelLeft)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "panel-side pa-0 pr-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_PanelRight)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}