import API from "@/api/wrapper"
import { APIUrlRedirection } from "@/typesAPI"
import enums from "@/enums"
import store from "@/store"

// Console
interface Console {
    log:any,
    logLevel: number
}

// Ressources
interface metaGenerated {
    id:string,
    meta : {
        generated_at:string,
        generated_by:string
    }
}

const helpers = {
    static: {
        stripeAppearance : {
            theme: 'stripe',
            variables: {
                fontFamily: "Inter, sans-serif",
                fontWeightNormal: 400,
                fontSizeBase: "14px",
                gridRowSpacing: "0"
            },
            rules: {
                ".Tab": {
                    marginBottom : "16px",
                    boxShadow: "0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);",
                    border: "1px solid rgba(0, 0, 58, 0.12)",
                },
                ".Tab--selected": {
                    border: "2px solid rgba(50, 25, 178, 1)",
                    boxShadow: "0px 0px 0px 2px rgba(50, 25, 178, 0.24)"
                },
                ".Tab--selected:focus": {
                    border: "2px solid rgba(50, 25, 178, 1)",
                    boxShadow: "0px 0px 0px 2px rgba(50, 25, 178, 0.24)"
                },
                ".TabLabel--selected": {
                    color: "rgba(50, 25, 178, 1)"
                },
                ".Label": {
                    marginBottom : "0px",
                    fontFamily: "Inter, sans-serif",
                    lineHeight: "24px",
                    fontSize: "14px"
                },
                ".Input": {
                    marginTop : "8px",
                    marginBottom : "16px",
                    boxShadow: "0px 1px 4px rgba(0, 0, 58, 0.08), 0px 1px 1px -1px rgba(0, 0, 58, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.08);",
                    borderRadius: "8px",
                    color:"#00003A",
                    outline: "none",
                    padding: "12px",
                    border: "1px solid rgba(0, 0, 58, 0.12)",
                    transition: "box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out"
                },
                ".Input:hover": {
                    // border: "1px solid rgba(0, 0, 58, 0.30)"
                },
                ".Input:focus": {
                    paddingLeft: "11px",
                    marginTop: "7px",
                    marginBottom: "15px",
                    border: "2px solid rgba(50, 25, 178, 1)",
                    boxShadow: "0px 0px 0px 2px rgba(50, 25, 178, 0.24)"
                },
            }
        },
    },
    text: {
        capitalize(txt:string) {
            return txt.charAt(0).toUpperCase() + txt.slice(1)
        }
    },
    providers: {
        providerToLabel(provider?:string) {
            let ret = ""
            switch(provider) {
                case "hetzner":
                    ret = "Hetzner"
                    break;
                case "aws":
                    ret = "AWS"
                    break;
            }
            return ret;
        },
        regionToLabel(region?:string) {
            let ret = ""
            switch(region) {
                case "nbg1":
                    ret = "Germany (Nuremberg)"
                    break;
                case "fsn1":
                    ret = "Germany (Falkenstein)"
                    break;
                case "hel1":
                    ret = "Finland (Helsinki)"
                    break;
                case "ash":
                    ret = "USA (Ashburn)"
                    break;
                case "hil":
                    ret = "USA (Hillsboro)"
                    break;
            }
            return ret;
        },
        regionToDigram(region?:string) {
            let ret = ""
            switch(region) {
                case "nbg1":
                    ret = "DE"
                    break;
                case "fsn1":
                    ret = "DE"
                    break;
                case "hel1":
                    ret = "FI"
                    break;
                case "ash":
                    ret = "US"
                    break;
                case "hil":
                    ret = "US"
                    break;
            }
            return ret;
        }
    },
    prices: {
        format(price:number, currency?:string, precision?:number):string {
            const defaultPrecision = 2
            const choosenPrecision = precision ? precision : defaultPrecision
            const multiplicator = Math.pow(10,choosenPrecision)
            const formatedPrice = (Math.round((price / 100) * multiplicator ) / multiplicator).toFixed(choosenPrecision)
            //@ts-ignore
            return formatedPrice + (currency ? ' ' + enums.CurrencyTrigramToSymbol[currency] : '')
        }
    },
    dates: {
        formatTime(time:string):string {
            const date = new Date(time)
            return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())
        },
        formatTimestamp(time:string):string {
            const date = new Date(parseInt(time) * 1000)
            return (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':' + (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':' + (date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds())
        },
    },
    states: {
        translateHealthCheckStatusToIcon(status?:string) {
            let ret = "spinner"
            switch(status) {
              case enums.HealthCheckStatus.OK :
                ret = 'check-circle'
                break;
              case enums.HealthCheckStatus.PENDING :
                ret = 'warning'
                break;
              case enums.HealthCheckStatus.FAILING :
                ret = 'stopsign-alert'
                break;
              default:
                break;
            }
            return ret
          },
          translateDeploymentStatusToLabel(status?:string):string {
            let ret = "Offline"
        
            switch(status) {
              case enums.DeploymentStatus.UNKNOWN :
                ret = "Unknown"
                break;
              case enums.DeploymentStatus.HEALTHY :
                ret = "Healthy"
                break;
              case enums.DeploymentStatus.DEGRADED :
                ret = "Degraded"
                break;
              case enums.DeploymentStatus.CRITICAL :
                ret = "Critical"
                break;
              case enums.DeploymentStatus.OFFLINE :
                ret = "Offline"
                break;
              default:
                break;
            }
        
            return ret
          },
          translateDeploymentStatusToState(status?:string):string {
            let ret:string = enums.BadgeStates.Neutral
        
            switch(status) {
              case enums.DeploymentStatus.HEALTHY :
                ret = enums.BadgeStates.Success
                break;
              case enums.DeploymentStatus.DEGRADED :
                ret = enums.BadgeStates.Warning
                break;
              case enums.DeploymentStatus.CRITICAL :
                ret = enums.BadgeStates.Danger
                break;
              default:
                break;
            }
        
            return ret
          },
          translateDeploymentStatusToIcon(status?:string):string {
            let ret = 'cloud-off'
        
            switch(status) {
              case enums.DeploymentStatus.UNKNOWN :
                ret = 'clock'
                break;
              case enums.DeploymentStatus.OFFLINE :
                ret = "cloud-off"
                break;
              case enums.DeploymentStatus.HEALTHY :
                ret = 'check-circle'
                break;
              case enums.DeploymentStatus.DEGRADED :
                ret = 'warning'
                break;
              case enums.DeploymentStatus.CRITICAL :
                ret = 'stopsign-alert'
                break;
              default:
                break;
            }
        
            return ret
          },
          translateInvoiceStatusToState(status?:string):string {
            let ret:string = enums.BadgeStates.Neutral
        
            switch(status) {
              case 'paid' :
                ret = enums.BadgeStates.Success
                break;
              case 'open' :
                ret = enums.BadgeStates.Warning
                break;
              case 'uncollectible' :
                ret = enums.BadgeStates.Danger
                break;
            case 'void' :
                ret = enums.BadgeStates.Neutral
                break;
              default:
                break;
            }
        
            return ret
          },
          translateInvoiceStatusToLabel(status?:string):string {
            let ret = 'Draft'
            switch(status) {
              case 'paid' :
                ret = 'Paid'
                break;
              case 'open' :
                ret = 'Open'
                break;
              case 'uncollectible' :
                ret = 'Uncollectible'
                break;
            case 'void' :
                ret = 'Canceled'
                break;
              default:
                break;
            }
        
            return ret
          }
    },
    ressources: {
        isNewerThan(r1: metaGenerated, r2: metaGenerated):boolean {
 
            // For handeling ms in the format send by the API
            const troncR1a = r1.meta.generated_at.split(':')
            const troncR1b = troncR1a.splice(troncR1a.length - 2 , 2)

            const troncR2a = r2.meta.generated_at.split(':')
            const troncR2b = troncR2a.splice(troncR2a.length - 2 , 2)

            if(troncR1a.join(':') === troncR2a.join(':')) {
                return parseInt(troncR1b.join(':')) > parseInt(troncR2b.join(':'))
            }
            return new Date(troncR1a.join(':')) > new Date(troncR2a.join(':'))
        },
        isFromMe(g: metaGenerated):boolean {
            return g.meta.generated_by === store.getters['user/getUser']?.id
        },
        addMultipleRessourceToArray(arr:any[], newRessource:any[], editOnly?:boolean) {
            newRessource.forEach((ressource:any) => {
                const index = arr.findIndex((old:any) => old.id === ressource.id)
                if(index !== -1 ) {
                    if(helpers.ressources.isNewerThan(ressource, arr[index])) {
                        arr[index] = ressource
                    }
                } else {
                    if(!editOnly) {
                        arr.push(ressource)
                    }
                }
            })
        }
    },
    contact: {
        contactUs () {
            window.open("mailto:support@fransys.cloud", "_blank");
        }
    },
    links: {
        newTab (url:string | undefined) {
            if(url) {
                window.open(url, "_blank");
            }
        },
        redirect (url:string | undefined) {
            if(url) {
                window.location.href = url
            }
        }
    },
    subscription: {
        checkout () {
            return API.subscription.checkout()
            .then((urlRedirection:APIUrlRedirection) => {
                window.location.href = urlRedirection.attributes.url
            })
            .catch()
        }
    },
    images: {
        dynamicLoad(path:string):string | undefined {
            try {
                return require(path) + ""
            } catch {
                return undefined
            }
        }
    },
    clipboard: {
        copy (text:string) {
            const dummy = document.createElement("textarea");
            document.body.appendChild(dummy);
            dummy.value = text;
            dummy.select();
            document.execCommand("copy");
            document.body.removeChild(dummy);
        }
    },
    components: {
        getNameFromAttributesType(type:string) {
            const splitedType = type.split('-')
            const capitalized = splitedType.map((str:string) => {
                return str.charAt(0).toUpperCase() + str.slice(1)
            })
            return 'DetailField' + capitalized.join('')
        }
    },    
    console: {
        logLevel: 0,
        log(...messages:any) {
            if(this.logLevel === 0) {
                console.log(...messages)
            }
        },
        warn(...messages:any) {
            if(this.logLevel === 0) {
                console.warn(...messages)
            }
        },
        error(...messages:any) {
            if(this.logLevel === 0) {
                console.error(...messages)
            }
        }
    }
}


export default helpers;