
import { Vue, Options, prop } from 'vue-class-component';
import Divider from '@/components/UIElements/Divider.vue';

class Props {
  headers?: any[] = prop({
    required: false,
  });
  values: any[][] = prop({
    required: true,
  });
  colClasses?: string[][] = prop({
    required: false,
  });
}

@Options({
  components: {
    Divider,
  },
})
export default class Table extends Vue.with(Props) {
 
}
