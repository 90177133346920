
import { Vue, Options, prop } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Divider from '@/components/UIElements/Divider.vue'
import CustomSelect from '@/components/UIElements/CustomSelect.vue';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import { ListItem } from '@/components/UIElements/List.vue';

class Props {
  usageAlert: any = prop({
    required: true,
  });

}

@Options({
  components: {
    HeightDeployer,
    Icon,
    Divider,
    CustomSelect,
    CustomButton
  },
})
export default class UsageAlert extends Vue.with(Props) {

  deployed = false

  itemsVal:ListItem[] = [
    {
      text:'35',
      value: '35'
    },
    {
      text:'50',
      value: '50'
    },
    {
      text:'80',
      value: '80'
    },
    {
      text:'100',
      value: '100'
    },
  ]
  selectedVal = this.itemsVal[0]

  itemsLimit:ListItem[]  = [
    {
      text:'burn rate limit',
      value: 'brl'
    },
    {
      text:'monthly spending hard limit',
      value: 'hl'
    },
  ]

  selectedLimit = this.itemsLimit[0]

  get disabled():boolean {
    return this.usageAlert?.readonly
  }

  mounted(): void {
    this.initValues()
  }

  initValues () {
    const foundLimit = this.itemsLimit.find((limit) => limit.value === this.usageAlert.limit)
    if(foundLimit) {
      this.selectedLimit = foundLimit
    }
    const foundVal = this.itemsVal.find((val) => val.value === this.usageAlert.value.toString())
    if(foundVal) {
      this.selectedVal = foundVal
    }
  }


  onSelectedValUpdate(newVal:any) {
    this.selectedVal = newVal
  }

  saveAlert () {
    console.log('SaveAlert', this.usageAlert)
  }
  
  deleteAlert () {
    console.log('delete', this.usageAlert)
  }

  deploy() {
    if(!this.disabled) {
      if(this.deployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.initValues()
    this.deployed = false
  }

  expand() {
    this.deployed = true
  }


}
