
import { Vue, Options, prop } from 'vue-class-component'
import Icon from '@/components/UIElements/Icon.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import { Watch } from 'vue-property-decorator'
import { nextTick } from 'vue'

enum POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
  LEFT = 'left',
  RIGHT = 'right',
}

class Props {
  visible: boolean = prop({
    required: true,
  });
  target: HTMLElement = prop({
    required: true,
  });
  transparent?: boolean = prop({
    required: false,
  });
  closeOnExternalClick?: boolean = prop({
    required: false,
  });
  positionerOnly?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    CustomButton,
    CustomTextInput,
    Spinner
  },
})
export default class Popover extends Vue.with(Props) {
  loading = false
  POSITION = POSITION
  top = 0;
  left = 0;
  width = 464;
  arrowTop = 0;
  arrowLeft = 0;
  arrowWidth = 21;
  arrowHeight = 9;
  isPlaced = false
  position = POSITION.BOTTOM
  


  @Watch('visible', {immediate:true})
  onShowChange(){
    if(this.visible) {
      this.isPlaced = false
      nextTick(() => {
        this.placePopover()
      })
    }
   
  }

  onExternalClick() {
    this.$emit('clickExternal')
    if(this.closeOnExternalClick) {
      this.$emit('update:visible', false)
    }
  }

  placePopover () {

      // @ts-ignore
      const targetRect:DOMRect  = this.target.$el ? this.target.$el.getBoundingClientRect() as DOMRect  : this.target?.getBoundingClientRect() as DOMRect 
      let popoverRect:DOMRect = new DOMRect()

      if(this.$refs.popoverDOM) {
        popoverRect = (this.$refs.popoverDOM as HTMLElement).getBoundingClientRect()
      }
      switch(this.position) {
        case POSITION.BOTTOM :
          // Put on Buttom 
          this.top = targetRect.top + targetRect.height
          this.left = targetRect.left + targetRect.width/2 - this.width/2
          this.arrowLeft = (this.width/2) - this.arrowWidth/2
          this.arrowTop = 0-this.arrowHeight
        break;
        case POSITION.TOP :
          // Put on Top 
          this.top = targetRect.top - (popoverRect.height) - this.arrowHeight
          this.left = targetRect.left + targetRect.width/2 - (this.width/2)
          this.arrowLeft = (this.width/2) - this.arrowWidth/2
          this.arrowTop = popoverRect.height - 2
        break;
        case POSITION.LEFT :
          // Put on Left 
          this.top = targetRect.top + targetRect.height/2 - (popoverRect.height/2)
          this.left = targetRect.left - this.width
          this.arrowLeft = this.width - 2
          this.arrowTop = (popoverRect.height/2) - this.arrowHeight
        break;
        case POSITION.RIGHT :
          // Put on Right 
          this.top = targetRect.top + targetRect.height/2 - (popoverRect.height/2)
          this.left = targetRect.left + targetRect.width
          this.arrowLeft = 0-this.arrowWidth
          this.arrowTop = (popoverRect.height/2) - this.arrowHeight
        break;
        default:
          // Put on Buttom 
          this.top = targetRect.top + targetRect.height
          this.left = targetRect.left + targetRect.width/2 - this.width/2
          this.arrowLeft = (this.width/2) - this.arrowWidth/2
          this.arrowTop = 0-this.arrowHeight
        break;
      }
      this.isPlaced = true
    
  }
}
