import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PaymentMethodDisplay = _resolveComponent("PaymentMethodDisplay")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_radio_group, {
        modelValue: _ctx.selectedPaymentMethod,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedPaymentMethod) = $event)),
        "hide-details": ""
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.paymentMethods, (paymentMethod, index) => {
            return (_openBlock(), _createBlock(_component_v_row, {
              class: "ma-0 mt-2 mb-4",
              key: paymentMethod.id
            }, {
              default: _withCtx(() => [
                (_ctx.selectable)
                  ? (_openBlock(), _createBlock(_component_v_col, {
                      key: 0,
                      class: "pa-0 fit-content d-flex mr-2"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_radio, {
                          value: paymentMethod,
                          class: "my-auto",
                          color: "rgba(50, 25, 178, 1)"
                        }, null, 8, ["value"])
                      ]),
                      _: 2
                    }, 1024))
                  : _createCommentVNode("", true),
                _createVNode(_component_PaymentMethodDisplay, {
                  paymentMethod: paymentMethod,
                  withBurger: !_ctx.selectable,
                  isDefault: index === 0
                }, null, 8, ["paymentMethod", "withBurger", "isDefault"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}