import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomButton = _resolveComponent("CustomButton")!

  return (_openBlock(), _createBlock(_component_CustomButton, {
    level: _ctx.$enums.ButtonLevels.Primary,
    onClick: _ctx.tryDeploy,
    icon: "publish",
    text: _ctx.text,
    disabled: _ctx.tryToDeploy || _ctx.deploying || _ctx.deployed || !_ctx.hasDraft || _ctx.forceDisable,
    class: "btn-deploy",
    loading: _ctx.deploying && !_ctx.needToShowSubscriptionModal,
    status: _ctx.status
  }, null, 8, ["level", "onClick", "text", "disabled", "loading", "status"]))
}