import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "grid-snap-hightlight",
  ref: "gridSnapHightlightRef"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockNode = _resolveComponent("BlockNode")!
  const _component_PlatformNode = _resolveComponent("PlatformNode")!
  const _component_InvisibleNode = _resolveComponent("InvisibleNode")!
  const _component_CustomEdge = _resolveComponent("CustomEdge")!
  const _component_Background = _resolveComponent("Background")!
  const _component_TrashBar = _resolveComponent("TrashBar")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_VueFlow = _resolveComponent("VueFlow")!

  return (_openBlock(), _createBlock(_component_VueFlow, {
    modelValue: _ctx.$store._state.data.elements.list,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$store._state.data.elements.list) = $event)),
    "select-nodes-on-drag": false,
    "delete-key-code": null,
    "default-zoom": 1,
    "min-zoom": 1,
    "max-zoom": 1,
    "default-position": [0,0],
    "pan-on-drag": true,
    onConnect: _ctx.onConnect,
    onNodeDragStart: _ctx.onNodeDragStart,
    onNodeDragStop: _ctx.onNodeDragStop,
    onNodeDrag: _ctx.onNodeDrag,
    onConnectEnd: _ctx.onConnectEnd,
    onConnectStart: _ctx.onConnectStart,
    onDrop: _ctx.onDrop,
    onPaneClick: _ctx.onPaneClick,
    onDragover: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"])),
    onDragenter: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"])),
    ref: "vueFlowRef",
    "connection-line-style": {stroke: 'rgba(50, 25, 178, 1)', 'stroke-width' : '2px'}
  }, {
    "node-block": _withCtx((props) => [
      _createVNode(_component_BlockNode, { props: props }, null, 8, ["props"])
    ]),
    "node-platform": _withCtx((props) => [
      _createVNode(_component_PlatformNode, { props: props }, null, 8, ["props"])
    ]),
    "node-invisible": _withCtx((props) => [
      _createVNode(_component_InvisibleNode, { props: props }, null, 8, ["props"])
    ]),
    "edge-link": _withCtx((props) => [
      _createVNode(_component_CustomEdge, { props: props }, null, 8, ["props"])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_Background, {
        variant: _ctx.BackgroundVariant.Dots,
        gap: _ctx.gridSpacing
      }, null, 8, ["variant", "gap"]),
      _createVNode(_component_Panel, {
        position: _ctx.PanelPosition.BottomRight
      }, {
        default: _withCtx(() => [
          _createVNode(_component_TrashBar, {
            ref: "trashRef",
            isHovered: (_ctx.isTrashHovered || !!_ctx.isABlockDisappearing)
          }, null, 8, ["isHovered"])
        ]),
        _: 1
      }, 8, ["position"]),
      _createElementVNode("div", _hoisted_1, null, 512)
    ]),
    _: 1
  }, 8, ["modelValue", "onConnect", "onNodeDragStart", "onNodeDragStop", "onNodeDrag", "onConnectEnd", "onConnectStart", "onDrop", "onPaneClick", "connection-line-style"]))
}