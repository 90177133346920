export default Object.freeze({
    "american_express" : "American Express",
    "cartes_bancaires" : "CB",
    "diners_club" : "Diners Club",
    "discover" : "Discover",
    "eftpos_australia" : "Eftpos Australia",
    "interac" : "Interac",
    "jcb" : "JCB",
    "mastercard" : "MasterCard",
    "union_pay" : "UnionPay",
    "visa" : "Visa",
    "other" : "Other",
})
