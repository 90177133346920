
import { Options, Vue } from 'vue-class-component';
import BlockBar from '@/components/Blocks/BlockBarMenu.vue';
import EnvironmentsBar from '@/components/Environments/EnvironmentsBar.vue';
import ProjectBar from '@/components/Projects/ProjectBar.vue';
import LogoBar from '@/components/Projects/LogoBar.vue';

@Options({
  components: {
    BlockBar,
    EnvironmentsBar,
    ProjectBar,
    LogoBar
  },
})
export default class PanelLeft extends Vue {
 
}
