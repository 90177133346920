import { APISelectOption } from "@/typesAPI"

type State = {
  emptyValues: {[key:string]:APISelectOption[]}
  pullingStatus: {[key:string]:boolean}
}

const state: State = {
  emptyValues: {},
  pullingStatus: {}
}


const getters = {
  getEmptyValues: (state:State) => {
    return state.emptyValues
  },
  getPullingStatus: (state:State) => {
    return state.pullingStatus
  },
}

const mutations = {
  SET_EMPTY_VALUES: (state:State, payload:{id:string, values:APISelectOption[]}) => {
    state.emptyValues[payload.id] = payload.values
  },
  SET_PULLING_STATUS: (state:State, payload:{id:string, value:boolean}) => {
    state.pullingStatus[payload.id] = payload.value
  },
}

const actions = {
  setEmptyValues: (context:any, payload:{id:string, values:APISelectOption[]}) => {
    context.commit('SET_EMPTY_VALUES', payload)
  },
  setPullingStatus: (context:any, payload:{id:string, value:boolean}) => {
    context.commit('SET_PULLING_STATUS', payload)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}