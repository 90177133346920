import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["spinner-container", {fullScreen: _ctx.fullScreen, dark: _ctx.dark}])
  }, [
    (_ctx.fullScreen)
      ? (_openBlock(), _createBlock(_component_Icon, {
          key: 0,
          icon: "logo",
          class: "spinner-logo"
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_Icon, {
      icon: "spinner",
      class: "spinner-icon"
    })
  ], 2))
}