import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_BlockCategory = _resolveComponent("BlockCategory")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { class: "block-bar bar pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "block-title pa-0 pt-2 pl-4 ma-0 pb-2" }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listBlocks, (block) => {
        return (_openBlock(), _createBlock(_component_v_row, {
          class: "ma-0 pb-2 pr-2",
          key: 'block-'+block.id
        }, {
          default: _withCtx(() => [
            _createVNode(_component_BlockCategory, {
              block: block,
              type: "block",
              positionXY: _ctx.positionXY,
              linkSrc: _ctx.linkSrc,
              onAddNode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNode')))
            }, null, 8, ["block", "positionXY", "linkSrc"])
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }))
}