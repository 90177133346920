import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  tabindex: "-1",
  type: "image/svg+xml",
  data: "/animations/trash-opening.svg",
  class: "trash-img"
}
const _hoisted_2 = {
  key: 1,
  tabindex: "-1",
  type: "image/svg+xml",
  data: "/animations/trash-closing.svg",
  class: "trash-img"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["trash-bar bar d-flex", {deployed: _ctx.isDraggingNode, hovered: _ctx.isHovered}])
  }, [
    (_ctx.trashOpen)
      ? (_openBlock(), _createElementBlock("object", _hoisted_1, " Trash Open "))
      : (_openBlock(), _createElementBlock("object", _hoisted_2, " Trash Closed "))
  ], 2))
}