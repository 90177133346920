
import { Vue, prop, Options } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'
import { ListItem } from '@/components/UIElements/List.vue';

import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { Watch } from 'vue-property-decorator';

export class Props {
  placeholder?: string = prop({
    required: false,
  });
  items?: ListItem[] = prop({
    required: false,
  });
  query?: string = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  modelValue?: ListItem = prop({
    required: false,
  });
  displaySearch?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon,
    CustomTextInput,
    DropdownMenu
  },
})
export default class CustomSelect extends Vue.with(Props) {
  opened = false;
  dropdownMenuWidth = '150'
  dropdownMenuTop = '0'
  onModelValueUpdate(newValue:ListItem) {
    this.opened = false
    this.$emit('update:modelValue', newValue)
  }

  mounted(): void {
    // TODO: Find a way to remove the timeout and base on the update of the HTML
    setTimeout(() => {
     this.setWidth()
     this.setTop()
    },100)
  }

  onOpenClick(): void {
    if(!this.disabled) {
      this.opened = true
    }
  }

  setWidth() {
    const DOMelem = this.$refs.customSelect as HTMLElement
    if(DOMelem) {
      this.dropdownMenuWidth = DOMelem.getBoundingClientRect().width.toString()
    }
  }
  setTop() {
    const DOMelem = this.$refs.customSelect as HTMLElement
    if(DOMelem) {
      const offsetParent = DOMelem.offsetParent as HTMLElement
      this.dropdownMenuTop = (DOMelem.getBoundingClientRect().top - offsetParent.getBoundingClientRect().top).toString()
    }
  }

  @Watch('items', {immediate: true})
  onItemsChange() {
    this.setWidth()
  }

  @Watch('opened', {immediate: true})
  onOpenChange() {
    this.setWidth()
    this.setTop()
  }

}



