import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createSlots as _createSlots, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e1180ace"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "link-detail-container"
}
const _hoisted_2 = { class: "btn-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_ctx.link)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_Popover, {
          visible: _ctx.isOpened,
          target: _ctx.target,
          transparent: _ctx.isModalTransparent,
          onClickExternal: _ctx.onClose
        }, _createSlots({
          header: _withCtx(() => [
            (_ctx.variablesOverrides.length)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  class: "pa-0"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" Connection variable ")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  class: "ml-auto",
                  level: _ctx.$enums.ButtonLevels.SmallIcon,
                  icon: "x",
                  onClick: _ctx.onClose
                }, null, 8, ["level", "onClick"])
              ]),
              _: 1
            })
          ]),
          footer: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_CustomButton, {
                level: _ctx.$enums.ButtonLevels.Tertiary,
                icon: "trash",
                text: "Delete link",
                class: "delete-btn footer-btn",
                onClick: _ctx.deleteLink
              }, null, 8, ["level", "onClick"])
            ])
          ]),
          _: 2
        }, [
          (_ctx.variablesOverrides.length)
            ? {
                name: "content",
                fn: _withCtx(() => [
                  _createVNode(_component_v_col, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "link-detail-variable" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Original variable name ")
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variablesOverrides, (variableOverride) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          class: "link-detail-variable",
                          key: variableOverride.id+'-original_name'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CustomTextInput, {
                              modelValue: variableOverride.attributes.original_name,
                              "onUpdate:modelValue": ($event: any) => ((variableOverride.attributes.original_name) = $event),
                              disabled: true,
                              disabledBox: true
                            }, null, 8, ["modelValue", "onUpdate:modelValue"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_col, { class: "pa-0 pl-4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { class: "link-detail-variable" }, {
                        default: _withCtx(() => [
                          _createTextVNode(" Override variable name ")
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.variablesOverrides, (variableOverride) => {
                        return (_openBlock(), _createBlock(_component_v_row, {
                          class: "link-detail-variable",
                          key: variableOverride.id+'-name'
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_CustomTextInput, {
                              modelValue: variableOverride.attributes.name,
                              "onUpdate:modelValue": ($event: any) => ((variableOverride.attributes.name) = $event),
                              onBlur: ($event: any) => (_ctx.editOverride($event, variableOverride.id)),
                              error: _ctx.isInError(variableOverride.id)
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onBlur", "error"])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["visible", "target", "transparent", "onClickExternal"])
      ]))
    : _createCommentVNode("", true)
}