
import { Vue, Options, prop } from 'vue-class-component'
import Modal from '@/components/UIElements/Modal.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import PaymentMethodsDisplay from '@/components/UIElements/PaymentMethodsDisplay.vue'
import { APICustomer, APIPaymentMethod } from '@/typesAPI';
import { Watch } from 'vue-property-decorator'
import StripeWidgetPaymentMethods from '@/components/UIElements/Widget/StripeWidgetPaymentMethods.vue'
import WidgetBillingDetails from '@/components/UIElements/Widget/WidgetBillingDetails.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue'

class Props {
  visible: boolean = prop({
    required: true,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Modal,
    CustomButton,
    PaymentMethodsDisplay,
    StripeWidgetPaymentMethods,
    WidgetBillingDetails,
    FransysSignature,
  },
})
export default class ModalValidatePaymentMethodList extends Vue.with(Props) {
    loadingModalCTA = false
    paymentMethodSelected:APIPaymentMethod | null = null
    showAddOtherPaymentMethod = false
    showEditBillingDetails = false

    get customer():APICustomer {
      return this.$store.getters['user/getCustomer']
    }

    get paymentMethods():APIPaymentMethod[] {
      return this.$store.getters['billing/getPaymentMethods']
    }

    onAddPaymentMethod() {
      this.showAddOtherPaymentMethod = false
    }

    onEditBillingDetails() {
      this.showEditBillingDetails = false
    }

    @Watch('visible', {immediate: true})
    onVisibleChange() {
      if(this.visible) {
        this.loadingModalCTA = false
        this.showAddOtherPaymentMethod = false
        this.showEditBillingDetails = false
        
        if(!this.customer?.attributes?.country) {
          this.showEditBillingDetails = true
        }
      }
    }

    onValidate() {
      this.loadingModalCTA = true
      this.$emit('success')
    }

    onClickAddOtherPaymentMethod() {
      this.showAddOtherPaymentMethod = true
    }

    closeModal() {
      this.$emit('update:visible',false)
      this.loadingModalCTA = false
      this.showAddOtherPaymentMethod = false
      this.showEditBillingDetails = false
    }

}
