import { APIPriceRanges } from '@/typesAPI'

type State = {
  monthlyPrice: APIPriceRanges | undefined,
}

const state: State = {
  monthlyPrice: undefined,
}


const getters = {
  getMonthlyPrice: (state:State) => {
    return state.monthlyPrice
  },

}

const mutations = {
  SET_MONTHLY_PRICE: (state:State, price:APIPriceRanges) => {
    state.monthlyPrice = price
  },

}

const actions = {
  setMonthlyPrice: (context:any,price:APIPriceRanges) => {
    context.commit('SET_MONTHLY_PRICE', price)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}