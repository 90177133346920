
import CreateMagicLinkBar from '@/components/Actions/CreateMagicLinkBar.vue';
import BackgroundGradiant from '@/components/UIElements/BackgroundGradiant.vue';
import FransysSignature from '@/components/UIElements/FransysSignature.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CreateMagicLinkBar,
    BackgroundGradiant,
    FransysSignature
  },
})
export default class RegisterView extends Vue {}
