import { APIBlock, APIDeployment } from "@/typesAPI"
import helpers from '@/utils/helpers'
import enums from "@/enums"

type State = {
  list: APIBlock[],
  deploymentList:APIDeployment[]
}

const state: State = {
  list : [],
  deploymentList: []
}


const getters = {   
  getList: (state:State) => {
    return state.list
  },
  getByID: (state:State) => {
    return (id:string) => {
      return state.list.find(blockAPI => blockAPI.id === id)
    }
  },
  getDeployements: (state:State) => {
    return state.deploymentList
  },
  getDeployementStatusByBlockID: (state:State) => {
    return (id:string) => {
      const block = state.list.find(blockAPI => blockAPI.id === id)
      if(block) {
        const deployment = state.deploymentList.find(dep => dep?.relationships?.subject?.data?.id === block.id )
        if(deployment) {
          return deployment.attributes.status
        }
      }
      return enums.DeploymentStatus.OFFLINE
    }
  },
  getByIDCategory: (state:State) => {
    return (id:string) => {
      return state.list.find(blockAPI => blockAPI?.relationships?.category?.data.id === id)
    }
  },      
}

const mutations = {
  EDIT_BLOCKAPI: (state:State, blockAPI:APIBlock) => {
    helpers.ressources.addMultipleRessourceToArray(state.list, [blockAPI])
  },
  SET_LIST: (state:State, blocksAPI:APIBlock[]) => {
    state.list = blocksAPI
  },
  SET_DEPLOYMENT_LIST: (state:State, deployments:APIDeployment[]) => {
    state.deploymentList = deployments
  },
  ADD_DEPLOYMENTS: (state:State, deployments:APIDeployment[]) => {
    helpers.ressources.addMultipleRessourceToArray(state.deploymentList, deployments)
  },
  DELETE_DEPLOYMENT: (state:State, deployment:APIDeployment) => {
    const index = state.deploymentList.findIndex((d:APIDeployment) => d.id === deployment.id)
    if(index >= 0) {
      state.deploymentList.splice(index,1)
    }  
  },
}

const actions = {
  addNode: (context:any, blockAPI:APIBlock) => {
    context.commit('EDIT_BLOCKAPI', blockAPI)
  },
  editBlock: (context:any, blockAPI:APIBlock) => {
    context.commit('EDIT_BLOCKAPI', blockAPI)
  },
  setBlockList: (context:any, blocksAPI:APIBlock[]) => {
    context.commit('SET_LIST', blocksAPI)
  },
  addDeployments: (context:any, deployments:APIDeployment[]) => {
    context.commit('ADD_DEPLOYMENTS', deployments)
  },
  deleteDeployment: (context:any, deployment:APIDeployment) => {
    context.commit('DELETE_DEPLOYMENT', deployment)
  },
  setDeploymentList: (context:any, deployments:APIDeployment[]) => {
    context.commit('SET_DEPLOYMENT_LIST', deployments)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}