import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mb-2 ml-3 token-text-color-secondary token-text-microbold token-text-transform-uppercase token-text-font-iAWriterDuoV" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlockItem = _resolveComponent("BlockItem")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.block.name), 1),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.computedBlocks, (computedBlock) => {
      return (_openBlock(), _createBlock(_component_BlockItem, {
        block: computedBlock,
        type: _ctx.type,
        positionXY: _ctx.positionXY,
        linkSrc: _ctx.linkSrc,
        key: computedBlock.id,
        onAddNode: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('addNode'))),
        class: "mb-2"
      }, null, 8, ["block", "type", "positionXY", "linkSrc"]))
    }), 128))
  ], 64))
}