import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CountrySelect = _resolveComponent("CountrySelect")!
  const _component_RegionSelect = _resolveComponent("RegionSelect")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "country-select full-width pa-0" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
        default: _withCtx(() => [
          _createTextVNode(" Country ")
        ]),
        _: 1
      }),
      _createVNode(_component_v_row, { class: "ma-0 mt-2 token-text-regular" }, {
        default: _withCtx(() => [
          _createVNode(_component_CountrySelect, {
            modelValue: _ctx.privateCountry,
            "onUpdate:modelValue": [
              _cache[0] || (_cache[0] = ($event: any) => ((_ctx.privateCountry) = $event)),
              _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:country', $event)))
            ],
            country: _ctx.privateCountry,
            topCountry: "US",
            usei18n: false
          }, null, 8, ["modelValue", "country"])
        ]),
        _: 1
      }),
      (_ctx.useRegion)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_v_row, { class: "ma-0 token-text-regular mt-4" }, {
              default: _withCtx(() => [
                _createTextVNode(" Region ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_row, { class: "ma-0 mt-2 token-text-regular" }, {
              default: _withCtx(() => [
                _createVNode(_component_RegionSelect, {
                  modelValue: _ctx.privateRegion,
                  "onUpdate:modelValue": [
                    _cache[2] || (_cache[2] = ($event: any) => ((_ctx.privateRegion) = $event)),
                    _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:region', $event)))
                  ],
                  country: _ctx.privateCountry,
                  region: _ctx.privateRegion,
                  usei18n: false
                }, null, 8, ["modelValue", "country", "region"])
              ]),
              _: 1
            })
          ], 64))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}