
import { Vue, Options, prop } from 'vue-class-component'
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  title?: string = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  horizontal?: boolean = prop({
    required: false,
  });
  solo?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    Icon
  },
})
export default class ShellDetailField extends Vue.with(Props) {

}
