import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["logo-container", {'full-size' : _ctx.fullSize}])
  }, [
    _createElementVNode("span", {
      class: _normalizeClass(["logo", 'logo-' + (_ctx.color ? 'recolor-' : '') +_ctx.logo]),
      ref: "dom",
      style: _normalizeStyle(_ctx.color ? 'background:'+_ctx.color : '')
    }, null, 6)
  ], 2))
}