
import { Vue, Options, prop } from 'vue-class-component';
import PaymentMethodDisplay from '@/components/UIElements/PaymentMethodDisplay.vue'
import { APIPaymentMethod } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

class Props {
  paymentMethods: APIPaymentMethod[] = prop({
    required: true,
  });
  selectable?: boolean = prop({
    required: false,
  });
  selected?: APIPaymentMethod = prop({
    required: false,
  });
}

@Options({
  components: {
    PaymentMethodDisplay
  },
})
export default class PaymentMethodsDisplay extends Vue.with(Props) {
  selectedPaymentMethod:APIPaymentMethod | null = null
  
  @Watch('paymentMethods', {immediate:true})
  onPaymentMethodsChange() {
    if(this.paymentMethods?.length) {
      this.selectedPaymentMethod = this.paymentMethods[0]
    }
  }

  @Watch('selectedPaymentMethod', {immediate:true})
  onSelectedPaymentMethodsChange() {
    if(this.selectedPaymentMethod) {
      this.$emit('update:selected', this.selectedPaymentMethod)
    }
  }
}
