export default Object.freeze({
    Primary : 'primary',
    Secondary :'secondary',
    Tertiary : 'tertiary',
    Text: 'text',
    Round : 'round',
    SmallIcon : 'small-icon',
    SliderSelected : 'slider-selected',
    SliderNotSelected : 'slider-not-selected',
})
