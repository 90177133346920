
import { Options, Vue } from 'vue-class-component';
import PanelLeft from '@/components/Panels/PanelLeft.vue'; 
import PanelTop from '@/components/Panels/PanelTop.vue'; 
import PanelRight from '@/components/Panels/PanelRight.vue'; 

@Options({
  components: {
    PanelLeft,
    PanelTop,
    PanelRight
  },
})
export default class PanelsLayout extends Vue {}
