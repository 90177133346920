
import { Vue, prop } from 'vue-class-component';
import { APILiveLog } from '@/typesAPI'

class Props {
  log:APILiveLog = prop({
    required: true,
  });
}
export default class LogLine extends Vue.with(Props) {

}
