
import { Options, Vue } from 'vue-class-component';
import SaveBar from '@/components/Actions/SaveBar.vue';
import ValidationErrorsBar from '@/components/ValidationErrors/ValidationErrorsBar.vue';
import ProjectBar from '@/components/Projects/ProjectBar.vue';
import LogoBar from '@/components/Projects/LogoBar.vue';
import ContactUsBar from '@/components/Actions/ContactUsBar.vue';
import AccountBar from '@/components/Account/AccountBar.vue';
import ClusterBar from '@/components/Actions/ClusterBar.vue';

@Options({
  components: {
    SaveBar,
    ValidationErrorsBar,
    ProjectBar,
    LogoBar,
    ContactUsBar,
    AccountBar,
    ClusterBar
  },
})
export default class PanelTop extends Vue {
  get isSubscribed () {
    return this.subscriptionState !== this.$enums.SubscriptionState.FRESH && this.subscriptionState !== this.$enums.SubscriptionState.TRIAL_EXPIRED && this.subscriptionState !== this.$enums.SubscriptionState.EXPIRED
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }
}
