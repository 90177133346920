import { normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["icon-container", {'full-size' : _ctx.fullSize}])
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["icon", 'icon-'+_ctx.icon]),
      ref: "dom",
      style: _normalizeStyle('background: '+_ctx.color+' !important;')
    }, null, 6)
  ], 2))
}