
import { Vue, prop, Options } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
//@ts-ignore
import { CountrySelect, RegionSelect }  from 'vue3-country-region-select'

class Props {
  country: string = prop({
    required: true,
  });
  region?: string = prop({
    required: false,
  });
  useRegion?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CountrySelect,
    RegionSelect,
  },
})
export default class CustomCountrySelect extends Vue.with(Props) {
  privateCountry = ''
  privateRegion = ''

  @Watch('country', {immediate:true})
  onCountryChange() {
    if(this.country) {
      this.privateCountry = this.country
    }
  }

  @Watch('region', {immediate:true})
  onRegionChange() {
    if(this.region) {
      this.privateRegion = this.region
    }
  }
}
