
import CustomButton from '@/components/UIElements/CustomButton.vue';
import Popin from '@/components/UIElements/Popin.vue';
import { Vue, Options } from 'vue-class-component';

@Options({
  components: {
    CustomButton,
    Popin
  },
})
export default class SubscriptionStartTrialModal extends Vue {
  loadingCheckout = false
  
  mounted(): void {
    this.loadingCheckout = false
  }

  checkout() {
    this.loadingCheckout = true
    this.$helpers.subscription.checkout()
  }

  startTrial () {
    this.$emitter.emit('startTrial')
  }

}
