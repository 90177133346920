
import { Vue, prop } from 'vue-class-component';
import { EdgeProps, BezierEdge, EdgeMouseEvent, useVueFlow } from '@vue-flow/core'
import { Options } from 'vue-class-component'
import { APIErrorValidation } from '@/typesAPI';

class Props {
  props: EdgeProps = prop({
    required: true,
  });
}

@Options({
  components: {
    BezierEdge
  },
})
export default class CustomEdge extends Vue.with(Props) {
  buttonSize = 36
  dragLinkPosition = {
    x : 0,
    y : 0
  }
  sourceOrTarget = ''

  vueFlowState = useVueFlow()

  mounted(): void {
    this.props.events.click((edgeMouseEvent:EdgeMouseEvent) => {
      this.$store.dispatch('elements/setSelectedLink', edgeMouseEvent.edge.id)
    })
    this.props.events.mouseEnter((edgeMouseEvent:EdgeMouseEvent) => {
      this.$store.dispatch('elements/setHoveredLink', edgeMouseEvent.edge.id)
    })
    this.props.events.mouseLeave((edgeMouseEvent:EdgeMouseEvent) => {
      this.$store.dispatch('elements/setHoveredLink')
    })
    
    this.$emitter.on('dragLink', (payload:{x: number, y:number, sourceOrTarget:string}) => {
      if (this.isSelected) {
        this.sourceOrTarget = payload.sourceOrTarget
        const projectedXY = this.vueFlowState.project({x: payload.x, y: payload.y})
        this.dragLinkPosition.x = projectedXY.x
        this.dragLinkPosition.y = projectedXY.y
      }
    })
  }

  onEdgeClick() {
    this.onButtonClick()
  }

  onButtonClick() {
    this.$store.dispatch('elements/setSelectedLink')
    this.$store.dispatch('elements/setDetailOpenLink')

    this.$store.dispatch('elements/setDetailOpenLink', this.props.id)
  }

  get isSelected() {
    return this.$store.getters['elements/getSelectedLink'] && this.$store.getters['elements/getSelectedLink'].id === this.props.id
  }

  get buttonX() {
    return this.computedProps.sourceX + ((this.computedProps.targetX - this.computedProps.sourceX) / 2) - this.buttonSize/2
  }
  get buttonY() {
    return this.computedProps.sourceY + ((this.computedProps.targetY - this.computedProps.sourceY) / 2) - this.buttonSize/2
  }

  get isDragging () {
    return !!this.$store.getters['elements/getDraggedLink'] && this.$store.getters['elements/getDraggedLink'].id === this.props.id
  }

  get computedProps() {
    return {
      ...this.props,
      sourceX : this.isDragging && this.sourceOrTarget === "source" ? this.dragLinkPosition.x : this.props.sourceX,
      sourceY : this.isDragging && this.sourceOrTarget === "source" ? this.dragLinkPosition.y : this.props.sourceY,
      targetX : this.isDragging && this.sourceOrTarget === "target" ? this.dragLinkPosition.x : this.props.targetX,
      targetY : this.isDragging && this.sourceOrTarget === "target" ? this.dragLinkPosition.y : this.props.targetY
    }
  }

  get errorsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.computedProps.id)
  }

  get warningsValidation (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings'].filter((error:APIErrorValidation) => error.relationships.source.data.id === this.computedProps.id)
  }

  get isInError():boolean {
    return !!this.errorsValidation.length
  }

  get hoveredError():APIErrorValidation | null {
    return this.$store.getters['errorsValidation/getHoveredError']
  }

  get isValidationErrorHovered(): boolean  {
    return this.hoveredError ? this.hoveredError.relationships.source.data.id === this.computedProps.id : false
  }

  get selectedError():APIErrorValidation | null {
    return this.$store.getters['errorsValidation/getSelectedError']
  }

  get isValidationErrorSelected(): boolean  {
    return this.selectedError ? this.selectedError.relationships.source.data.id === this.computedProps.id : false
  }

}
