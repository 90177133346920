import { Platform } from "@/types"

type State = {
  list: Platform[],
  draggingPlatform?:Platform
}

const state: State = {
  list : [
    {
      key: 'platform-1',
      name: 'Kubernetes',
      icon: 'kubernetes',
      id: '0'
    },
    {
      key: 'platform-2',
      name: 'AWS',
      icon: 'aws',
      id: '1'
    }
  ]
}


const getters = {
  getList: (state:State) => {
    return state.list
  },
  getDraggingPlatform: (state:State) => {
    return state.draggingPlatform
  },
}

const mutations = {
  SET_DRAGGING_PLATFORM: (state:State, platform:Platform) => {
    state.draggingPlatform = platform
  },
}

const actions = {
  setDraggingPlatform: (context:any, platform:Platform) => {
    context.commit('SET_DRAGGING_PLATFORM', platform)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}