import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Divider = _resolveComponent("Divider")!

  return (_openBlock(), _createBlock(_component_v_col, { class: "custom-table full-view pa-0 flex-wrap" }, {
    default: _withCtx(() => [
      (_ctx.headers)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 0,
            class: "ma-0 full-width token-text-medium"
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
                return (_openBlock(), _createBlock(_component_v_col, {
                  cols: 12/_ctx.headers.length,
                  class: "pa-0",
                  key: 'header-'+index
                }, {
                  default: _withCtx(() => [
                    (_ctx.$slots['header'])
                      ? _renderSlot(_ctx.$slots, "header", {
                          key: 0,
                          header: header,
                          index: index
                        })
                      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                          _createTextVNode(_toDisplayString(header), 1)
                        ], 64))
                  ]),
                  _: 2
                }, 1032, ["cols"]))
              }), 128))
            ]),
            _: 3
          }))
        : _createCommentVNode("", true),
      (_ctx.headers)
        ? (_openBlock(), _createBlock(_component_v_row, {
            key: 1,
            class: "ma-0 mb-2 mt-2 full-width"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_col, { class: "pa-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Divider)
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (row, indexR) => {
        return (_openBlock(), _createBlock(_component_v_row, {
          class: "ma-0 full-width token-text-medium mb-2",
          key: 'row-'+indexR
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (val, indexC) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                class: _normalizeClass(["pa-0 d-flex", (_ctx.colClasses && _ctx.colClasses[indexC]) ? _ctx.colClasses[indexC] : '']),
                key: 'cell-'+indexR+'-'+indexC
              }, {
                default: _withCtx(() => [
                  (_ctx.$slots['value'])
                    ? _renderSlot(_ctx.$slots, "value", {
                        key: 0,
                        value: val,
                        row: indexR,
                        col: indexC
                      })
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(val), 1)
                      ], 64))
                ]),
                _: 2
              }, 1032, ["class"]))
            }), 128))
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 3
  }))
}