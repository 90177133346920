import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "projectsView full-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_ProjectsSneakPeek = _resolveComponent("ProjectsSneakPeek")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
              default: _withCtx(() => [
                _createTextVNode(" Projects ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  icon: "add",
                  text: "Create project",
                  onClick: _ctx.createProject
                }, null, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mx-n6 pt-4" }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.projectsList, (project, index) => {
              return (_openBlock(), _createBlock(_component_v_col, {
                class: "pa-4",
                cols: "6",
                key: project.id
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ProjectsSneakPeek, {
                    project: _ctx.projectsList[index],
                    "onUpdate:project": ($event: any) => ((_ctx.projectsList[index]) = $event)
                  }, null, 8, ["project", "onUpdate:project"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}