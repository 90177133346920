
import { Vue, Options } from 'vue-class-component'
import { Element, Block } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import List, {ListItem} from '@/components/UIElements/List.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import NodeDetailContent from '@/components/Details/NodeDetailContent.vue'
import NodeDetailMetrics from '@/components/Details/NodeDetailMetrics.vue'
import NodeDetailLogs from '@/components/Details/NodeDetailLogs.vue'
import { APITabField, APIBlock, APIFieldDefinition, APIFieldValue, APIFieldBox } from '@/typesAPI'
import { Watch } from 'vue-property-decorator'
import API from '@/api/wrapper'
import Spinner from '@/components/UIElements/Spinner.vue'

@Options({
  components: {
    Icon,
    Logo,
    CustomButton,
    List,
    NodeDetailContent,
    NodeDetailMetrics,
    NodeDetailLogs,
    Spinner
  },
})
export default class NodeDetail extends Vue {
  currentConfigTab: ListItem | null = null 
  editedName = ""
  listConfigTab:ListItem[] = []
  saving = false
  saveBtnStatus = ''
  saveBtnTxt = 'Save'
  loading = false

  @Watch('isOpened', {immediate: true})
  onIsOpenedChange() {
    if(this.isOpened) {
      this.init()
    }
  }

  init() {
    this.initTabSelected()
    if(this.blockAPIName) {
      this.editedName = this.blockAPIName
    }
  }

  @Watch('currentConfigTab', {immediate: true})
  onTabSelectedChange() {
    // TODO : check correctly for Live Tabs
    if(!this.loading && this.currentConfigTab?.id && !this.isTabLoaded && this.currentConfigTab?.id !== "metrics" && this.currentConfigTab?.id !== "logs" ) {
      this.loading = true
      API.blocks.getBlockFields(this.blockAPI?.id as string, this.currentConfigTab?.id as string)
      .then((result) => {
        this.$store.dispatch('fields/addTabLoaded', this.blockAPI?.id +'-'+ this.currentConfigTab?.id )

        this.$store.dispatch('fields/addFieldValues', result.values)
        this.$store.dispatch('fields/addFieldBoxes', result.boxes)
        this.$store.dispatch('fields/addNodeFieldDefinitions', result.definitions)
        this.$store.dispatch('fields/addFieldGroups', result.groups)

        this.$store.dispatch('fields/buildTree')

        this.loading = false
      })
    }
  }

  get isTabLoaded():boolean {
    return this.$store.getters['fields/getIsTabLoaded'](this.blockAPI?.id +'-'+ this.currentConfigTab?.id)
  }

  get blockAPI():APIBlock | undefined {
    return this.node && this.node.extra && this.tree.blocks && this.tree.blocks[this.node?.extra?.blockAPIID] ? this.tree.blocks[this.node?.extra?.blockAPIID] : undefined
  }

  get node ():Element {
    return this.$store.getters['elements/getDetailOpenElement']
  }

  get block ():Block {
    return this.node?.extra?.block
  }

  get isOpened () {
    return !!this.node
  }

  get errorsValidationModalIsOpen ():boolean {
    return this.$store.getters['errorsValidation/getModalIsOpen']
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

  get isOnlyOneTab (): boolean {
    return this.listConfigTab.length === 1
  }

  get tree():any {
    return this.$store.getters['fields/getTree']
  }

  get blockAPIName() {
    let ret = ''
    if(this.blockAPI) {
      ret = this.blockAPI?.attributes?.name ? this.blockAPI.attributes.name : this.blockAPI.attributes.default_name 
    }
    return ret
  }

  get listConfigTabGetter ():ListItem[] {

    let ret:ListItem[] = []
    let listTab:APITabField[] = []
    // if(this.blockAPI && this.tree.blocks[this.blockAPI.id]) {
    //   listTab = Object.values(this.tree.blocks[this.blockAPI.id].tabs) as APITabField[]
    // }
    if(this.blockAPI) {
      listTab = this.$store.getters['fields/getTabsByBlockID'](this.blockAPI.id)
    }

    listTab.sort((tab1:APITabField, tab2:APITabField) => {
      return tab1.attributes.priority - tab2.attributes.priority
    })


    ret = listTab.map((tab:APITabField) => {
      return {
        text: tab.attributes.name,
        id: tab.id
      }
    })

    const monitoringPart:ListItem[] = [
      {
        text: '-',
        id:'-',
        separator: true
      },
      {
        text: 'Metrics',
        id:'metrics'
      },
      {
        text: 'Logs',
        id:'logs'
      }
    ]

    if(this.blockStatus !== this.$enums.DeploymentStatus.OFFLINE) {
      ret = ret.concat(...monitoringPart)
    }
    
    return ret
  }

  get blockStatus() {
    return this.$store.getters["blocksAPI/getDeployementStatusByBlockID"](this.blockAPI?.id)
  }

  initTabSelected() {
    this.listConfigTab = this.listConfigTabGetter

    if(this.listConfigTab && this.listConfigTab[0]) {
      if(this.$route.params.tabId) {
        // Chose Tab
        this.listConfigTab.forEach((tabItem:ListItem) => {
          if(tabItem.id === this.$route.params.tabId) {
            tabItem.selected = true
            this.currentConfigTab = tabItem
          } else {
            tabItem.selected = false
          }
        })
      } else {
        this.listConfigTab[0].selected = true
        this.currentConfigTab = this.listConfigTab[0]
      }

      if(this.currentConfigTab && this.$route.params.tabId !== this.currentConfigTab?.id && this.blockAPI) {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab.id
          }
        });
      }
    }
  }

  @Watch('$route.params.tabId')
  onQueryChange() {
    if(this.$route.name === 'flow') {
      this.$store.dispatch('elements/setDetailOpenElement')
    } else {
      this.initTabSelected()
    }
  }

  @Watch('listConfigTabGetter')
  onListTabChange() {
    this.initTabSelected()
  }

  onSave(shouldClose:boolean) {
    this.saving = true
    this.saveBtnTxt = 'Saving'
    setTimeout(() => {
      this.onSaveSuccess(shouldClose)
    },250)
  }

  onSaveSuccess(shouldClose:boolean) {
    if(this.saving) {
      this.saveBtnTxt = 'Saved'
      this.saving = false
      this.saveBtnStatus = this.$enums.ButtonStates.Success
      setTimeout(() => {
        this.saveBtnStatus = ''
        this.saveBtnTxt = 'Save'
        if(shouldClose) {
          this.onClose()
        }
      },1000)
    }
    
  }

  onClose() {
    this.$store.dispatch('elements/setDetailOpenElement')
  }

  onSelectConfigTab(configTab:ListItem ) {
    this.currentConfigTab = configTab
    this.$router.push({
      name: 'flowBlock',
      params: {
        ...this.$route.params,
        blockId: this.blockAPI ? this.blockAPI.id : '',
        tabId: configTab.id
      }
    });
  }

  deleteBlock() {
    this.$emitter.emit('deleteBlock', this.node)
  }

  @Watch('isOpened')
  onOpenChange() {
    this.saveBtnStatus = ''
    if(!this.listConfigTab[0]) {
      this.listConfigTab = this.listConfigTabGetter
    }

    if(this.isOpened && this.listConfigTab[0]) {
      this.listConfigTab[0].selected = true
      this.currentConfigTab = this.listConfigTab[0]
      if(this.$route.params.boxId) {
        this.$router.push({
          name: 'flowBlockAndBox',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab ? this.currentConfigTab.id : '',
            boxId: this.$route.params.boxId
          }
        })
      } else {
        this.$router.push({
          name: 'flowBlock',
          params: {
            ...this.$route.params,
            blockId: this.blockAPI ? this.blockAPI.id : '',
            tabId: this.currentConfigTab ? this.currentConfigTab.id : '',
          }
        })
      }
     
    } else {
      this.$router.push({
        ...this.$route.params,
        name: 'flow'
      });
    }
  }

  @Watch('listConfigTab')
  onListConfigTabChange() {
    if(this.currentConfigTab) {
      this.listConfigTab.forEach((tab:ListItem) => {
        tab.selected = tab.id === this.currentConfigTab?.id
      })
    }
  }

  @Watch('blockAPI')
  onBlockAPIChange() {
    this.editedName = this.blockAPIName
  }

  onNameEdit() {
    if(this.blockAPI && this.editedName !== this.blockAPIName) {
      API.blocks.editBlockName(this.blockAPI.id, this.editedName).then((data) => {
        this.$store.dispatch('blocksAPI/editBlock', data)
        this.$store.dispatch('deployment/editDeploymentSubject', data)
      })
    }
  }



  getTabByFieldID(id:string): APITabField {
    const field: APIFieldDefinition = this.$store.getters['fields/getDefinitionsByID'](id) as APIFieldDefinition
    const box : APIFieldBox = this.$store.getters['fields/getBoxByID'](field?.relationships?.box.data.id) as APIFieldBox
    const tab : APITabField = this.$store.getters['fields/getTabByID'](box?.relationships?.tab.data.id) as APITabField
    return tab
  }

  getFieldsDefinitionsByIDValue(id:string): APIFieldDefinition {
    return this.$store.getters['fields/getDefinitionsByIDValue'](id) as APIFieldDefinition
  }

  

}
