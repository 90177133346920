
import { Vue, Options, prop } from 'vue-class-component';
import Icon from '@/components/UIElements/Icon.vue'

class Props {
  showIcon?: boolean = prop({
    required: false,
  });
  outlined?: boolean = prop({
    required: false,
  });
  label?: string = prop({
    required: false,
  });
  icon?: string = prop({
    required: false,
  });
  state: string = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
  },
})
export default class Badge extends Vue.with(Props) {

  get computedIcon ():string {
    switch (this.state) {
      case this.$enums.BadgeStates.Danger :
        return 'cancel-circle';
      case this.$enums.BadgeStates.Info :
        return 'cross';
      case this.$enums.BadgeStates.Success :
        return 'check-circle';
      case this.$enums.BadgeStates.Warning :
        return 'warning';
      default :
        return '';
    }
  }
}
