
import { Vue, Options, prop } from 'vue-class-component';
import PaymentMethodLogo from '@/components/UIElements/PaymentMethodLogo.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import { APIPaymentMethod } from '@/typesAPI';
import Badge from '@/components/UIElements/Badge.vue';
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue';
import { ListItem } from '@/components/UIElements/List.vue';
import API from '@/api/wrapper';

class Props {
  paymentMethod: APIPaymentMethod = prop({
    required: true,
  });
  withBurger?: boolean = prop({
    required: false,
  });
  isDefault?:boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    PaymentMethodLogo,
    CustomButton,
    Badge,
    DropdownMenu
  },
})
export default class PaymentMethodDisplay extends Vue.with(Props) {
  burgerOpened = false
  burgerItems: ListItem[] = [
    {
      id: 'default',
      text: 'Set to default',
      icon: 'star'
    },
    {
      id: 'separator',
      separator: true,
    },
    {
      id: 'delete',
      text: 'Delete',
      icon: 'trash',
      class: 'delete'
    },
  ]
  
  onBurgerMenuItemClick(item:ListItem) {
    if(item.id === 'default') {
      //Set to default
      API.billing.editPaymentMethod(this.paymentMethod.id, {priority: 1})
      .then((res:APIPaymentMethod) => {
        this.$store.dispatch('billing/invalidatePaymentMethods')
      })
    }

    item.selected = false
    this.burgerOpened = false
  }  
}
