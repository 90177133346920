import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_range_slider = _resolveComponent("v-range-slider")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["slider", {disabled:_ctx.disabled, hideThumb:_ctx.hideThumb}])
  }, [
    _createVNode(_component_v_row, { class: "ma-2" }, {
      default: _withCtx(() => [
        (_ctx.range)
          ? (_openBlock(), _createBlock(_component_v_range_slider, {
              key: 0,
              modelValue: _ctx.insideValues,
              "onUpdate:modelValue": [
                _cache[0] || (_cache[0] = ($event: any) => ((_ctx.insideValues) = $event)),
                _ctx.updateValue
              ],
              "hide-details": true,
              step: _ctx.step || 1,
              min: _ctx.min,
              max: _ctx.max,
              "show-ticks": 'always',
              "tick-size": "4",
              "track-fill-color": "rgba(50, 25, 178, 1)",
              "track-color": "rgba(0, 0, 58, 0.3)",
              "thumb-label": _ctx.hideThumbLabels ? false : 'always',
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "step", "min", "max", "onUpdate:modelValue", "thumb-label", "disabled"]))
          : (_openBlock(), _createBlock(_component_v_slider, {
              key: 1,
              modelValue: _ctx.insideValues[0],
              "onUpdate:modelValue": [
                _cache[1] || (_cache[1] = ($event: any) => ((_ctx.insideValues[0]) = $event)),
                _ctx.updateValue
              ],
              "hide-details": true,
              step: _ctx.step || 1,
              min: _ctx.min,
              max: _ctx.max,
              "show-ticks": 'always',
              "tick-size": "4",
              "track-fill-color": "transparent",
              "track-color": "rgba(0, 0, 58, 0.3)",
              "thumb-label": _ctx.hideThumbLabels ? false : 'always',
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "step", "min", "max", "onUpdate:modelValue", "thumb-label", "disabled"]))
      ]),
      _: 1
    })
  ], 2))
}