
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import DropdownMenu from '@/components/UIElements/DropdownMenu.vue'
import { ListItem } from '@/components/UIElements/List.vue';
import API from '@/api/wrapper';
import { APIUrlRedirection } from '@/typesAPI';

@Options({
  components: {
    CustomButton,
    Icon,
    DropdownMenu
  },
})
export default class AccountBar extends Vue {

  opened = false
  modelValue: ListItem = {}

  toggleDropDown () {
    this.opened = !this.opened
  }

  onModelValueUpdate (newVal:ListItem) {
    newVal.selected = false
    this.opened = false

    switch(newVal.id) {
      case 'subscription' : 
        API.subscription.manage()
        .then((urlRedirection:APIUrlRedirection) => {
          window.location.href = urlRedirection.attributes.url
        })
        .catch()
      break;
      case 'logout' : 
        API.session.logout()
        .then(() => {
          this.$store.dispatch('user/setUser', null)
          this.$router.push({name: 'login'})
        })
      break;
      default:
      break;
    }
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get items():ListItem[]  {
     return [
      {
        id: 'subscription',
        icon: this.subscriptionState === this.$enums.SubscriptionState.ACTIVE ? 'subscription-dollar' : 'arrow-up',
        text: this.subscriptionState === this.$enums.SubscriptionState.ACTIVE ? 'Manage subscription' : 'Upgrade',
      },
      {
        id: 'separator',
        separator: true,
      },
      {
        id: 'logout',
        icon: 'send-out',
        text: 'Logout',
        class: 'logout'
      }
    ]
  }
  

}
