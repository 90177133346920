
import { Vue } from 'vue-class-component';
import Echo from "laravel-echo";
import API from '@/api/wrapper';
import getEnv from '@/utils/env';
import { APICluster, APICustomer, APIDeployment, APIDeploymentLog, APIDeploymentPlan, APIDeploymentSubject, APIDeploymentVersion, APIFieldValue, APIProject, APIUser } from '@/typesAPI';
import { Watch } from 'vue-property-decorator';

export default class SocketsManager extends Vue {
  echo?:Echo   

  mounted(): void {
    this.connectSocket()
  }


  connectSocket() {
    //@ts-ignore
    if(!window.Pusher) {
      //@ts-ignore
      window.Pusher = require('pusher-js');
    }
   
    this.echo =  new Echo({
      broadcaster: 'pusher',
      key: 'app-key',
      wsHost: getEnv('VUE_APP_SOCKET_URL'),
      wsPort: 80,
      wssPort: 443,
      forceTLS: false,
      encrypted: true,
      disableStats: true,
      enabledTransports: ['ws', 'wss'],
      authorizer: (channel:any, options:any) => {
        return {
          authorize: (socketId:any, callback:any) => {
            API.sockets.auth(callback, {
              socket_id: socketId,
              channel_name: channel.name
            })
          }
        };
      },
    });

    this.connectChannels()

  }

  get customer():APICustomer {
    return this.$store.getters['user/getCustomer']
  }
  get user():APIUser {
    return this.$store.getters['user/getUser']
  }
  get clusters():APICluster[] {
    return this.$store.getters['clusters/getClusters']
  }
  get routeProjectId():string {
    return this.$route?.params?.projectId as string
  }

  connectChannels() {
    if(this.echo) {
      this.onCustomerChange()
      this.onUserChange()
      this.onRouteProjectIdChange()
      this.onClustersChange()
    }
  }

  unmounted(): void {
    if(this.echo) {
      this.echo.leaveAllChannels()
    }
  }

  @Watch("customer", {immediate:true})
  onCustomerChange() {
    if(this.echo && this.customer && !this.echo?.connector?.channels['private-customers.'+ this.customer?.id]) {
      this.echo
      .channel('private-customers.'+ this.customer.id)
      // Customer
      .listen('.customers.updated', (e:any) => {
        this.$helpers.console.log('.customers.updated' , e)
        this.$store.dispatch('user/setCustomer', e)
      })
      // TODO
      .listen('.invoices.updated', (e:any) => {
        this.$helpers.console.log('.invoices.updated' , e)
      })
      // TODO
      .listen('.paymentMethods.created', (e:any) => {
        this.$helpers.console.log('.paymentMethods.created' , e)
      })
      // TODO
      .listen('.paymentMethods.updated', (e:any) => {
        this.$helpers.console.log('.paymentMethods.updated' , e)
      })
      
    }
  }

  @Watch("user", {immediate:true})
  onUserChange() {
    if(this.echo && this.user && !this.echo?.connector?.channels['private-users.'+ this.user?.id]) {

      this.echo
      .channel('private-users.'+ this.user.id)
      // User
      .listen('.users.updated', (e:any) => {
        this.$helpers.console.log('.user.updated' , e)
        this.$store.dispatch('user/setUser', e)
      })
    }
  }

  
  @Watch("clusters", {immediate:true})
  onClustersChange() {
    if(this.echo && this.clusters) {
      console.log(this.echo?.connector.channels)
      this.clusters.forEach((cluster) => {
        if(this.echo && !this.echo?.connector?.channels['kubernetesClusters.'+ cluster.id]) {
          this.echo
          .channel('private-kubernetesClusters.'+ cluster.id)
          // Deployement
          .listen('.deploymentVersions.started', (deployementVersion: APIDeploymentVersion) => {
            this.$helpers.console.log('.deploymentVersions.started' , deployementVersion)

            if(deployementVersion) {
              API.deployment.getDeploymentPlans(deployementVersion.id)
              .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
                if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
                  this.$store.dispatch('deployment/setModalIsOpen', true)
                }
                this.$store.dispatch('deployment/addDeploymentVersion', deployementVersion)
                this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)
                value.subjects.forEach((subject:APIDeploymentSubject) => {
                  this.$store.dispatch('deployment/addDeploymentSubject', subject)
                })
              })
            }
          })
          .listen('.deploymentVersions.deleted', (e:APIDeploymentVersion) => {
            this.$helpers.console.log('.deploymentVersions.deleted' , e)
            this.$store.dispatch('deployment/deleteDeploymentVersion', e)
          })
          .listen('.deploymentVersions.created', (e:APIDeploymentVersion) => {
            this.$helpers.console.log('.deploymentVersions.created' , e)
            this.$store.dispatch('deployment/addDeploymentVersion', e)
          })
          .listen('.deploymentVersions.fullyCompleted', (e:any) => {
            this.$helpers.console.log('.deploymentVersions.fullyCompleted' , e)
            this.$store.dispatch('deployment/addDeploymentVersion', e)
            this.$store.dispatch('deployment/setForceDeployCompletedState', true)
          })

          .listen('.deploymentPlans.logs', (e:APIDeploymentLog[]) => {
            this.$helpers.console.log('.deploymentPlans.logs' , e)
            this.$store.dispatch('deployment/addDeploymentLogs', {deploymentLogs: e, planId: e[0].relationships.plan.data.id})
          })
          .listen('.deploymentPlans.createdOrUpdated', (e:APIDeploymentPlan) => {
            this.$helpers.console.log('.deploymentPlans.createdOrUpdated' , e)
            this.$store.dispatch('deployment/editDeploymentPlan', e)
            this.$store.dispatch('deployment/addDeploymentSubject', this.$store.getters['blocksAPI/getByID'](e.relationships.subject.data.id))
          })
          .listen('.deploymentPlans.deleted', (e:APIDeploymentPlan) => {
            this.$helpers.console.log('.deploymentPlans.deleted' , e)
            this.$store.dispatch('deployment/deleteDeploymentPlan', e)
          })

          .listen('.deployments.created', (e:APIDeployment)  => {
            this.$helpers.console.log('.deployments.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
            // if(!this.$helpers.ressources.isFromMe(e)) {
              this.$store.dispatch('blocksAPI/addDeployments', [e])
            // }
          })
          .listen('.deployments.deleted', (e:APIDeployment)  => {
            this.$helpers.console.log('.deployments.deleted' , e)
            this.$store.dispatch('blocksAPI/deleteDeployment', e)
          })
          .listen('.deployments.updated', (e:APIDeployment)  => {
            this.$helpers.console.log('.deployments.updated' , e)
            this.$store.dispatch('blocksAPI/addDeployments', [e])
          })
          .listen('.deployments.statusChanged', (e:APIDeployment)  => {
            this.$helpers.console.log('.deployments.statusChanged' , e)
            this.$store.dispatch('blocksAPI/addDeployments', [e])
          })

        }
      })
    } 
  }

  @Watch("routeProjectId", {immediate:true})
  onRouteProjectIdChange() {
    if(this.echo && this.routeProjectId && !this.echo?.connector?.channels['private-projects.'+ this.$route.params.projectId as string]) {

      this.echo
      .channel('private-projects.'+ this.$route.params.projectId as string)
      // Deployement
      .listen('.deploymentVersions.started', (deployementVersion: APIDeploymentVersion) => {
        this.$helpers.console.log('.deploymentVersions.started' , deployementVersion)

        if(deployementVersion) {
          API.deployment.getDeploymentPlans(deployementVersion.id)
          .then ((value: { deploymentPlans: APIDeploymentPlan[]; subjects:APIDeploymentSubject[];}) => {
            if (deployementVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
              this.$store.dispatch('deployment/setModalIsOpen', true)
            }
            this.$store.dispatch('deployment/addDeploymentVersion', deployementVersion)
            this.$store.dispatch('deployment/addDeploymentPlans', value.deploymentPlans)
            value.subjects.forEach((subject:APIDeploymentSubject) => {
              this.$store.dispatch('deployment/addDeploymentSubject', subject)
            })
          })
        }
      })
      .listen('.deploymentVersions.deleted', (e:APIDeploymentVersion) => {
        this.$helpers.console.log('.deploymentVersions.deleted' , e)
        this.$store.dispatch('deployment/deleteDeploymentVersion', e)
      })
      .listen('.deploymentVersions.created', (e:APIDeploymentVersion) => {
        this.$helpers.console.log('.deploymentVersions.created' , e)
        this.$store.dispatch('deployment/addDeploymentVersion', e)
      })
      .listen('.deploymentVersions.fullyCompleted', (e:any) => {
        this.$helpers.console.log('.deploymentVersions.fullyCompleted' , e)
        this.$store.dispatch('deployment/addDeploymentVersion', e)
        this.$store.dispatch('deployment/setForceDeployCompletedState', true)
      })

      .listen('.deploymentPlans.logs', (e:APIDeploymentLog[]) => {
        this.$helpers.console.log('.deploymentPlans.logs' , e)
        this.$store.dispatch('deployment/addDeploymentLogs', {deploymentLogs: e, planId: e[0].relationships.plan.data.id})
      })
      .listen('.deploymentPlans.createdOrUpdated', (e:APIDeploymentPlan) => {
        this.$helpers.console.log('.deploymentPlans.createdOrUpdated' , e)
        this.$store.dispatch('deployment/editDeploymentPlan', e)
        this.$store.dispatch('deployment/addDeploymentSubject', this.$store.getters['blocksAPI/getByID'](e.relationships.subject.data.id))
      })
      .listen('.deploymentPlans.deleted', (e:APIDeploymentPlan) => {
        this.$helpers.console.log('.deploymentPlans.deleted' , e)
        this.$store.dispatch('deployment/deleteDeploymentPlan', e)
      })

      .listen('.deployments.created', (e:APIDeployment)  => {
        this.$helpers.console.log('.deployments.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        // if(!this.$helpers.ressources.isFromMe(e)) {
          this.$store.dispatch('blocksAPI/addDeployments', [e])
        // }
      })
      .listen('.deployments.deleted', (e:APIDeployment)  => {
        this.$helpers.console.log('.deployments.deleted' , e)
        this.$store.dispatch('blocksAPI/deleteDeployment', e)
      })
      .listen('.deployments.updated', (e:APIDeployment)  => {
        this.$helpers.console.log('.deployments.updated' , e)
        this.$store.dispatch('blocksAPI/addDeployments', [e])
      })
      .listen('.deployments.statusChanged', (e:APIDeployment)  => {
        this.$helpers.console.log('.deployments.statusChanged' , e)
        this.$store.dispatch('blocksAPI/addDeployments', [e])
      })

      // ValidationErrors
      .listen('.validationErrors.calculated', (e:any) => {
        this.$helpers.console.log('.validationErrors.calculated' , e)
        this.$store.dispatch('errorsValidation/deleteErrors', e.passing)
        this.$store.dispatch('errorsValidation/addErrors', e.failing)
      })
      // Fields
      .listen('.fields.created',  (payload:{data:APIFieldValue, included : any[]})  => {
        this.$helpers.console.log('fields.created' , payload, this.$helpers.ressources.isFromMe(payload.data) ? 'FROM ME' : '')

        // if(!this.$helpers.ressources.isFromMe(payload.data)) {
          this.$store.dispatch('fields/addNodeFieldDefinition', payload.included.find((item) => item.type === "fieldDefinitions"))
          this.$store.dispatch('fields/addNodeFieldTab', payload.included.find((item) => item.type === "blockTabs"))
          this.$store.dispatch('fields/addNodeFieldBox', payload.included.find((item) => item.type === "fieldBoxes"))

          this.$store.dispatch('fields/addNodeFieldValue', payload.data)
        // }
        setTimeout(() => {
          this.$emitter.emit(`fields.created.${payload.data.id}`)
        })

      })
      .listen('.fields.updated', (payload:{data:APIFieldValue, included : any[]}) => {
        this.$helpers.console.log('fields.updated' , payload, this.$helpers.ressources.isFromMe(payload.data) ? 'FROM ME' : '')

        // if(!this.$helpers.ressources.isFromMe(payload.data)) {
          this.$store.dispatch('fields/addNodeFieldDefinition', payload.included.find((item) => item.type === "fieldDefinitions"))
          this.$store.dispatch('fields/addNodeFieldTab', payload.included.find((item) => item.type === "blockTabs"))
          this.$store.dispatch('fields/addNodeFieldBox', payload.included.find((item) => item.type === "fieldBoxes"))
          this.$store.dispatch('fields/addNodeFieldValue', payload.data)
        // }

        setTimeout(() => {
          this.$emitter.emit(`fields.updated.${payload.data.id}`)
        })

      })
      .listen('.fields.deleted',  (payload:{data:APIFieldValue, included : any[]})  => {
        this.$helpers.console.log('fields.deleted' , payload)

        this.$store.dispatch('fields/deleteFieldValue', payload.data)
        setTimeout(() => {
          this.$emitter.emit(`fields.deleted.${payload.data.id}`)
        })

      })
      .listen('.projects.created', (e:APIProject)  => {
        this.$helpers.console.log('.projects.created' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        // if(!this.$helpers.ressources.isFromMe(e)) {
          this.$store.dispatch('projects/updateProject', e)
        // }

      })

      .listen('.blocks.fieldsRecalculated', (e:APIDeployment)  => {
        this.$helpers.console.log('.blocks.fieldsRecalculated' , e, this.$helpers.ressources.isFromMe(e) ? 'FROM ME' : '')
        this.$store.dispatch('blocksAPI/editBlock', e)
      })
      
    }
  }
}
