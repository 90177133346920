import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af0920b4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "detail-field-social-account" }
const _hoisted_2 = { class: "token-text-medium" }
const _hoisted_3 = { class: "token-text-micro" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Avatar = _resolveComponent("Avatar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_v_img = _resolveComponent("v-img")!
  const _component_ShellDetailField = _resolveComponent("ShellDetailField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ShellDetailField, {
      disabled: _ctx.disabled,
      solo: _ctx.solo
    }, {
      content: _withCtx(() => [
        _createVNode(_component_v_col, {
          class: _normalizeClass(["pa-0 detail-field-social-account-content", {noAccount: !_ctx.socialAccount}])
        }, {
          default: _withCtx(() => [
            (_ctx.socialAccount)
              ? (_openBlock(), _createBlock(_component_v_row, {
                  key: 0,
                  class: "ma-0 ma-2"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_col, { class: "pa-0 fit-content" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_Avatar, {
                          src: _ctx.socialAccount.attributes.avatar
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, { class: "pa-0 pl-4 d-flex" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_row, { class: "my-auto ml-2" }, {
                          default: _withCtx(() => [
                            (_ctx.forcedSocialAccount)
                              ? (_openBlock(), _createBlock(_component_v_col, {
                                  key: 0,
                                  class: "pa-0"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.socialAccount.attributes.provider), 1),
                                    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.socialAccount.attributes.nickname), 1)
                                  ]),
                                  _: 1
                                }))
                              : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                  _createTextVNode(_toDisplayString(_ctx.socialAccount.attributes.nickname), 1)
                                ], 64))
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_col, {
                      class: "pa-0 my-auto",
                      style: {"position":"relative"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          ref: "menuButton",
                          class: "ml-auto mr-0 px-3",
                          level: _ctx.forcedSocialAccount ? _ctx.$enums.ButtonLevels.Secondary : _ctx.$enums.ButtonLevels.Tertiary,
                          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.burgerOpened = !_ctx.burgerOpened)),
                          icon: "burger"
                        }, null, 8, ["level"]),
                        _createVNode(_component_DropdownMenu, {
                          items: _ctx.burgerItems,
                          show: _ctx.burgerOpened,
                          "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.burgerOpened) = $event)),
                          maxContent: true,
                          "onUpdate:modelValue": _ctx.onBurgerMenuItemClick,
                          target: _ctx.$refs.menuButton,
                          classInternal: "mt-2"
                        }, null, 8, ["items", "show", "onUpdate:modelValue", "target"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_openBlock(), _createBlock(_component_v_col, {
                  key: 1,
                  class: "pa-4"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_v_row, { class: "ma-0 pb-4" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_v_img, {
                          src: _ctx.socialAccountProviderIntegrationImg,
                          height: "72px"
                        }, null, 8, ["src"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-medium pb-2" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Connect Fransys to " + _toDisplayString(_ctx.socialAccountProviderName) + " to start hosting. ", 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0 token-text-regular token-text-color-secondary pb-4" }, {
                      default: _withCtx(() => [
                        _createTextVNode(" Fransys will only use your account to import your code and generate a working CI. ")
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_v_row, { class: "ma-0" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_CustomButton, {
                          class: "connect-button",
                          level: _ctx.$enums.ButtonLevels.Secondary,
                          onClick: _ctx.connect
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode("Connect " + _toDisplayString(_ctx.socialAccountProviderName), 1)
                          ]),
                          _: 1
                        }, 8, ["level", "onClick"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
          ]),
          _: 1
        }, 8, ["class"])
      ]),
      _: 1
    }, 8, ["disabled", "solo"])
  ]))
}