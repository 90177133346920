import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "widget-billing-detail" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_row = _resolveComponent("v-row")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_CustomCountrySelect = _resolveComponent("CustomCountrySelect")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0 token-text-regular" }, {
          default: _withCtx(() => [
            _createTextVNode(" Full name or company name ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.detailForm.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.detailForm.name = $event)),
              class: "full-width"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomCountrySelect, {
              country: _ctx.detailForm.country,
              "onUpdate:country": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.detailForm.country) = $event))
            }, null, 8, ["country"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 token-text-regular" }, {
          default: _withCtx(() => [
            _createTextVNode(" City ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.detailForm.city,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.detailForm.city = $event)),
              class: "full-width"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 token-text-regular" }, {
          default: _withCtx(() => [
            _createTextVNode(" Postal Code ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.detailForm.postal_code,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.detailForm.postal_code = $event)),
              class: "full-width"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 token-text-regular" }, {
          default: _withCtx(() => [
            _createTextVNode(" Address ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.detailForm.address,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_ctx.detailForm.address = $event)),
              class: "full-width"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4 token-text-regular" }, {
          default: _withCtx(() => [
            _createTextVNode(" European TVA number (optional) ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 my-2" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomTextInput, {
              modelValue: _ctx.detailForm.tax_id,
              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.detailForm.tax_id = $event)),
              class: "full-width"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_CustomButton, {
              text: "Save payment billing details",
              level: _ctx.$enums.ButtonLevels.Primary,
              class: "full-width",
              onClick: _ctx.onSubmit,
              disabled: _ctx.disabled,
              loading: _ctx.loading
            }, null, 8, ["level", "onClick", "disabled", "loading"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}