import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "projectsView full-view" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_col = _resolveComponent("v-col")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_Divider = _resolveComponent("Divider")!
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_DetailFieldSocialAccount = _resolveComponent("DetailFieldSocialAccount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h4" }, {
              default: _withCtx(() => [
                _createTextVNode(" Account ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  icon: "send-out",
                  text: "Logout",
                  level: _ctx.$enums.ButtonLevels.Tertiary,
                  onClick: _ctx.logout
                }, null, 8, ["level", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => [
                _createTextVNode(" Account details ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 ml-auto fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomButton, {
                  text: "Save changes",
                  level: _ctx.$enums.ButtonLevels.Secondary,
                  disabled: _ctx.disableSaveButton,
                  onClick: _ctx.saveChange,
                  loading: _ctx.saveLoading
                }, null, 8, ["level", "disabled", "onClick", "loading"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-2 token-text-regular" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 mr-8" }, {
              default: _withCtx(() => [
                _createTextVNode(" Email ")
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createTextVNode(" Full name ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 mr-8" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomTextInput, {
                  modelValue: _ctx.email,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event)),
                  placeholder: "email@placeholder.com",
                  disabled: true
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_CustomTextInput, {
                  modelValue: _ctx.name,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.name) = $event)),
                  placeholder: "Jon Smith"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 token-text-h5" }, {
              default: _withCtx(() => [
                _createTextVNode(" Connected services ")
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_v_row, { class: "ma-0 mt-4" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0" }, {
              default: _withCtx(() => [
                _createVNode(_component_Divider)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountList, (account) => {
          return (_openBlock(), _createBlock(_component_v_row, {
            class: "ma-0",
            key: account.id
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DetailFieldSocialAccount, {
                forcedSocialAccount: account,
                class: "full-width"
              }, null, 8, ["forcedSocialAccount"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    })
  ]))
}