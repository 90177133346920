import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "my-auto token-text-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass('custom-button ' + (_ctx.level ? _ctx.level : 'primary') + ' ' + (_ctx.computedDisabled ? 'disabled' : '') + ' ' + (_ctx.hasText ? 'btn-text' : '') + ' ' + (_ctx.loading ? 'loading' : '') + ' ' + (_ctx.status ? 'state-'+_ctx.status : ''))
  }, [
    _createVNode(_component_v_row, { class: "my-auto mx-0" }, {
      default: _withCtx(() => [
        (_ctx.computedIcon)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass(["pa-0 d-flex col-icon", {'mx-auto' : !_ctx.hasText, 'pl-4 ml-auto' : _ctx.hasText}])
            }, [
              _createVNode(_component_Icon, { icon: _ctx.computedIcon }, null, 8, ["icon"])
            ], 2))
          : _createCommentVNode("", true),
        (_ctx.hasText)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass(["custom-button-text pa-0 pr-4 d-flex col-text", {'pl-2 mr-auto' : _ctx.computedIcon, 'pl-4 mx-auto' : !_ctx.computedIcon}])
            }, [
              (_ctx.text)
                ? (_openBlock(), _createBlock(_component_v_row, {
                    key: 0,
                    class: "mx-auto"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.text), 1)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_v_row, {
                    key: 1,
                    class: "ma-0 my-auto token-text-button"
                  }, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default")
                    ]),
                    _: 3
                  }))
            ], 2))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ], 2))
}