
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import DeployButton from '@/components/Deployment/DeployButton.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import ListElement from '@/components/UIElements/ListElement.vue';
import Badge from '@/components/UIElements/Badge.vue';
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue';
import Checkbox from '@/components/UIElements/Checkbox.vue';
import SubscriptionStartTrialModal from '@/components/Subscription/SubscriptionStartTrialModal.vue';
import SubscriptionTrialOverModal from '@/components/Subscription/SubscriptionTrialOverModal.vue';
import SubscriptionExpiredModal from '@/components/Subscription/SubscriptionExpiredModal.vue';
import ModalPurchaseCredit from '@/components/Modals/ModalPurchaseCredit.vue';
import ModalValidatePaymentMethodList from '@/components/Modals/ModalValidatePaymentMethodList.vue';
import ModalEditLimits from '@/components/Modals/ModalEditLimits.vue';

import { APIBlock, APIDeploymentPlan, APIDeploymentVersion, APIDeploymentLog, APIDeploymentSubject, APICustomer, APIDeployment, APIProject, APICluster, APIBaseAttributes } from '@/typesAPI'
import API from '@/api/wrapper'
import { Watch } from 'vue-property-decorator';


@Options({
  components: {
    CustomButton,
    DeployButton,
    Icon,
    Logo,
    Spinner,
    ListElement,
    SubscriptionStartTrialModal,
    SubscriptionTrialOverModal,
    SubscriptionExpiredModal,
    Badge,
    HeightDeployer,
    Checkbox,
    ModalPurchaseCredit,
    ModalValidatePaymentMethodList,
    ModalEditLimits
  },
})
export default class ValidationErrorsModal extends Vue {
  
  isLoading = false
  isLoadingLog = false
  hideClusterSubtext = false
  showPurchaseCreditModal = false
  showValidatePaymentMethodList = false
  showEditLimits = false
  myClusters:APICluster[] = []

  planExpanded:APIDeploymentPlan | null = null
  planSelectedToDeploy:{[key:string]:boolean} = {}
  versionsToDeploy:APIDeploymentVersion[] = []
  draftViewText = ""

  get isFirstDeployment () {
    let ret = true
    const blockAPIList:APIBlock[] = this.$store.getters['blocksAPI/getList']
    blockAPIList.forEach((blockAPI) => {
      if(this.$store.getters['blocksAPI/getDeployementStatusByBlockID'](blockAPI.id) !== this.$enums.DeploymentStatus.UNKNOWN) {
        ret = false
      }
    })
    return ret
  }

  @Watch('deploymentPlansDraft', {immediate: true})
  onDraftVersionChange(newVal:APIDeploymentPlan[], oldVal:APIDeploymentPlan[]) {
    if(this.deploymentPlansDraft) {
      if(oldVal && newVal) {
        newVal.forEach((val) => {
          if(!oldVal.find((val2) => val2.id === val.id)) {
            this.planSelectedToDeploy[val.id] = true
          }
        })
      }
      this.checkDraftViewText()
      this.calculateVersionsToDeploy()
    }
  }
  
  @Watch('blockDeployments', {immediate: true})
  onBlockDeploymentsChange() {
    if(this.blockDeployments) {
      this.getVersionsByProject()
    }
  }

  @Watch('clusterDeployments', {immediate: true})
  onClusterDeploymentsChange() {
    if(this.clusterDeployments && this.myClusters.length) {
      this.clusterDeployments?.forEach((deployment) => {
        this.myClusters.forEach((cluster) => {
          if(cluster.relationships.deployment.data.id === deployment.id) {
            this.getVersionsByDeployment(deployment)
          }
        })
      })
    }
  }

  @Watch('project', {immediate: true})
  onProjectChange() {
    if(this.project) {
      this.getMyClusters()
      this.getVersionsByProject()
    }
  }

  @Watch('clusters', {immediate: true})
  onClustersChange() {
    if(this.clusters) {
      this.getMyClusters()
    }
  }

  @Watch('deploymentPlansDraft', {immediate: true})
  onDeploymentPlansDraftChange() {
    if(this.deploymentPlansDraft) {
      this.calculateVersionsToDeploy()
    }
  }

  getMyClusters() {
    const ret:APICluster[] = []

    this.project?.relationships?.kubernetes_clusters?.data?.forEach((data) => {
      const cluster = this.$store.getters['clusters/getClusterById'](data.id)
      if(cluster) {
        ret.push(cluster)
      }
    })
    this.myClusters = ret
    this.onClusterDeploymentsChange()
  }
  
  getVersionsByDeployment(deployment:APIDeployment) {
    API.deployment.getVersionsByDeployment(deployment.id)
    .then((result) => {
      if(result.deploymentVersions) {
        this.$store.dispatch('deployment/addDeploymentVersions', result.deploymentVersions)
        result.deploymentVersions.forEach((deploymentVersion:APIDeploymentVersion) => {
          if (deploymentVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
            this.$store.dispatch('deployment/setModalIsOpen', true)
          }
          this.$store.dispatch('deployment/addDeploymentPlans', result.deploymentPlans)
          result.deploymentPlans.forEach((deploymentPlan:APIDeploymentPlan) => {
            this.planSelectedToDeploy[deploymentPlan.id] = true
          })

          if(result?.subjects?.length) {
            result.subjects.forEach((subject) => {
              this.$store.dispatch('deployment/addDeploymentSubject', subject)
            })
          }
        })
      }
      this.checkDraftViewText()
      this.calculateVersionsToDeploy()
    })
  }
  getVersionsByProject() {
    if(this.project) {
      API.deployment.getVersionsByProject(this.project?.id)
      .then((result) => {
        if(result.deploymentVersions) {
          this.$store.dispatch('deployment/addDeploymentVersions', result.deploymentVersions)
          result.deploymentVersions.forEach((deploymentVersion:APIDeploymentVersion) => {
            if (deploymentVersion.attributes.status ===  this.$enums.DeploymentState.Running) {
              this.$store.dispatch('deployment/setModalIsOpen', true)
            }
            this.$store.dispatch('deployment/addDeploymentPlans', result.deploymentPlans)
            result.deploymentPlans.forEach((deploymentPlan:APIDeploymentPlan) => {
              this.planSelectedToDeploy[deploymentPlan.id] = true
            })

            if(result?.subjects?.length) {
              result.subjects.forEach((subject) => {
                this.$store.dispatch('deployment/addDeploymentSubject', subject)
              })
            }
          })
        }
        this.checkDraftViewText()
        this.calculateVersionsToDeploy()
      })
    }
  }

  onClickPurchaseCredits() {
    this.showPurchaseCreditModal = true
  }

  onClickEnableAutoProvisioning() {
    this.showValidatePaymentMethodList = true
  }

  onSuccessValidatePaymentMethodList() {
    API.billing.editCustomer(this.customer?.id, {has_auto_provisioning_enabled : true})
    .finally(() => {
      this.showValidatePaymentMethodList = false
      this.showEditLimits = true
    })
  }

  getTimeToDisplay(time:string):string {
    return this.$helpers.dates.formatTime(time)
  }

  toggleReduce () {
    this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    this.$store.dispatch('deployment/setModalIsDraftView', false)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)

    // this.$store.dispatch('deployment/setModalIsOpen', !this.open)
    // if(!this.isDeployFinished) {
    //   if(!this.isReduced && this.plans.length === 0) {
    //     this.$store.dispatch('deployment/setDeployingState', this.$enums.DeploymentState.NotStarted)
    //     this.$store.dispatch('deployment/setModalIsOpen', false)
    //   } else {
    //     this.$store.dispatch('deployment/setModalIsReduced', !this.isReduced)
    //   }
    // }
  }

  closeExpandPlan() {
    this.planExpanded = null
  }

  toggleExpandPlan (deploymentPlan:APIDeploymentPlan) {
    this.planExpanded = deploymentPlan
    this.loadPlanLogs(deploymentPlan.id)
  }

  loadPlanLogs(deploymentPlanId:string) {
    this.isLoadingLog = true
    API.deployment.getLogs(deploymentPlanId)
    .then((logs:APIDeploymentLog[]) => {
      this.$store.dispatch('deployment/setDeploymentLogs', {deploymentLogs: logs, planId: deploymentPlanId})
      this.isLoadingLog = false
      setTimeout(() => {
        const htmlElem = document.getElementsByClassName("log-window")[0]
        htmlElem.scrollTo({
          top: htmlElem.scrollHeight,
          left: 0,
          behavior: "smooth",
        })
      })
    })
  }

  getPlanLogs(deploymentPlanId:string) {
    return this.$store.getters['deployment/getDeploymentLogsByPlanId'](deploymentPlanId)
  }


  getRollingBackPlanFromPlan(deploymentPlan:APIDeploymentPlan):APIDeploymentPlan | null {
    if(!this.isRollingBack) {
      return null
    }
    const ret = this.plansRollback.find((plan:APIDeploymentPlan) => plan.relationships.subject.data.id === deploymentPlan.relationships.subject.data.id)
    return ret && ret.attributes.operation !== this.$enums.DeploymentPlanOperations.NOOP ? ret : null
  }

  deploymentPlanIcon(deploymentPlan:APIDeploymentPlan):string {
    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = 'clock'
    ret[this.$enums.DeploymentRunState.STARTING] = 'spinner'
    ret[this.$enums.DeploymentRunState.RUNNING] = 'spinner'
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = 'spinner'
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = 'check-circle'
    ret[this.$enums.DeploymentRunState.RETRIED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.FAILED] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = 'cancel-circle'
    ret[this.$enums.DeploymentRunState.ABANDONED] = 'block'

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeState(deploymentPlan:APIDeploymentPlan):string {
    if(deploymentPlan.attributes.operation === this.$enums.DeploymentPlanOperations.NOOP) {
      return this.$enums.BadgeStates.Neutral
    }

    const ret:{[key:string]: string} = {}

    ret[this.$enums.DeploymentRunState.PENDING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.STARTING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.RUNNING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.WAITING_FOR_CALLBACK] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.SUCCEEDING] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.SUCCEEDED] = this.$enums.BadgeStates.Success
    ret[this.$enums.DeploymentRunState.RETRIED] = this.$enums.BadgeStates.Neutral
    ret[this.$enums.DeploymentRunState.FAILED] = this.$enums.BadgeStates.Danger
    ret[this.$enums.DeploymentRunState.ROLLED_BACK] = this.$enums.BadgeStates.Danger
    ret[this.$enums.DeploymentRunState.ABANDONED] = this.$enums.BadgeStates.Neutral

    return ret[deploymentPlan?.attributes?.status]
  }

  deploymentPlanBadgeLabel(deploymentPlan:APIDeploymentPlan):string {
    const labels:{[key:string]: string} = {}
  
    labels[this.$enums.DeploymentPlanOperations.NOOP] = 'No Changes'
    labels[this.$enums.DeploymentPlanOperations.CREATION] = 'New'
    labels[this.$enums.DeploymentPlanOperations.UPDATE] = 'Update'
    labels[this.$enums.DeploymentPlanOperations.DESTRUCTION] = 'Delete'

    return labels[deploymentPlan.attributes.operation]
  }


  textFromDeploymentPlan(deploymentPlan:APIDeploymentPlan) {
    switch(deploymentPlan?.relationships?.subject?.data.type) {
      case 'kubernetesClusters' :
        return 'Kubernetes cluster'
      case 'blocks' :
        return this.getAPIBlockNameFromID(deploymentPlan?.relationships?.subject?.data.id)
      default: 
        return ''
    }
  }

  getAPIBlockNameFromID(id:string): string {

    const apiBlock:APIBlock =  this.listSubjects.find((subject:APIDeploymentSubject) => {
      return subject.id === id
    }) as APIBlock
    if(!apiBlock?.attributes) {
      return ""
    }
    return apiBlock?.attributes?.name ? apiBlock.attributes.name : apiBlock?.attributes?.default_name
  }

  getLogoFromDeploymentPlan(deploymentPlan:APIDeploymentPlan): string {
    if(deploymentPlan?.relationships?.subject?.data.type === 'blocks') {
      const apiBlock:APIBlock = this.listSubjects.find((subject:APIDeploymentSubject) => {
        return subject.id === deploymentPlan.relationships.subject.data.id
      }) as APIBlock
      return apiBlock && this.$enums.EnginesLogos[apiBlock.attributes.default_name] ? this.$enums.EnginesLogos[apiBlock.attributes.default_name] : ''
    } else if (deploymentPlan?.relationships?.subject?.data.type === 'kubernetesClusters') {
      return this.$enums.EnginesLogos['kubernetes']
    }

    return ''
  }

  updateDeploymentPlanIncludeInVersion(deploymentPlan:APIDeploymentPlan, newState:boolean) {
    this.checkDraftViewText()
    this.calculateVersionsToDeploy()
  }

  openDraftView() {
    this.$store.dispatch('deployment/setModalIsDraftView', true)
    this.$store.dispatch('deployment/setModalIsOpen', true)
    this.$store.dispatch('deployment/setForceDeployCompletedState', false)
  }

  getDeploymentPlansByVersionId(versionId:string) {
    return this.$store.getters['deployment/getDeploymentPlansByVersionId'](versionId)
  }

  isKubernetesCluster(deploymentPlan:APIDeploymentPlan) {
    return deploymentPlan.relationships.subject.data.type === "kubernetesClusters"
  }

  get deploymentPlansDraft():APIDeploymentPlan[] {
    const ret:APIDeploymentPlan[] = []
    if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        ret.push(...this.getDeploymentPlansByVersionId(draftVersion.id))
      })
    }
    ret.sort((a, b) => {
      if(a.relationships.subject.data.type === "kubernetesClusters") {
        return -1
      }
      return 0
    })
    return ret
  }

  get draftVersions():APIDeploymentVersion[] {
    return this.$store.getters['deployment/getDraftVersions']
  }


  toggleClusterSubtext() {
    // this.hideClusterSubtext = !this.hideClusterSubtext
  }

  get subscriptionState ():string {
    return this.$store.getters['user/getSubscriptionState']
  }

  get customer ():APICustomer {
    return this.$store.getters['user/getCustomer']
  }

  get open ():boolean {
    return this.$store.getters['deployment/getModalIsOpen']
  }

  get isReduced ():boolean {
    return this.$store.getters['deployment/getModalIsReduced']
  }

  get plans ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getRunningOrCompletedDeploymentPlans']
  }
  get plansRollback ():APIDeploymentPlan[] {
    return this.$store.getters['deployment/getDeploymentPlansRollback']
  }

  get isRollingBack ():boolean {
    return !!this.plansRollback.length
  }

  get listSubjects ():APIDeploymentSubject[] {
    return this.$store.getters['deployment/getListSubjects']
  }

  get deployingState (): string {
    return this.$store.getters['deployment/getDeployingState']
  }

  get deploying (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Running
  }

  get isDeployFinished (): boolean {
    return this.deployingState === this.$enums.DeploymentState.Completed
  }

  get titleText (): string {
    return this.isDeployFinished ? 'Deployment complete' : 'Deployment in progress'
  }

  get clusterSubtext (): string {
    return 'Deploying a cluster for the first time can take up to 10 minutes.<br/> You can close this panel.'
  }

  get isDraftView (): boolean {
    return this.$store.getters['deployment/getModalIsDraftView']
  }

  get blockDeployments (): APIDeployment[] {
    return this.$store.getters['blocksAPI/getDeployements']
  }

  get clusterDeployments (): APIDeployment[] {
    return this.$store.getters['clusters/getDeployments']
  }

  get project (): APIProject | null {
    return this.$store.getters['projects/getCurrentProject']
  }

  get clusters (): APICluster[] | null {
    return this.$store.getters['clusters/getClusters']
  }

  calculateVersionsToDeploy() {
    const ret:APIDeploymentVersion[] = []
    this.deploymentPlansDraft.forEach((plan:APIDeploymentPlan) => {
      if(this.planSelectedToDeploy[plan.id]) {
        const version = this.draftVersions.find((v) => v.id === plan.relationships.version.data.id)
        if(version) {
          ret.push(version)
        }
      }
    })
    this.versionsToDeploy = ret
  }



  get getShouldDisablePublish (): boolean {
    let ret = true

    if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        const plans = this.getDeploymentPlansByVersionId(draftVersion.id)
        plans?.forEach((plan:APIDeploymentPlan) => {
          if(this.planSelectedToDeploy[plan.id] === true) {
            ret = false
          }
        })
      }) 
    }
    
    return ret
  }

  checkDraftViewText () {
    let ret = "Deploy all changes"

    if(this.deploying) {
      ret = "Deployment in progress"
    } else if(this.draftVersions.length) {
      this.draftVersions.forEach((draftVersion) => {
        const plans = this.getDeploymentPlansByVersionId(draftVersion.id)
        plans?.forEach((plan:APIDeploymentPlan) => {
          if(!this.planSelectedToDeploy[plan.id]) {
            ret = "Deploy selection"
          }
        })
      })
    }
    
    this.draftViewText = ret
  }
  

  @Watch('isDeployFinished')
  onisDeployFinishedChange(val:boolean) {
    if(val && this.isDraftView) {
      setTimeout(() => {
        this.$store.dispatch('deployment/setForceDeployCompletedState', false)
      },3000)
    }
  }


  
}
