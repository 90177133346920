
import { Vue, prop, Options } from 'vue-class-component';
import { Block } from '@/types'
import Icon from '@/components/UIElements/Icon.vue'
import Logo from '@/components/UIElements/Logo.vue'

class Props {
  block: Block = prop({
    required: true,
  });
}

@Options({
  components: {
    Icon,
    Logo
  },
})
export default class BlockItemShadow extends Vue.with(Props) {
}
