
import { Vue, prop } from 'vue-class-component';

class Props {
  modelValue: string = prop({
    required: true,
  });
  disabled?: boolean = prop({
    required: false,
  });
  tabs: {[key:string]: string} = prop({
    required: true,
  });
}

export default class Switch extends Vue.with(Props) {
  currentTab = null

  handleClick (keyClicked: string) {
    this.$emit('update:modelValue', keyClicked)
  }
}
