
import { Vue, prop } from 'vue-class-component';

class Props {
  src?:string = prop({
    required: false,
  });
}

export default class Avatar extends Vue.with(Props) {
}
