
import { Vue, Options, prop } from 'vue-class-component'
import Spinner from '@/components/UIElements/Spinner.vue'

class Props {
  visible?: boolean = prop({
    required: false,
  });
  transparent?: boolean = prop({
    required: false,
  });
  loading?: boolean = prop({
    required: false,
  });
  width?: string = prop({
    required: false,
  });
  closeOnExternalClick?: boolean = prop({
    required: false,
  });
  fullScreen?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Spinner
  },
})
export default class Modal extends Vue.with(Props) {


  get computedStyle ():string {
    return '' + (this.width ? `width: ${this.width} !important;min-width: ${this.width} !important;max-width: ${this.width} !important;` : '') 
  }

  onExternalClick() {
    if(!this.fullScreen) {
      this.$emit('clickExternal')
      if(this.closeOnExternalClick) {
        this.$emit('update:visible', false)
      }
    }
  }

}
