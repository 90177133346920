
import { Vue, prop, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import { Watch } from 'vue-property-decorator';

class Props {
  values: number[]  = prop({
    required: true,
  });
  step: number | undefined  = prop({
    required: false,
  });
  min: number | undefined = prop({
    required: false,
  });
  max: number | undefined = prop({
    required: false,
  });
  hideThumbLabels: boolean | undefined = prop({
    required: false,
  });
  hideThumb: boolean | undefined = prop({
    required: false,
  });
  range: boolean | undefined = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
}

@Options({
  components: {
    CustomButton
  },
})
export default class Slider extends Vue.with(Props) {

  insideValues = [0,0]

  mounted(): void {
    this.insideValues = this.values
  }

  updateValue(values:number[] | number) {
    this.$emit('updateValue:values', values)
  }

  @Watch('values')
  onValuesChange() {
    this.insideValues = this.values
  }


}
