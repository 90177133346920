import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cluster-bar bar pa-0 d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalClusterConfig = _resolveComponent("ModalClusterConfig")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_PriceDisplay = _resolveComponent("PriceDisplay")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ModalClusterConfig, { cluster: _ctx.selectedCluster }, null, 8, ["cluster"]),
    _createVNode(_component_v_row, { class: "ma-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_col, { class: "pa-0 d-flex" }, {
          default: _withCtx(() => [
            _createVNode(_component_DropdownMenu, {
              items: _ctx.burgerItems,
              show: _ctx.burgerOpened,
              "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.burgerOpened) = $event)),
              maxContent: true,
              "onUpdate:modelValue": _ctx.onMenuItemClick,
              target: _ctx.$refs.menuButton
            }, null, 8, ["items", "show", "onUpdate:modelValue", "target"]),
            _createVNode(_component_CustomButton, {
              ref: "menuButton",
              class: "ma-auto",
              text: _ctx.clusterName ? _ctx.clusterName : 'No cluster selected' ,
              level: _ctx.$enums.ButtonLevels.Tertiary,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.burgerOpened = true))
            }, null, 8, ["text", "level"])
          ]),
          _: 1
        }),
        (_ctx.currentClusterId)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_v_col, { class: "pa-0 separator-vertical mx-2" }),
              _createVNode(_component_v_col, { class: "pa-0 fit-content ml-2" }, {
                default: _withCtx(() => [
                  (_ctx.monthlyPrice)
                    ? (_openBlock(), _createBlock(_component_v_row, {
                        key: 0,
                        class: "ma-0 token-text-medium price token-text-color-primary"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_PriceDisplay, {
                            price: _ctx.displayPriceMin,
                            price2: _ctx.displayPriceMax,
                            currency: _ctx.customer?.attributes.currency
                          }, null, 8, ["price", "price2", "currency"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ]))
}