
import { Vue, Options } from 'vue-class-component';
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Badge from '@/components/UIElements/Badge.vue'
import Icon from '@/components/UIElements/Icon.vue'
import Spinner from '@/components/UIElements/Spinner.vue'
import Popin from '@/components/UIElements/Popin.vue'
import { APIErrorValidation } from '@/typesAPI';
import { ElementNode } from '@/types';
import { Watch } from 'vue-property-decorator';
import ListElement from '@/components/UIElements/ListElement.vue';

@Options({
  components: {
    CustomButton,
    Icon,
    Popin,
    Spinner,
    Badge,
    ListElement
  },
})
export default class ValidationErrorsModal extends Vue {
  
  onClose () {
    this.$store.dispatch('errorsValidation/closeModal')
  }

  closeWarningPopup () {
    this.$store.dispatch('errorsValidation/closeWarningPopup')
  }

  forceDeploy () {
    this.$store.dispatch('errorsValidation/closeWarningPopup')
    this.$store.dispatch('errorsValidation/closeModal')
    this.$emitter.emit('forceDeploy')
  }

  onClickError (error:APIErrorValidation) {
    this.$store.dispatch('errorsValidation/setSelectedError', error)
    if(error.relationships.source.data.type === 'blocks') {
      // Open Block
      const tree = this.$store.getters['fields/getTree']
      const block = tree.blocks[error.relationships.source.data.id]

      this.$router.push({
        name: 'flowBlock',
        params: {
          blockId: error.relationships.source.data.id,
          tabId: Object.keys(block.childs)[0],
        }
      });

    } else if(error.relationships.source.data.type === 'blockConnections') {
      // Open Connection
      this.$store.dispatch('elements/setDetailOpenLink', error.relationships.source.data.id)
    } else if(error.relationships.source.data.type === 'blockEnvironmentVariableOverrides') {
      // Open Connection and hightlight override
    } else if(error.relationships.source.data.type === 'fields') {
      // Open Block + Open Box

      const tree = this.$store.getters['fields/getTree']
      const fv = tree.fieldValues[error.relationships.source.data.id]
      this.$router.push({
        name: 'flowBlockAndBox',
        params: {
          blockId: fv.relationships.block.data.id,
          tabId: fv?.parent?.parent?.parent.id,
          boxId: fv?.parent?.parent.id
        }
      });

    }
    this.$store.dispatch('errorsValidation/setHoveredError', null)
    this.$store.dispatch('errorsValidation/setModalTransparency', false)

  }

  onErrorHover(error: APIErrorValidation | null) {
    if(!this.selectedError || this.selectedError !== error) {
      this.$store.dispatch('errorsValidation/setHoveredError', error)

      if(error) {
        const nodes: ElementNode[] = this.$store.getters['elements/getNodes']
        const node = nodes.find((n:ElementNode) => {
          return n.extra.blockAPIID === error.relationships.source.data.id
        })
        const htmlDiv:HTMLDivElement |null = document.querySelector("[data-id=" + node?.id +"]")
        if(this.isBlockDetailOpen || this.isLinkDetailOpen || (htmlDiv && (htmlDiv.getBoundingClientRect().right > (document.body.getBoundingClientRect().width - 416)))) {
          this.$store.dispatch('errorsValidation/setModalTransparency', true)
        }
      } else {
        this.$store.dispatch('errorsValidation/setModalTransparency', false)
      }
    }
    
  }

  get isModalTransparent ():boolean {
    return this.$store.getters['errorsValidation/getModalTransparency']
  }

  get isLoading ():boolean {
    return this.$store.getters['errorsValidation/isLoading']
  }

  get selectedError ():APIErrorValidation | null {
    return this.$store.getters['errorsValidation/getSelectedError']
  }

  get showWarningPopup ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarningPopupIsOpen']
  }

  get open ():boolean {
    return this.$store.getters['errorsValidation/getModalIsOpen']
  }

  get list ():APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getList']
  }

  get errors (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getErrors']
  }

  get warnings (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getWarnings']
  }

  get infos (): APIErrorValidation[] {
    return this.$store.getters['errorsValidation/getInfos']
  }

  get isBlockDetailOpen (): boolean {
    return !!this.$store.getters['elements/getDetailOpenElement']
  }

  get isLinkDetailOpen (): boolean {
    return !!this.$store.getters['elements/getDetailOpenLink']
  }

  @Watch('isBlockDetailOpen')
  onBlockDetailToggle(newVal: boolean, oldVal:boolean) {
    if(oldVal && !newVal) {
      this.$store.dispatch('errorsValidation/setSelectedError', null)
    }
  }

  @Watch('isLinkDetailOpen')
  onLinkDetailToggle(newVal: boolean, oldVal:boolean) {
    if(oldVal && !newVal) {
      this.$store.dispatch('errorsValidation/setSelectedError', null)
    }
  }

  

}
