
import API from '@/api/wrapper';
import CustomButton from '@/components/UIElements/CustomButton.vue';
import CustomTextInput from '@/components/UIElements/CustomTextInput.vue';
import Icon from '@/components/UIElements/Icon.vue';
import Spinner from '@/components/UIElements/Spinner.vue';
import { Vue, Options, prop } from 'vue-class-component';

enum PROVIDERS {
  GMAIL = 'gmail',
  OUTLOOK = 'outlook'
}

class Props {
  email: string = prop({
    required: true,
  });
}


@Options({
  components: {
    CustomButton,
    CustomTextInput,
    Icon,
    Spinner,
  },
})
export default class CreateMagicLinkBar extends Vue.with(Props) {
  loading = false
  tooManyRequest = false
  tooManyRequestButtonLoading = false


  mounted(): void {
    this.generateLink()
  }

  generateLink () {
    this.tooManyRequest = false
    this.loading = true
    API.session.getCSRF().then(() => {
      API.user.create(this.email)
      .then()
      .catch((err) => {
        if(err.response.status === 422) {
          API.session.createMagicLink(this.email)
          .then()
          .catch((err2) => {
            if(err2.response.status === 429) {
              this.tooManyRequest = true
              this.tooManyRequestButtonLoading = true
              setTimeout(() => {
                this.tooManyRequestButtonLoading = false
              },60000)
            }
          })
        }
      })
      .finally(() => {
        this.loading = false
      })
    })
  }

  openInbox (): void {
    switch(this.provider) {
      case PROVIDERS.GMAIL:
        window.location.href = 'https://mail.google.com/mail/u/0/#search/no-reply%40fransys.cloud'
        break;
      case PROVIDERS.OUTLOOK:
        window.location.href = 'https://outlook.live.com/mail/0/'
        break;
      default:
        break;
    }
  }

  get provider ():string | null {
    let prov = null
    if(this.email) {
      const regExGmail = /(@gmail.com)/
      const regExOutlook = /(@outlook.com)|(@hotmail.com)|(@hotmail.fr)/

      if(this.email.match(regExGmail)) {
        prov = PROVIDERS.GMAIL
      } else if(this.email.match(regExOutlook)) {
        prov = PROVIDERS.OUTLOOK
      }
    }
    return prov
  }
}
