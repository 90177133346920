import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1663c5c7"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-container"
}
const _hoisted_2 = {
  key: 1,
  class: "dropdown-footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextInput = _resolveComponent("CustomTextInput")!
  const _component_List = _resolveComponent("List")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_Popover = _resolveComponent("Popover")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return (_ctx.target)
    ? (_openBlock(), _createBlock(_component_Popover, {
        key: 0,
        visible: _ctx.show,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:show', $event))),
        target: _ctx.target,
        closeOnExternalClick: true,
        positionerOnly: true
      }, {
        content: _withCtx(() => [
          _createElementVNode("div", {
            ref: "dropdownMenu",
            class: _normalizeClass('dropdown-menu ma-auto my-4 ' + (_ctx.maxContent ? ' max-content ': ' ') + (_ctx.classInternal ? _ctx.classInternal: ' ')),
            style: _normalizeStyle('width:' + _ctx.width+ ';')
          }, [
            (_ctx.show)
              ? _withDirectives((_openBlock(), _createBlock(_component_v_card, {
                  key: 0,
                  elevation: "0"
                }, {
                  default: _withCtx(() => [
                    (_ctx.displaySearch)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                          _createVNode(_component_CustomTextInput, {
                            class: "ma-4",
                            placeholder: "Search",
                            icon: "search",
                            searchStyle: true,
                            modelValue: _ctx.query,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:query', $event)))
                          }, null, 8, ["modelValue"])
                        ]))
                      : _createCommentVNode("", true),
                    _createElementVNode("div", {
                      class: "expanded-select-items-container",
                      style: _normalizeStyle('max-height:' + _ctx.maxHeight+'px;')
                    }, [
                      _createVNode(_component_List, {
                        items: _ctx.items,
                        onSelect: _ctx.onSelect,
                        loading: _ctx.loading
                      }, null, 8, ["items", "onSelect", "loading"])
                    ], 4),
                    (_ctx.$slots['footer'])
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _renderSlot(_ctx.$slots, "footer", {}, undefined, true)
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 3
                })), [
                  [_directive_click_outside, _ctx.onOutsideClick]
                ])
              : _createCommentVNode("", true)
          ], 6)
        ]),
        _: 3
      }, 8, ["visible", "target"]))
    : _createCommentVNode("", true)
}