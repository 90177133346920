
type State = {
  firstUnboardingDone: boolean
}

const state: State = {
  firstUnboardingDone: false
}


const getters = {
  getFirstUnboardingDone: (state:State) => {
    return state.firstUnboardingDone
  },
}

const mutations = {
  SET_FIRST_UNBOARDING_DONE: (state:State, newVal:boolean) => {
    state.firstUnboardingDone = newVal
  },
}

const actions = {
  setFirstUnboardingDone: (context:any, newVal:boolean) => {
    context.commit('SET_FIRST_UNBOARDING_DONE', newVal)
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}