
import { Vue, Options, prop, WithDefault } from 'vue-class-component'
import HeightDeployer from '@/components/UIElements/HeightDeployer.vue'
import CustomButton from '@/components/UIElements/CustomButton.vue'
import Divider from '@/components/UIElements/Divider.vue'
import Icon from '@/components/UIElements/Icon.vue'
import { APIFieldDefinition, APIFieldGroup, APIFieldValue } from '@/typesAPI'
import { Watch } from 'vue-property-decorator';
import { nextTick } from 'vue';
import API from '@/api/wrapper';

class Props {
  deployable: WithDefault<boolean> = prop({
    required: false,
    default: true
  });
  forceDeployed?: boolean = prop({
    required: false,
  });
  disabled?: boolean = prop({
    required: false,
  });
  deployed: boolean = prop({
    required: true,
  });
  vertical?: boolean = prop({
    required: false,
  });
  groupId: string = prop({
    required: true,
  });
}

@Options({
  components: {
    HeightDeployer,
    Icon,
    CustomButton,
    Divider
  },
})
export default class FieldGroup extends Vue.with(Props) {

  maxWidthSoloValue = 370
  isLongValue = false
  hideAll = false

  deleteLoading = false

  get computedDeployed():boolean {
    return !!this.deployed
  }

  mounted() {
    setTimeout(() => {
      this.$emit('update:deployed', this.computedDeployed)
      this.checkLongValue()
    },100)
  }

  @Watch('computedDeployed')
  onRefRowValueChange(newVal:boolean, oldVal:boolean) {
    if(!newVal && oldVal) {
      nextTick(() => {
        this.checkLongValue()
      })
    }
  }

  @Watch('fieldsValues', {deep:true})
  onFieldsValuesChange(newVal:boolean, oldVal:boolean) {
    if(!this.deployed) {
      setTimeout(() => {
        this.checkLongValue()
      },50)
    }
  }

  get fieldsValues ():APIFieldValue[] {
    return this.$store.getters['fields/getListValues']
  }

  get fieldGroup ():APIFieldGroup | undefined {
    return this.$store.getters['fields/getListGroups'].find((group:APIFieldDefinition) => group.id === this.groupId)
  }

  get disableDelete():boolean {
    return this.fieldGroup ? this.fieldGroup.attributes.is_read_only : false
  }

  deleteGroup () {
    this.deleteLoading = true
    API.group.delete(this.groupId)
    .then(() => {
      this.hideAll = true
    })
  }

  checkLongValue() {
    if(!this.vertical) {
      this.isLongValue = false
      if(this.$refs.rowValue && this.$refs.rowTitle) {
        // @ts-ignore
        if(this.$refs.rowValue.$el.getBoundingClientRect().width + this.$refs.rowTitle.$el.getBoundingClientRect().width > this.maxWidthSoloValue) {
          this.isLongValue = true
        } else {
          this.isLongValue = false
        }
      }
    }
  }

  deploy() {
    if(this.deployable && !this.disabled) {
      if(this.computedDeployed) {
        this.collapse()
      } else {
        this.expand()
      }
    }
  }

  collapse() {
    this.$emit('update:deployed', false)
  }

  expand() {
    this.$emit('update:deployed', true)
  }

}
