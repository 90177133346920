
import { Vue, Options, prop } from 'vue-class-component';
import Logo from '@/components/UIElements/Logo.vue';

class Props {
  vertical?: boolean = prop({
    required: false,
  });
}


@Options({
  components: {
    Logo,
  },
})
export default class FransysSignature extends Vue.with(Props) {
}
