export default Object.freeze({
    PENDING : 'pending',
    STARTING : 'starting',
    RUNNING : 'running',
    WAITING_FOR_CALLBACK : 'waiting-for-callback',
    SUCCEEDING : 'succeeding',
    SUCCEEDED : 'succeeded',
    RETRIED : 'retried',
    FAILED : 'failed',
    ROLLED_BACK : 'rolled-back',
    ABANDONED : 'abandoned',
})
