import enums from '@/enums'

type State = {
  isVisible: {[key:string]:boolean},
}

const state: State = {
  isVisible: {},
}


const getters = {
  getVisibilityCluster: (state:State) => {
    return !!state.isVisible[enums.ModalsName.Cluster] 
  },

}

const mutations = {
  SET_VISIBILITY: (state:State, payload:{modal:any, newState:boolean}) => {
    state.isVisible[payload.modal] = payload.newState
  },
  HIDE_ALL: (state:State) => {
    state.isVisible = {}
  },

}

const actions = {
  setVisibility: (context:any,payload:{modal:any, newState:boolean}) => {
    context.commit('SET_VISIBILITY', payload)
  },
  hideAll: (context:any) => {
    context.commit('HIDE_ALL')
  },
}
export default {
  state,
  getters,
  mutations,
  actions
}