export default Object.freeze({
    // DB
    MariaDB : 'mariadb',
    MySQL : 'mysql',
    Redis : 'redis',
    PostgreSQL: 'postgresql',
    MongoDB: 'mongodb',
    ElastiSearch: 'elasticsearch',
    Cassandra: 'cassandra',
    // PHP
    Laravel: 'laravel',
    Slim: 'slim',
    Cake: 'cake',
    Codeignighter: 'codeignighter',
    FuelPHP: 'fuelphp',
    Phalcon: 'phalcon',
    Symphony: 'symphony',
    Yii: 'yii',
    // Kubernetes
    kubernetes: 'kubernetes',
    // Workers
    Worker: 'worker',
    // Sockets
    Socket: 'socket',
    Soketi: 'soketi',
    // Git
    gitlab: 'gitlab',
    github: 'github',
    // Docker
    docker: 'docker',
    // Front
    vue: 'vue',
    angular: 'angular',
    react: 'react',
    'Vue JS': 'vue',
    'Angular JS': 'angular',
    'React JS': 'react',
    // Application
    Application: 'code',
})