import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-75c0c7cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "my-auto" }
const _hoisted_2 = { class: "my-auto" }
const _hoisted_3 = { class: "mx-n2 token-text-color-primary" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Icon = _resolveComponent("Icon")!
  const _component_CustomButton = _resolveComponent("CustomButton")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createBlock(_component_v_row, { class: "price-display ma-0" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$helpers.prices.format(_ctx.displayPrice,_ctx.currency)), 1),
      (_ctx.price2 && _ctx.price !== _ctx.price2)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_Icon, {
              icon: "arrow-right",
              class: "mx-2 my-auto"
            }),
            _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$helpers.prices.format(_ctx.displayPrice2, _ctx.currency)), 1)
          ], 64))
        : _createCommentVNode("", true),
      _createVNode(_component_CustomButton, {
        class: "ml-2 my-auto",
        level: _ctx.$enums.ButtonLevels.Tertiary,
        disabled: false,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.burgerOpened = true)),
        ref: "button"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, " / " + _toDisplayString(_ctx.selectedItem.text), 1)
        ]),
        _: 1
      }, 8, ["level"]),
      _createVNode(_component_DropdownMenu, {
        items: _ctx.burgerItems,
        show: _ctx.burgerOpened,
        "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.burgerOpened) = $event)),
        maxContent: true,
        "onUpdate:modelValue": _ctx.onBurgerMenuItemClick,
        target: _ctx.$refs.button,
        classInternal: "mt-2"
      }, null, 8, ["items", "show", "onUpdate:modelValue", "target"])
    ]),
    _: 1
  }))
}