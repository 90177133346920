
import { Vue, prop } from 'vue-class-component';

class Props {
  logo: string = prop({
    required: true,
  });
  color?: string = prop({
    required: false,
  });
  fullSize?: boolean = prop({
    required: false,
  });
}

export default class Logo extends Vue.with(Props) {
}
