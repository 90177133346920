import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7e2396fc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "panel-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LogoBar = _resolveComponent("LogoBar")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_ProjectBar = _resolveComponent("ProjectBar")!
  const _component_ContactUsBar = _resolveComponent("ContactUsBar")!
  const _component_ClusterBar = _resolveComponent("ClusterBar")!
  const _component_ValidationErrorsBar = _resolveComponent("ValidationErrorsBar")!
  const _component_SaveBar = _resolveComponent("SaveBar")!
  const _component_v_row = _resolveComponent("v-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_col, { class: "pa-0" }, {
      default: _withCtx(() => [
        _createVNode(_component_v_row, { class: "ma-0" }, {
          default: _withCtx(() => [
            _createVNode(_component_v_col, { class: "pa-0 pr-4 mr-0 fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_LogoBar)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, { class: "pa-0 pr-4 fit-content" }, {
              default: _withCtx(() => [
                _createVNode(_component_ProjectBar)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "pa-0 pr-4 fit-content ml-auto",
              align: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ContactUsBar)
              ]),
              _: 1
            }),
            (_ctx.isSubscribed)
              ? (_openBlock(), _createBlock(_component_v_col, {
                  key: 0,
                  class: "pa-0 pr-4 fit-content",
                  align: "right"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ClusterBar)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_v_col, {
              class: "pa-0 fit-content",
              align: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ValidationErrorsBar)
              ]),
              _: 1
            }),
            _createVNode(_component_v_col, {
              class: "pa-0 fit-content",
              align: "right"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SaveBar)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}