
import { Options, Vue, prop } from 'vue-class-component';
import { NodeProps, Position, Handle } from '@vue-flow/core'

class Props {
  props: NodeProps = prop({
    required: true,
  });
}

@Options({
  components: {
    Handle,
  },
})
export default class PlatformNode extends Vue.with(Props) {
  Position:typeof Position = Position
}
